import React from 'react';

import { useApp } from '@hooks';

import { Desktop, Mobile } from './layouts';

export const CreateSticker = () => {
    const app = useApp();
    const {
        deviceInfo: {
            isMobileLayoutRecommended,
        },
    } = app;

    return isMobileLayoutRecommended
        ? <Mobile/>
        : <Desktop {...app}/>;
};
