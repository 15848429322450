export interface IMemePartyStateLoadParams {
    streamerId?: string;
}

export interface IMemePartyState {
    streamerId: string;
    enabled: boolean;
    goal: number;
    currentValue: number;
    active: boolean;
    startedAt: number;
    willEndAt: number;
}
