import { Button, Column, EComponentSize, Icon, Label, Row } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React, { MouseEvent, useState } from 'react';
import { FileUploader as FU } from 'react-drag-drop-files';
import type FileUploaderSrc from 'react-drag-drop-files/dist/src/FileUploader';

import { IconGarbageLine, IconUploadLine, IconVolumeLine } from '@assets/images/svg';

const FileUploader: typeof FileUploaderSrc = FU;

import s from './CustomSoundUploader.scss';

const fileTypes = ['MP3', 'WAV'];

interface CustomSoundUploaderProps {
    soundLink?: string;
    handleChange(file?: File): void;
    clear?: () => void;
}

export const CustomSoundUploader = (props: CustomSoundUploaderProps) => {
    const {
        soundLink,
        handleChange,
        clear = noop,
    } = props;

    const [audioFile, setAudioFile] = useState<File>();

    const handleAudioChange = (files: File | File[]) => {
        const f = Array.isArray(files) ? files[0] : files;

        setAudioFile(f);
        handleChange(f);
    };

    const deleteAudio = (event: MouseEvent) => {
        event.preventDefault();
        setAudioFile(undefined);
        clear();
    };

    const hasAudio = !!audioFile || !!soundLink;

    return (
        <Column grow padding={{ top: 5, bottom: 5 }}>
            <FileUploader
                classes={s.fileUploader}
                fileOrFiles={audioFile}
                handleChange={handleAudioChange}
                name="file"
                types={fileTypes}
            >
                <Column>
                    {!hasAudio &&
                        <Button
                            caption={'Добавить звук'}
                            icon={<IconVolumeLine/>}
                            className={s.addAudioButton}
                        />
                    }
                    {hasAudio &&
                        <Row columnGap={10}>
                            <Column>
                                <Icon icon={<IconVolumeLine/>} size={EComponentSize.SMALL}/>
                            </Column>
                            <Column grow width={200}>
                                <Label
                                    caption={soundLink?.split('/').pop() || audioFile?.name || ''}
                                    className={s.trackName}
                                />
                            </Column>
                            <Column>
                                <Button icon={<IconUploadLine/>} circle />
                            </Column>
                            <Column>
                                <div onMouseDown={deleteAudio}>
                                    <Button icon={<IconGarbageLine/>} circle />
                                </div>
                            </Column>
                        </Row>
                    }
                </Column>
            </FileUploader>
        </Column>
    );
};
