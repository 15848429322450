import { EOrientation } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import s from './Menu.scss';


interface MenuProps {
    className?: string;
    orientation?: EOrientation;
}

export const Menu = (props: React.PropsWithChildren<MenuProps>) => {
    const {
        children,
        className,
        orientation = EOrientation.HORIZONTAL,
    } = props;

    const menuClasses = cn(
        s.menu,
        s[`menu${orientation}`],
        className,
    );

    return (
        <div className={menuClasses}>
            {children}
        </div>
    );
};
