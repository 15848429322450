import { ScrollableArea } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconBellSolid } from '@assets/images/svg';
import { IUser, TMemeAlertsEvent } from '@typings';
import { Empty, EventItem } from '@views';

import s from './EventsList.scss';


interface IEventsListProps {
    currentUser?: IUser;
    events: Array<TMemeAlertsEvent>;

    onReplayClick(event: TMemeAlertsEvent): void;
    onToggleDisableStateClick(event: TMemeAlertsEvent): void;
}

export const EventsList = (props: IEventsListProps) => {
    const { t } = useTranslation();
    const {
        events,
        currentUser,

        onReplayClick = noop,
        onToggleDisableStateClick = noop,
    } = props;

    return (
        <ScrollableArea contentClassName={s.scrollableContentArea}>
            {
                events.length > 0
                    ? events.map((e, idx) =>
                        <EventItem
                            key={idx}
                            currentUser={currentUser}
                            event={e}
                            onReplayClick={onReplayClick}
                            onToggleDisableStateClick={onToggleDisableStateClick}
                        />,
                    )
                    : <Empty
                        icon={<IconBellSolid/>}
                        iconSize={40}
                        text={t('dashboard.events.emptyText')}
                    />
            }
        </ScrollableArea>
    );
};
