import React from 'react';
import { RouteObject } from 'react-router-dom';

import { Catalogue, Channel, Favorites, Mine, Search } from '@views';

export const PersonalAreaRoutes: Array<RouteObject> = [
    {
        path: '',
        element: <Catalogue/>,
    },
    {
        path: 'favorites',
        element: <Favorites/>,
    },
    {
        path: 'mine',
        element: <Mine/>,
    },
    {
        path: 'channel',
        element: <Channel/>,
    },
    {
        path: 'search',
        element: <Search/>,
    },
];
