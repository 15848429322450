import { useLogger } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useState } from 'react';

import { IconCorruptedImageLine } from '@assets/images/svg';

import s from './Image.scss';


interface ImageProps {
    // Properties
    disabled?: boolean;
    source?: string;
    stickerImageRef?: React.MutableRefObject<HTMLImageElement | null>;
    // Handlers
    onReadyStateChange?: (ready: boolean) => void;
}

export const Image = (props: ImageProps) => {
    const {
        source,
        disabled,
        stickerImageRef,
        onReadyStateChange = noop,
    } = props;

    const logger = useLogger({ target: Image.name });

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const onLoaded = () => {
        logger.debug('loaded');
        setLoaded(true);
        onReadyStateChange(true);
    };

    const onError = () => {
        logger.debug('error');
        setError(true);
        onReadyStateChange(false);
    };

    return (
        <div
            className={cn(
                s.imageContainer,
            )}
        >
            {/* Show error if the image could not be loaded */}
            { !loaded && error &&
                <div className={s.error}>
                    <div className={s.errorImage}>
                        <IconCorruptedImageLine/>
                    </div>
                </div>
            }

            {/* This is tha main animation that is shown on collection */}
            { !error &&
                <img
                    className={cn(
                        s.image,
                        {
                            [s.hidden]: !loaded || error,
                            [s.imageDisabled]: disabled,
                        },
                    )}
                    src={source}
                    ref={stickerImageRef}
                    onLoad={() => onLoaded()}
                    onError={() => onError()}
                />
            }
        </div>
    );
};
