import { useLogger } from '@hyperclap/ui';
import { useEffect, useState } from 'react';

import { useApi, useWebSocket } from '@hooks';
import { ObsCommand, TWSMessage, WSMessageType } from '@typings';

interface IStreamerParams {
    streamerChannelName?: string;
}

export const useStreamer = (params: IStreamerParams) => {
    const {
        streamerChannelName,
    } = params;

    const {
        users: {
            useGetUserByNameQuery,
        },
    } = useApi();

    const logger = useLogger({ target: useStreamer.name, showTimestamp: true });

    const {
        data: streamer,
        isError,
        isFetching,
        isLoading,
        isSuccess,
        refetch: refetchStreamer,
    } = useGetUserByNameQuery(streamerChannelName, { skip: !streamerChannelName });

    const {
        subscribe,
        unsubscribe,
    } = useWebSocket({
        clientId: 'ma-streamer-hook',
    });

    const [noStreamer, setNoStreamer] = useState(false);

    const onMessageHandler = (message: TWSMessage) => {
        const { type, data } = message;
        logger.trace(JSON.stringify(message));

        if (type === WSMessageType.OBS_COMMAND && data.command === ObsCommand.REFETCH_SETTINGS) {
            refetchStreamer();
        }
    };

    useEffect(() => {
        if (streamer) {
            logger.debug(`Subscribe to WS messages ${streamer.id}`);
            subscribe(streamer.id, onMessageHandler);
        }

        return () => {
            if (streamer) {
                logger.debug(`Unsubscribe from WS messages ${streamer.id}`);
                unsubscribe(streamer.id, onMessageHandler);
            }
        };
    }, [streamer]);

    useEffect(() => {
        setNoStreamer((!isLoading && !isFetching && isSuccess && !streamer) || isError);
    }, [streamer, isLoading, isFetching, isError, isSuccess]);

    useEffect(() => {
        if (!streamerChannelName) {
            logger.debug(`Streamer load request is skipped because "streamerPageLink" is empty`);
            setNoStreamer(true);
        }
    }, []);

    return {
        streamer,
        noStreamer,
        state: {
            isError,
            isLoading: isLoading || isFetching,
            isSuccess,
        },
        refetchStreamer,
    };
};
