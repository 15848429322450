import { useLogger } from '@hyperclap/ui';
import { ISourceOptions } from '@tsparticles/engine';
import { Particles, initParticlesEngine } from '@tsparticles/react';
import React, { useEffect, useState } from 'react';
import { loadFull } from 'tsparticles';

import { confettiConfig } from './confetti-config';

export const Confetti = () => {
    const logger = useLogger({ target: Confetti.name, showTimestamp: true });
    const [init, setInit] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
        logger.debug('Confetti initied');
    }, []);

    if (init) {
        return (
            <Particles
                id='tsparticles'
                options={confettiConfig as ISourceOptions}
            />
        );
    }
};
