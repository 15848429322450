import { TSortConditions } from '@typings';

export interface ISupporter {
    _id: string;
    supporterName: string;
    supporterAvatar: string;
    supporterLink: string;
    supporterId: string;
    balance: number;
    spent: number;
    purchased: number;
    joined: number;
    newbieActionUsed: boolean;
    welcomeBonusEarned: boolean;
}

export interface ISupportersFilters {
    query?: string;
    sort?: TSortConditions<ISupporter>;
}
