import { useEffect, useRef, useState } from 'react';

import { isStickerSentEvent } from '@common';
import { useApi, useCurrentUser, useWebSocket } from '@hooks';
import { EventsFilterPreset } from '@hooks/app/events/Events';
import { IStickerSentEvent, TMemeAlertsEvent, TWSMessage, WSMessageType } from '@typings';

export const useRawEvents = () => {
    const { currentUser, noCurrentUser } = useCurrentUser();
    const {
        events: {
            useLoadEventsQuery,
            useLazyMarkAsReadQuery,
        },
        stickers: {
            useLazyReplayStickerQuery,
            useLazyToggleStickerDisableStateQuery,
        },
    } = useApi();

    const {
        subscribe,
        unsubscribe,
    } = useWebSocket({ clientId: 'memealerts-events' });

    const [replaySticker] = useLazyReplayStickerQuery();

    const [toggleStickerDisableState] = useLazyToggleStickerDisableStateQuery();

    const [markAsRead] = useLazyMarkAsReadQuery();

    const [events, setEvents] = useState<TMemeAlertsEvent[]>([]);
    const [loadedEvents, setLoadedEvents] = useState<TMemeAlertsEvent[]>([]);
    const [filters, setFilters] = useState<Array<EventsFilterPreset>>([
        EventsFilterPreset.PURCHASES,
        EventsFilterPreset.BONUSES,
        EventsFilterPreset.STICKERS,
        EventsFilterPreset.MEME_CANNON,
        EventsFilterPreset.FULLSCREEN,
        EventsFilterPreset.MODERATION,
    ]);

    const { data: fetchedEvents, isFetching } = useLoadEventsQuery({ limit: 100, filters }, { refetchOnMountOrArgChange: true });

    const eventsRef = useRef(events);

    const changeFilters = (val: Array<EventsFilterPreset>) => setFilters(val);

    const onMessageHandler = (message: TWSMessage, callback?:(data: unknown) => void) => {
        const { type, data } = message;

        if (type === WSMessageType.EVENT_CREATED) {
            if (callback) {
                callback({ wasRead: true });
            }

            const event = data.event;
            event.id = data.eventId ?? '';
            event._id = event.id;
            setEvents([event].concat(eventsRef.current));
        }
    };

    const replayClick = async (event: TMemeAlertsEvent) => {
        await replaySticker(event._id);
    };

    const toggleDisableState = async (event: IStickerSentEvent) => {
        const updatedEvent = (await toggleStickerDisableState(event._id).unwrap()) as IStickerSentEvent;
        setEvents([...events].map(
            (e) => isStickerSentEvent(e) && e.stickerId === updatedEvent.stickerId
                ? { ...e, isStickerDisabled: updatedEvent.isStickerDisabled }
                : e,
        ));
        setLoadedEvents([...loadedEvents].map(
            (e) => isStickerSentEvent(e) && e.stickerId === updatedEvent.stickerId
                ? { ...e, isStickerDisabled: updatedEvent.isStickerDisabled }
                : e,
        ));
    };

    useEffect(() => {
        eventsRef.current = events;
    }, [events]);

    useEffect(() => {
        if (fetchedEvents) {
            setLoadedEvents(fetchedEvents as TMemeAlertsEvent[]);
        }
    }, [fetchedEvents]);

    useEffect(() => {
        if (currentUser) {
            subscribe(currentUser.id, onMessageHandler);
        }

        return () => {
            if (currentUser) {
                unsubscribe(currentUser.id, onMessageHandler);
            }
        };
    }, [currentUser]);

    return {
        currentUser,
        unauthorized: noCurrentUser,
        loadedEvents,
        events,
        isLoading: isFetching,
        markAsRead,

        replayClick,
        toggleDisableState,
        changeFilters,
    };
};
