import { Column, ERowAlignment, Row } from '@hyperclap/ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
    IAvatarAsset,
    IAvatarAssetColorsMapVariableItem,
    IAvatarAssetFace,
    IAvatarAssetImage,
    IAvatarAssetImageSize,
} from '@typings';

import s from './AssetList.scss';
import { IAssetListProps } from './types';
import { AssetListItem } from '../AssetListItem';

export const AssetList = (props: IAssetListProps) => {
    const {
        assets,
        filteredAssets,
        displayedAssets,
        variableColorsMap,
        assetsFaces,
        assetsSkinColors,
        assetsCategories,
        layerPositions,
        onSelect,
        onUnSelect,
        onVariableColorsMapSelectColor,
    } = props;
    const { t: translation } = useTranslation();
    const selectedItem = filteredAssets.find((item) => displayedAssets.includes(item));

    const t = (id: string) => translation(`avatar-editor.AssetList.${id}`);

    const handleItemClick = (item: IAvatarAsset) => () => {
        displayedAssets.includes(item)
            ? onUnSelect(item)
            : onSelect(item);
    };

    const handleColorChange = (item: IAvatarAssetColorsMapVariableItem) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = String(event.target.value);

        if (selectedItem) {
            onVariableColorsMapSelectColor(
                selectedItem.id,
                {
                    item,
                    testColor: value,
                    testImage: null as unknown as IAvatarAssetImage,
                    testImageSize: null as unknown as IAvatarAssetImageSize,
                },
            );
        }
    };

    return (
        <Row alignment={ERowAlignment.TOP}>
            <Column>
                <div className={s.list}>
                    {filteredAssets.map((item) => (
                        <AssetListItem
                            key={item.id}
                            asset={item}
                            assets={assets}
                            face={null as unknown as IAvatarAssetFace}
                            isSelected={displayedAssets.includes(item)}
                            assetsFaces={assetsFaces}
                            assetsSkinColors={assetsSkinColors}
                            assetsCategories={assetsCategories}
                            layerPositions={layerPositions}
                            onClick={handleItemClick(item)}
                        />
                    ))}
                </div>
            </Column>

            <Column className={s.columnColors}>
                {selectedItem?.colorsMap.variable.colors.map((item, index) => {
                    const variableItem = variableColorsMap.find((colorMapItem) => colorMapItem.assetId === selectedItem.id);
                    const testColor = variableItem?.colorMap.colors.find((colorMapItem) =>
                        colorMapItem.item.color === item.color,
                    )?.testColor;

                    return (
                        <div
                            key={item.color}
                            className={s.colorPicker}
                        >
                            <div className={s.colorPickerTitle}>{`${t('color')} ${index + 1}`}</div>

                            <div
                                className={s.colorPickerActionArea}
                                style={{ backgroundColor: testColor || item.color }}
                            >
                                <input
                                    className={s.colorPickerInput}
                                    type="color"
                                    onChange={handleColorChange(item)}
                                />
                            </div>
                        </div>
                    );
                })}
            </Column>
        </Row>
    );
};
