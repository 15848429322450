import { useEffect, useState } from 'react';

import { useApi } from '@hooks';

export const useStreamerPaymentsSettings = () => {
    const {
        channel: {
            useUpdateChannelCurrencyImageMutation,
            useResetChannelCurrencyImageMutation,
            useSetChannelCurrencyNameMutation,
            useSetChannelSupporterNameMutation,
        },
    } = useApi();

    const [updateCurrencyImage] = useUpdateChannelCurrencyImageMutation();
    const [resetCurrencyImage] = useResetChannelCurrencyImageMutation();
    const [isCurrencyImageCleared, setIsCurrencyImageCleared] = useState(false);
    const [setChannelCurrencyName] = useSetChannelCurrencyNameMutation();
    const [setChannelSupporterName] = useSetChannelSupporterNameMutation();

    const [channelCurrencyImage, setChannelCurrencyImage] = useState<File>();
    const [channelCurrencyImageData, setChannelCurrencyImageData] = useState('');

    const updateChannelCurrencyImage = () => {
        if (channelCurrencyImage) {
            const form = new FormData();
            form.append('image', channelCurrencyImage);
            updateCurrencyImage(form);
        }
    };

    const onChangeChannelCurrencyImage = (file: File) => {
        setChannelCurrencyImage(file);
    };

    const onResetCurrencyImage = () => {
        setChannelCurrencyImage(undefined);
        setChannelCurrencyImageData('');
        setIsCurrencyImageCleared(true);
    };

    useEffect(() => {
        if (channelCurrencyImage) {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (reader.result) {
                    setChannelCurrencyImageData(reader.result.toString());
                    setIsCurrencyImageCleared(false);
                }
            };

            reader.readAsDataURL(channelCurrencyImage);
        }
    }, [channelCurrencyImage]);

    return {
        isCurrencyImageCleared,
        channelCurrencyImageData,
        onChangeChannelCurrencyImage,
        setChannelCurrencyName,
        setChannelSupporterName,
        updateChannelCurrencyImage,
        onResetCurrencyImage,
        resetCurrencyImage,
    };
};
