import { useLogger } from '@hyperclap/ui';
import cn from 'classnames';
// eslint-disable-next-line import/no-unresolved
import config from 'config';
import React, { useEffect, useRef, useState } from 'react';

import { DEFAULT_GEOMETRY, IGeometry, ISticker, StickerSize } from '@typings';

import s from './StickerPlayer.scss';


interface StickerPlayerProps {
    uid?: string;
    sticker: ISticker;
    geometry?: IGeometry;
    size?: string;
    volume?: number;
    hidden?: boolean;
    fullscreen?: boolean;
    useAnimation?: boolean;
    pageBackgroundUrl?: string,
    pageBackgroundMode?: number,
    isCopyrightFreeOnly?: boolean;
    onPlayEnd?: () => void;
}

export const StickerPlayer = (props: StickerPlayerProps) => {
    const {
        sticker: {
            stickerUrl,
            stickerPreview,
            customSettings,
            videoData,
        },
        geometry: {
            x,
            y,
            rotation,
        } = { ...DEFAULT_GEOMETRY },
        size = StickerSize.MEDIUM,
        volume = 100,
        hidden = false,
        useAnimation = false,
        fullscreen = false,
        onPlayEnd,
    } = props;

    const { trace } = useLogger({ target: 'StickerPlayer' });

    const fullscreenStyles = {
        backgroundImage: `url(${stickerPreview})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    };

    const styles = {
        top: y,
        left: x,
        transform: `rotate(${rotation}deg)`,
        ...fullscreenStyles,
    };

    const container = useRef<HTMLVideoElement>(null);

    const [videoStyles, setVideoStyles] = useState({ });
    const [fadeOut, setFadeOut] = useState(false);

    const calcVideoStyles = () => { // TODO: Try to calc it outside of StickerPlayer
        const sizeName = size ?? StickerSize.MEDIUM;
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const videoWidth = container.current!.offsetWidth;
        const videoHeight = container.current!.offsetHeight;
        const videoStyles: Record<string, unknown> = { opacity: 1 };

        const viewportDiagonal = Math.sqrt(Math.pow(viewportWidth, 2) + Math.pow(viewportHeight, 2));
        const stickerSizePercent = config?.obs.stickerSize[sizeName] ?? 25;

        trace(`Size: ${sizeName}, Percent: ${stickerSizePercent}%`);

        if (fullscreen && videoData) {
            videoStyles.width = '100%';
            videoStyles.height = '100%';
        } else {
            if (videoWidth >= videoHeight) {
                const videoWidth = stickerSizePercent * viewportDiagonal / 100 + 'px';
                const videoHeight = 'auto';
                trace(`Calculated video dimensions: Width = ${videoWidth}, Height = ${videoHeight}`);
                videoStyles.width = videoWidth;
                videoStyles.height = videoHeight;
                videoStyles.borderRadius = stickerSizePercent * viewportDiagonal / 100 * 0.05;
            } else {
                trace(`Video width < Video height, calculating video size using ${stickerSizePercent}% of the height`);
                const videoWidth = 'auto';
                const videoHeight = stickerSizePercent * viewportDiagonal / 100 + 'px';
                trace(`Calculated video dimensions: Width = ${videoWidth}, Height = ${videoHeight}`);
                videoStyles.width = videoWidth;
                videoStyles.height = videoHeight;
                videoStyles.borderRadius = stickerSizePercent * viewportDiagonal / 100 * 0.05;
            }
        }

        return videoStyles;
    };

    const minStickerPlayingTimeSec: number = config?.obs?.minStickerPlayingTimeSec ?? 3;

    const onLoaded = (e: React.SyntheticEvent<HTMLVideoElement>) => {
        const videoDuration = e.currentTarget.duration || minStickerPlayingTimeSec;

        if (videoDuration && onPlayEnd) {
            const duration = videoDuration < minStickerPlayingTimeSec ? minStickerPlayingTimeSec : videoDuration;
            setTimeout(() => onPlayEnd(), duration * 1000);
            setTimeout(() => setFadeOut(true), duration * 1000 - 500);
        }

        if (container.current) {
            setVideoStyles(calcVideoStyles());
        }
    };

    useEffect(() => {
        if (container.current) {
            const vol = customSettings?.customVolumeEnabled && customSettings?.customVolume !== undefined
                ? customSettings?.customVolume
                : volume;
            container.current.volume = vol / 100;
        }
    }, [volume]);

    return (
        <div
            className={cn(
                s.stickerPlayer,
                {
                    [s.stickerPlayerHidden]: hidden,
                    [s.stickerPlayerFullscreen]: fullscreen,
                    [s.stickerPlayerAnimated]: useAnimation && !hidden,
                    [s.stickerPlayerAnimatedFadeOut]: useAnimation && fadeOut && !hidden,
                },
            )}
            style={styles}
        >
            <video
                ref={container}
                className={cn(s.video, { [s.videoWithBorder]: !videoData?.isTransparent && !fullscreen })}
                autoPlay={true}
                controls={false}
                src={stickerUrl}
                style={{ ...videoStyles }}
                onPlay={(e) => onLoaded(e)}
            />
        </div>
    );
};
