import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line import/no-unresolved
import config from 'config';

export const baseApi = createApi({
    reducerPath: 'app/version-api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.hosts.appHost,
    }),
    endpoints: () => ({}),
});
