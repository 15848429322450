import { baseApi } from './base.api';

/** Telegram API provides methods are user to login/register via Telegram */
export const telegramApi = baseApi
    .injectEndpoints({
        endpoints: (build) => ({

            /** Sends a request to register via Telegram (Memozavr Bot) */
            telegramBotRegister: build.query<void, string>({
                query: (params: string) => ({
                    url: 'telegram/register?' + params,
                    method: 'GET',
                }),
            }),

            /** Sends a request to login via Telegram (Memozavr Bot) */
            telegramBotAuth: build.query<void, string>({
                query: (params: string) => ({
                    url: 'telegram/auth?' + params,
                    method: 'GET',
                }),
            }),
        }),
    });
