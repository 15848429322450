import { Period, IDashboardPeriodData } from '@typings';

import { baseApi } from './base.api';

export const dashboardApi = baseApi
    .enhanceEndpoints({
        addTagTypes: ['Dashboard_Data'],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            loadDashboardData: build.query<IDashboardPeriodData, Period>({
                query: (period: Period) => ({
                    url: 'dashboard',
                    method: 'POST',
                    body: { period },
                }),
                providesTags: ['Dashboard_Data'],
            }),
        }),
    });
