import {
    Button,
    Column,
    Hint,
    Icon,
    Row,
    TextField,
    ERowJustification,
    EColumnAlignment,
    EColumnJustification,
    EComponentColor,
} from '@hyperclap/ui';
import React, { useState } from 'react';

import { IconHuskySolid } from '@assets/images/svg';
import { useApp } from '@hooks';

import s from './InvitePrompt.scss';

export const InvitePrompt = () => {
    const app = useApp();

    const {
        invites: {
            activateInvite,
            activationErrorMessage,
            clearActivationErrorMessage,
        },
    } = app;

    const [inviteCode, setInviteCode] = useState<string>('');

    const onActivationClick = () => {
        void activateInvite(inviteCode);
    };

    const onInviteChange = (value: string) => {
        setInviteCode(value);
        clearActivationErrorMessage();
    };

    return (
        <Column grow rowGap={20} justification={EColumnJustification.CENTER}>
            <Column rowGap={20} alignment={EColumnAlignment.CENTER}>
                <Icon icon={<IconHuskySolid />} width={80} height={80} className={s.inviteIcon}/>
                <Hint className={s.inviteDescription}>
                    MemeAlerts работает по приглашениям. Вы можете получить инвайт в канале
                    <a href={'https://t.me/memealerts_com'} target={'_blank'} rel="noreferrer">https://t.me/memealerts_com</a>
                </Hint>
            </Column>
            <Row justification={ERowJustification.CENTER}>
                <TextField
                    caption={'Ваш инвайт'}
                    onChanged={onInviteChange}
                    width={216}
                    errorMessage={activationErrorMessage}
                    hasError={!!activationErrorMessage}
                />
            </Row>
            <Row justification={ERowJustification.CENTER}>
                <Button
                    caption={'Активировать'}
                    color={EComponentColor.ACCENT}
                    disabled={!inviteCode}
                    width={160}
                    onClick={onActivationClick}
                />
            </Row>
        </Column>
    );
};
