import {
    Button,
    Column,
    Hint,
    Label,
    Row,
    Switch,
    TextArea,
    useModals,
    EComponentColor,
    EComponentSize,
} from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React, { useEffect, useState } from 'react';

import { IconCloseLine } from '@assets/images/svg';
import { IChannel, IUser } from '@typings';

import s from './CustomBadWordsPopup.scss';

interface ICustomBadWordsPopupProps {
    currentUser: IUser;
    update?: (data: Partial<IChannel>) =>void;
}

export const CustomBadWordsPopup = (props: ICustomBadWordsPopupProps) => {
    const { closeModal } = useModals();
    const {
        currentUser,
        update = noop,
    } = props;

    const [antiSwearBadWords, setAntiSwearBadWords] = useState('');
    const [antiSwearRemoveLinks, setAntiSwearRemoveLinks] = useState(false);

    const onSaveClick = () => {
        update({
            antiSwearBadWords,
            antiSwearRemoveLinks,
        });
    };

    useEffect(() => {
        if (currentUser) {
            setAntiSwearBadWords(currentUser.channel?.antiSwearBadWords ?? '');
            setAntiSwearRemoveLinks(currentUser.channel?.antiSwearRemoveLinks ?? false);
        }
    }, [currentUser]);

    return (
        <Column className={s.customBadWordsPopup}>
            <Column className={s.customBadWordsPopupContent}>
                <Label caption={'Спам фильтр'} size={EComponentSize.LARGE}/>
                <Label
                    caption={'Введите список запрещенных слов через пробел. Они будут заменяться на звездочки в сообщениях к стикерам.'}
                    style={{ lineHeight: '20px' }}
                    size={EComponentSize.SMALL}
                />
                <Row>
                    <TextArea
                        maxRows={10}
                        defaultValue={currentUser?.channel?.antiSwearBadWords}
                        onChange={(val) => setAntiSwearBadWords(val?.toString() ?? '')}
                    />
                </Row>
                <Row margin={{ top: 5 }}>
                    <Label caption='Удалять ссылки' size={EComponentSize.MEDIUM}/>
                    <Switch
                        checked={currentUser?.channel?.antiSwearRemoveLinks}
                        color={EComponentColor.ACCENT}
                        onChange={setAntiSwearRemoveLinks}
                    />
                </Row>
                <Hint muted textSize={EComponentSize.SMALL}>
                    {'Заменять ссылки в сообщениях на звёздочки.'}
                </Hint>
                <Row className={s.footerRow}>
                    <Button
                        color={EComponentColor.ACCENT}
                        caption={'Сохранить'}
                        width={160}
                        onClick={onSaveClick}
                    />
                </Row>
            </Column>

            <div className={s.closeButton} onClick={closeModal}>
                <IconCloseLine/>
            </div>
        </Column>
    );
};
