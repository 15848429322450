import { IAvatarAssetColorsMapPermanent } from './IAvatarAssetColorsMapPermanent';
import { IAvatarAssetColorsMapVariable } from './IAvatarAssetColorsMapVariable';
import { IAvatarAssetLayer } from './IAvatarAssetLayer';

export interface IAvatarAsset {
    id: string;
    _id?: string;
    title: string;
    categoryId: string; // IAssetCategory->id
    layers: IAvatarAssetLayer[];
    hiddenLayerPositions: string[]; // Array<IAssetLayerPosition->id>
    colorsMap: {
        variable: IAvatarAssetColorsMapVariable;
        permanent: IAvatarAssetColorsMapPermanent;
    };
    sortIndex: number;
}
