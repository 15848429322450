import { useLogger } from '@hyperclap/ui';
import { useEffect, useState } from 'react';

import { useApi } from '@hooks';
import { IHookBaseParams, ISticker } from '@typings';

const PAGE_SIZE = 20;

export enum FavoritesMode {
    FAVORITES = 0,
    SENT = 1,
}

interface IFavoritesParams extends IHookBaseParams {}

export const useFavorites = (params: IFavoritesParams) => {
    const logger = useLogger({ target: useFavorites.name, showTimestamp: true });
    const {
        active,
    } = params;

    const {
        stickers: {
            useLazyLoadPagePersonalAreaFavoritesQuery,
        },
    } = useApi();

    const [loadPage, { isFetching: isStickersFetching, isError }] = useLazyLoadPagePersonalAreaFavoritesQuery();

    const [favoritesMode, setFavoritesMode] = useState<FavoritesMode>(FavoritesMode.FAVORITES);
    const [stickers, setStickers] = useState<Array<ISticker>>([]);
    const [isEndReached, setIsEndReached] = useState(false);

    const loadNextPage = async () => {
        if (!active || isEndReached || isStickersFetching) return;

        if (isError) {
            logger.error(`Previous page loading failed. Stop loading next pages`);

            return;
        }

        const loadedPage = await loadPage({
            limit: PAGE_SIZE,
            skip: stickers.length,
            onlyFavorites: favoritesMode === FavoritesMode.FAVORITES,
            onlySent: favoritesMode === FavoritesMode.SENT,
        }, false).unwrap();

        if (loadedPage?.length) {
            setStickers(stickers.concat(loadedPage));
        }

        setIsEndReached(!loadedPage?.length || loadedPage?.length < PAGE_SIZE);
    };

    const updateLoadedSticker = (sticker: ISticker) => {
        setStickers(stickers.map((s) => s.id === sticker.id ? sticker : s));
    };

    const deleteLoadedSticker = (sticker: ISticker) => {
        setStickers(stickers.filter((s) => s.id !== sticker.id));
    };

    const changeFavoritesMode = (mode: FavoritesMode) => {
        setIsEndReached(false);
        setStickers([]);
        setFavoritesMode(mode);
    };

    useEffect(() => {
        if (!active) {
            setStickers([]);
            setIsEndReached(false);
        }

        if (active && !stickers.length) {
            void loadNextPage();
        }
    }, [active]);

    useEffect(() => {
        void loadNextPage();
    }, [favoritesMode]);

    return {
        favoritesMode,
        isEndReached,
        isStickersFetching,
        stickers,

        deleteLoadedSticker,
        loadNextPage,
        changeFavoritesMode,
        updateLoadedSticker,
    };
};
