import {
    Button,
    Column,
    Divider,
    Label,
    Row,
    Switch,
    EComponentColor,
    EComponentSize,
} from '@hyperclap/ui';
import React, { useEffect, useState } from 'react';

import { useAppContext } from '@hooks';
import { Grid } from '@views';

export const AlertsSettings = () => {
    const app = useAppContext();
    const {
        currentUser: {
            currentUser,
        },
        settings: {
            streamer: {
                page: {
                    updateChannel,
                },
            },
        },
        translation: {
            t,
        },
    } = app;

    const [isPopupNickNameEnabled, setPopupNickNameEnabled] = useState<boolean>(false);
    const [isPopupNewSaleEnabled, setPopupNewSaleEnabled] = useState<boolean>(false);
    const [isNewSupporterAlertEnable, setIsNewSupporterAlertEnable] = useState<boolean>(false);
    const [isBonusAlertEnabled, setIsBonusAlertEnabled] = useState<boolean>(false);
    const [popupNickNameAreaMask, setPopupNickNameAreaMask] = useState(128);

    const onSave = () => {
        void updateChannel({
            isPopupNewSaleEnabled,
            isPopupNickNameEnabled,
            isBonusAlertEnabled,
            popupNickNameAreaMask,
            isNewSupporterAlertEnable,
        });
    };

    useEffect(() => {
        if (currentUser?.channel) {
            setPopupNickNameAreaMask(currentUser.channel?.popupNickNameAreaMask ?? 128);
            setPopupNewSaleEnabled(currentUser.channel?.isPopupNewSaleEnabled);
            setIsNewSupporterAlertEnable(currentUser.channel?.isNewSupporterAlertEnable);
            setPopupNickNameEnabled(currentUser.channel?.isPopupNickNameEnabled);
            setIsBonusAlertEnabled(currentUser.channel?.isBonusAlertEnabled);
        }
    }, [currentUser]);

    return (
        <Row padding={{ top: 15, bottom: 50 }}>
            <Column grow>
                <Label
                    padding={{ bottom: 10 }}
                    caption={t('streamer-settings.alerts.display-section')}
                    size={EComponentSize.LARGE}
                />

                <Row margin={{ bottom: 10 }}>
                    <Column width={400}>
                        <Label caption={t('streamer-settings.alerts.alerts-position')} size={EComponentSize.MEDIUM}/>
                        <Grid
                            mask={popupNickNameAreaMask}
                            multiselect={false}
                            skipSecondRow={true}
                            onSelectionChange={setPopupNickNameAreaMask}
                        />
                    </Column>
                </Row>

                <Divider margin={{ top: 20, bottom: 10 }} width={510} />

                <Label padding={{ bottom: 10 }} caption={t('streamer-settings.alerts.alerts-section')} size={EComponentSize.LARGE}/>

                <Row margin={{ bottom: 5 }}>
                    <Label caption={t('streamer-settings.alerts.currency-purchased-alert')} size={EComponentSize.MEDIUM} width={240}/>
                    <Switch
                        checked={currentUser?.channel.isPopupNewSaleEnabled}
                        color={EComponentColor.ACCENT}
                        onChange={setPopupNewSaleEnabled}
                    />
                </Row>

                <Row margin={{ bottom: 5 }}>
                    <Label caption={t('streamer-settings.alerts.bonus-earned-alert')} size={EComponentSize.MEDIUM} width={240}/>
                    <Switch
                        checked={currentUser?.channel.isBonusAlertEnabled}
                        color={EComponentColor.ACCENT}
                        onChange={setIsBonusAlertEnabled}
                    />
                </Row>

                <Row margin={{ bottom: 5 }}>
                    <Label caption={t('streamer-settings.alerts.new-supporter-alert')} size={EComponentSize.MEDIUM} width={240}/>
                    <Switch
                        checked={currentUser?.channel.isNewSupporterAlertEnable}
                        color={EComponentColor.ACCENT}
                        onChange={setIsNewSupporterAlertEnable}
                    />
                </Row>

                <Row margin={{ bottom: 5 }}>
                    <Label caption={t('streamer-settings.alerts.sticker-sent-alert')} size={EComponentSize.MEDIUM} width={240}/>
                    <Switch
                        checked={currentUser?.channel.isPopupNickNameEnabled}
                        color={EComponentColor.ACCENT}
                        onChange={setPopupNickNameEnabled}
                    />
                </Row>

                <Row margin={{ top: 15 }}>
                    <Button
                        caption={t('common.save')}
                        color={EComponentColor.ACCENT}
                        width={160}
                        onClick={onSave}
                    />
                </Row>
            </Column>
        </Row>
    );
};
