import { noop } from '@hyperclap/utils';
import { t } from 'i18next';
import React, { MouseEvent } from 'react';

import { ImageDefaultCurrency } from '@assets/images/png';
import { timestampToShortDate } from '@common';
import { Avatar } from '@components';
import { IUser } from '@typings';
import { ISupporter } from '@typings/api/Supporters';

import s from './SupportersTable.scss';

interface ISupportersTableProps {
    data?: Array<ISupporter>;
    currentUser: IUser;

    onSupporterClick: (val: ISupporter) => void;
}

export const SupportersTable = (props: ISupportersTableProps) => {
    const {
        data = [],
        currentUser,
        onSupporterClick = noop,
    } = props;

    const onRowClick = (e: MouseEvent, val: ISupporter) => {
        e.stopPropagation();
        onSupporterClick(val);
    };

    const currencyIcon = <Avatar
        className={s.roundedAvatar}
        source={(currentUser?.channel?.currencyImageUrl || ImageDefaultCurrency)}
        width={12}
        height={12}
        rounded
    />;

    const items = data.map((item, idx) => (
        <tr key={idx} className={s.supportersTableBodyRow} onClick={(e) => onRowClick(e, item)}>
            <td className={s.supportersTableBodyRowCell}>{item.supporterName}</td>
            <td className={s.supportersTableBodyRowCell}>{timestampToShortDate(item.joined)}</td>
            <td className={s.supportersTableBodyRowCell}>{item.purchased}</td>
            <td className={s.supportersTableBodyRowCell}>{item.spent}</td>
            <td className={s.supportersTableBodyRowCell}>{item.balance}</td>
        </tr>
    ));

    return (
        <table className={s.supportersTable}>
            <thead>
                <tr className={s.supportersTableHeadRow}>
                    <td className={s.supportersTableHeadRowCell}>{t('supporters.table.supporter')}</td>
                    <td className={s.supportersTableHeadRowCell}>{t('supporters.table.joined')}</td>
                    <td className={s.supportersTableHeadRowCell}>
                        <div className={s.supportersTableHeadRowCellFlex}>
                            {currencyIcon}{t('supporters.table.purchased')}
                        </div>
                    </td>
                    <td className={s.supportersTableHeadRowCell}>
                        <div className={s.supportersTableHeadRowCellFlex}>
                            {currencyIcon}{t('supporters.table.spent')}
                        </div>
                    </td>
                    <td className={s.supportersTableHeadRowCell}>
                        <div className={s.supportersTableHeadRowCellFlex}>
                            {currencyIcon}{t('supporters.table.balance')}
                        </div>
                    </td>
                </tr>
            </thead>
            <tbody>
                {items}
            </tbody>
        </table>
    );
};
