import { EComponentSize } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import { alternateArrays } from '@common';
import { Avatar } from '@components';
import { EPopupTypes, IPopupInfo, IPopupNickName } from '@hooks';

import s from './PopupNickName.scss';

interface PopupNickNameProps {
    popupInfo?: IPopupInfo;
}

export const PopupNickName = (props: PopupNickNameProps) => {
    const {
        popupInfo,
    } = props;

    const info = popupInfo as IPopupNickName;

    let message: string | JSX.Element | JSX.Element[] | undefined = info?.message;

    if (info?.message) {
        // eslint-disable-next-line max-len
        const emojiesRegExp = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
        const emojies = info.message?.match(emojiesRegExp);

        if (emojies?.length) {
            const words = info.message.split(emojiesRegExp);
            // eslint-disable-next-line max-len
            const wordsElements = words.map((word, idx) => (<span key={`w${idx}`} className={cn(s.messageText, { [s.messageTextFullscreen]: info.fullscreen })}>{word}</span>));
            const emojiesElements = emojies?.map((emoji, idx) => (<span key={`e${idx}`} className={s.messageEmoji}>{emoji}</span>)) ?? [];
            message = alternateArrays(wordsElements, emojiesElements);
        } else {
            message = <span className={cn(s.messageText, { [s.messageTextFullscreen]: info.fullscreen })}>{message}</span>;
        }
    } else if (info?.stickerName) {
        message = <span className={cn(s.messageText, { [s.messageTextFullscreen]: info.fullscreen })}>{info.stickerName}</span>;
    }

    return (
        <div className={s.popupNicknameWidget}>
            {popupInfo && popupInfo.type === EPopupTypes.NICK_NAME &&
                <div className={s.container}>
                    <div className={s.left}>
                        <div className={s.picture}>
                            <Avatar
                                source={info?.userAvatar}
                                rounded
                                isUserAvatar
                                size={EComponentSize.EXTRA_LARGE}
                            />
                        </div>
                    </div>
                    <div className={s.right}>
                        <div className={s.description}>
                            <div className={s.descriptionName}>{ info?.userAlias || 'АНОНИМ' }</div>
                            <div className={s.descriptionAction}>{info?.fullscreen ? 'ЗАФУЛЛСКРИНИЛ' : 'ЗАМЕМАСИЛ'}</div>
                        </div>
                        <div className={s.message}>
                            { message }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
