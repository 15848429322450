import { Logger } from '@hyperclap/ui';
// eslint-disable-next-line import/no-unresolved
import config from 'config';
import { io, Socket } from 'socket.io-client';

import { EAuthMode, TWSMessage } from '@typings';

import { AUTH_TOKEN_NAME, OBS_TOKEN_NAME } from '../const';

export interface WebSocketClientParams {
    clientId: string;
    queryParams?: Record<string, unknown>;
    onConnect?: (client: WebSocketClient) => void;
}

export const DEFAULT_CLIENT_NAME = 'meme-alerts-client';

export const defaultWebSocketClientParams: WebSocketClientParams = {
    queryParams: {},
    clientId: DEFAULT_CLIENT_NAME,
};

/** Websocket client with using socket.io */
export class WebSocketClient {
    private readonly logger = new Logger({ target: 'WebSocketClient', showTimestamp: true });

    private readonly params: WebSocketClientParams;

    /* Single instance of WebSocketClient */
    private static instance: WebSocketClient;

    /* Socket object */
    private socket: Socket | null = null;

    /* Protected constructor for singleton pattern support */
    protected constructor(params: WebSocketClientParams = defaultWebSocketClientParams) {
        this.params = params;
    }

    /* Invoked after connect to the WS gateway */
    protected onConnect() {
        this.logger.trace(`Connected to Gateway`);

        if (this.params.onConnect) {
            this.logger.trace('Executing on connect');
            this.params.onConnect?.(this);
        }
    }

    /* Invoked after disconnect from the WS gateway */
    protected onDisconnect() {
        this.logger.trace(`Disconnected from gateway`);
    }

    /* Returns single instance of the client */
    public static getInstance(params: WebSocketClientParams = defaultWebSocketClientParams) {
        if (!this.instance) {
            this.instance = new WebSocketClient(params);
            this.instance.init();
        }

        return this.instance;
    }

    /** Opens connection and set connect/disconnect handlers */
    private init() {
        const wsHost = config?.hosts.wsHost;

        if (!wsHost) {
            console.log('No WebSocket host is defined, could not create websocket connection');

            return;
        }

        const { clientId, queryParams } = this.params;
        const additionalQueryParams = { ...queryParams, clientId };

        this.logger.trace(
            `Connecting websocket gateway using: Client ID: ${clientId}, Additional params: ${JSON.stringify(additionalQueryParams)}`,
        );

        const opts = { query: additionalQueryParams, auth: {} };

        const mode = new URLSearchParams(window.location.search).get('mode') || EAuthMode.DEFAULT;

        const lsToken = localStorage.getItem(AUTH_TOKEN_NAME) ?? '';
        const obsToken = localStorage.getItem(OBS_TOKEN_NAME) ?? '';

        if (lsToken && mode != EAuthMode.OBS) {
            opts.auth = { token: lsToken };
        } else if (obsToken) {
            opts.auth = { token: obsToken };
        }

        this.socket = io(wsHost, opts);
        this.socket.on('connect', () => this.onConnect());
        this.socket.on('disconnect', () => this.onDisconnect());
    }

    public subscribe(channel: string, callback: (data: TWSMessage) => void) {
        if (channel) {
            this.socket?.on(channel, callback);
        }
    }

    public unsubscribe(channel: string, callback: (data: TWSMessage) => void) {
        if (channel) {
            this.socket?.off(channel, callback);
        }
    }

    public emit(channel: string, data: unknown) {
        if (channel) {
            this.socket?.emit(channel, data);
        }
    }
}
