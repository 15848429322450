import React from 'react';

import { useNotifications } from '@hooks';

import { Notification } from './Notification';
import s from './NotificationArea.scss';

interface NotificationAreaProps {
    maxNotificationCount?: number;
}

export const NotificationArea = (props: NotificationAreaProps) => {
    const {
        maxNotificationCount,
    } = props;

    const {
        notifications,
        close,
    } = useNotifications();

    return (
        <div className={s.notificationArea}>
            {
                notifications.slice(0, maxNotificationCount).map((n) => (
                    <Notification key={n.uid} {...n} onClose={() => close(n.uid)}/>
                ))
            }
        </div>
    );
};
