import { useDeviceInfo } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    IconChartLine,
    IconCogLine,
    IconFace,
    IconLogoutLine,
    IconSmileBeamLine,
    IconVideoLine,
} from '@assets/images/svg';
import { useApi, useInvites } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';
import { IUser } from '@typings';

import s from './ProfileMenu.scss';
import { ProfileMenuItem } from './ProfileMenuItem';


interface IProfileMenuProps {
    className?: string;
    currentUser: IUser;
    onLogout: () => Promise<void>;
}

export const ProfileMenu = (props: IProfileMenuProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { isMobile } = useDeviceInfo();

    const {
        className,
        currentUser,
        onLogout,
    } = props;

    const {
        util,
    } = useApi();

    const {
        inviteActivated,
    } = useInvites();

    const navigate = useNavigate();

    const {
        goToStickersCatalogue,
        goToDashboard,
        goToChannelSettings,
        goToSettings,
    } = useNavigator();

    const onExitClick = async () => {
        await onLogout();
        dispatch(util.resetApiState());
        localStorage.removeItem('token');
        navigate('/');
    };

    return (
        <div className={cn(s.profileMenu, className)}>
            {currentUser && !inviteActivated && !isMobile &&
                <ProfileMenuItem icon={<IconSmileBeamLine/>} caption={t('common.stickers')} onClick={goToStickersCatalogue} />
            }
            {currentUser && inviteActivated && !isMobile &&
                <ProfileMenuItem icon={<IconChartLine/>} caption={t('common.dashboard')} onClick={goToDashboard} />
            }
            {currentUser && !isMobile &&
                <ProfileMenuItem icon={<IconVideoLine/>} caption={t('common.streamer-area')} onClick={goToChannelSettings} />
            }
            {currentUser && !isMobile &&
                <ProfileMenuItem icon={<IconFace/>} caption={t('common.streamoji')} onClick={goToSettings} />
            }
            {currentUser && !isMobile &&
                <ProfileMenuItem icon={<IconCogLine/>} caption={t('common.settings')} onClick={goToSettings} />
            }
            {currentUser && isMobile &&
                <ProfileMenuItem icon={<IconCogLine/>} caption={t('common.streamers')} onClick={goToDashboard} />
            }
            <ProfileMenuItem icon={<IconLogoutLine/>} caption={t('common.logout')} onClick={onExitClick} />
        </div>
    );
};
