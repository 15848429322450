import { IMemeCreatorResult, IMemeCreatorTask } from '@typings';

import { baseApi } from './base.api';

export const tasksApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        runPrepareTask: build.query<IMemeCreatorTask, FormData>({
            query: (imageData: FormData) => ({
                url: 'prepare',
                method: 'POST',
                body: imageData,
            }),
        }),
        runPreviewTask: build.query<IMemeCreatorTask, FormData>({
            query: (stickerData: FormData) => ({
                url: 'preview',
                method: 'POST',
                body: stickerData,
            }),
        }),
        runCompleteTask: build.query<IMemeCreatorTask, FormData>({
            query: (stickerData: FormData) => ({
                url: 'complete',
                method: 'POST',
                body: stickerData,
            }),
        }),
        checkTaskResult: build.query<IMemeCreatorResult, FormData>({
            transformResponse: ( result: IMemeCreatorResult ) => {
                if (result.result?.previewUrl) {
                    result.result.previewUrl += `?nocache=${Math.random()}`;
                }

                return result;
            },
            query: (taskData: FormData) => ({
                url: 'result',
                method: 'POST',
                body: taskData,
            }),
        }),
    }),
});
