import {
    EOrientation,
    ERelativePosition,
} from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React from 'react';

import { Menu, MenuItem, MenuItemProps } from '@components';

import s from './Tabs.scss';

export interface ITabParams<T> {
    caption: string;
    tag: T;
}

interface TabsProps<T = unknown> {
    activeTab?: T,
    tabs: Array<ITabParams<T>>;
    onSelectionChange?: (tab?: T) => void;
}

const defaultMenuItemProps: MenuItemProps = {
    activeBorderPosition: ERelativePosition.BOTTOM,
    useActiveBorder: true,
};

export const Tabs = <T, >(props: TabsProps<T>) => {
    const {
        activeTab,
        tabs = [],
        onSelectionChange = noop,
    } = props;

    const onTabClick = (tag?: T) => {
        onSelectionChange(tag);
    };

    const menuItems = tabs.map((itemProps, idx) => (
        <MenuItem
            {...itemProps}
            {...defaultMenuItemProps}
            active={itemProps.tag === activeTab}
            tag={itemProps.tag}
            key={idx}
            onClick={onTabClick}
        />
    ));

    return (
        <Menu orientation={EOrientation.HORIZONTAL} className={s.tabs}>
            {menuItems}
        </Menu>
    );
};
