import React from 'react';
import { RouteObject } from 'react-router-dom';

import { Dashboard } from '@views';

export const DashboardRoutes: Array<RouteObject> = [
    {
        path: '',
        element: <Dashboard/>,
    },
];
