import {
    Row,
    TextField,
} from '@hyperclap/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IUser } from '@typings';

import s from './PersonalConfig.scss';

interface PersonalConfigProps {
    currentUser?: IUser;
    onNameChange(value: string): void;
}

export const PersonalConfig = (props: PersonalConfigProps) => {
    const {
        currentUser,
        onNameChange,
    } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`PersonalSettings.PersonalConfig.${id}`);

    return (
        <Row className={s.root}>
            <TextField
                caption={t('label')}
                maxLength={50}
                defaultValue={currentUser?.name}
                width={300}
                onChanged={onNameChange}
            />
        </Row>
    );
};
