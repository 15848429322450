import { Logger } from '@hyperclap/ui';
import React from 'react';
import { RouteObject } from 'react-router-dom';

import {
    AuthRedirect,
    ObsPage,
    RootRedirect,
    StreamerArea,
    SuccessPaymentRedirect,
} from '@views';

import { AuthRequired } from './guards';
import {
    StreamerAreaRoutes,
} from './routes';

const logger = new Logger({ target: 'Routing', showTimestamp: true });
logger.trace(`Prepare routes`);

export const routing: Array<RouteObject> = [
    // Home page
    {
        path: '/',
        element: <AuthRequired><RootRedirect/></AuthRequired>,
    },
    // Special routes
    {
        path: '/auth/redirect',
        element: <AuthRedirect />,
    },
    // Payment redirect special route
    {
        path: '/payments/success',
        element: <SuccessPaymentRedirect />,
    },
    // Channel area
    {
        path: '/:streamerChannelName',
        element: <StreamerArea/>,
        children: [...StreamerAreaRoutes],
    },
    // OBS layer page
    {
        path: '/r/:channelname',
        element: <ObsPage />,
    },
];
