import {
    Column,
    Row,
    TransparentButton,
    Button,
    // Select,
    useModals,
    ERowAlignment,
    EColumnAlignment,
    EComponentColor,
    // ISelectOption,
} from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconArrowLeftLine, IconBagAlt, IconMagic } from '@assets/images/svg';
import { TApp } from '@hooks';
// import { IAvatarAssetFaceEmotion } from '@typings';

import s from './AvatarEditor.scss';
import { AssetList } from './components/AssetList';
import { AssetsControl } from './components/AssetsControl';
import { CategoryList } from './components/CategoryList';
import { CategoryTabs } from './components/CategoryTabs';
import { ColorSelector } from './components/ColorSelector';
import { FaceList } from './components/FaceList';
import { EPreviewSize, Preview } from './components/Preview';
import { useAvatarEditor } from './hooks';
import { PersonalConfig } from '../PersonalConfig';
import { PersonalInfo } from '../PersonalInfo';
// import { Avatar } from '@components';

interface IAvatarEditorProps extends TApp {}

export const AvatarEditor = (props: IAvatarEditorProps) => {
    const {
        currentUser: {
            currentUser,
        },
        settings: {
            personal: {
                updateName,
            },
        },
    } = props;
    const {
        isLoading,
        assets,
        assetsFaces,
        assetsFacesEmotionsCategories,
        assetsCategories,
        displayedAssets,
        displayedFace,
        displayedFaceEmotion,
        assetsLayerPositions,
        hiddenLayerPositions,
        selectedCategory,
        filteredAssets,
        assetsSkinColors,
        assetsEyeColors,
        bodyColor,
        eyeColor,
        isFaceListDisplayed,
        variableColorsMap,
        categoriesTabs,
        onCategorySelect,
        onAssetSelect,
        onUnAssetSelect,
        onFaceSelect,
        onFaceUnSelect,
        // onFaceEmotionSelect,
        onBodyColorChange,
        onEyeColorChange,
        // onDisplayedAssetsChange,
        onAssetsControlChangeAssetsList,
        onCategoryFaceSelect,
        onBackClick,
        onVariableColorsMapSelectColor,
        onRandomizeClick,
        onNameChange,
        onSaveClick,
    } = useAvatarEditor({
        currentUser,
        updateName,
    });
    const { t: translation } = useTranslation();
    const { showModal } = useModals();

    const t = (id: string) => translation(`avatar-editor.${id}`);

    const tc = (id: string) => translation(`common.${id}`);

    const handleBackClick = () => onBackClick();

    const handleControlClick = () => {
        showModal(
            <AssetsControl
                selectedAssets={displayedAssets}
                assets={assets}
                assetsFaces={assetsFaces}
                assetsCategories={assetsCategories}
                layerPositions={assetsLayerPositions}
                onChangeAssetsList={onAssetsControlChangeAssetsList}
            />,
        );
    };

    // const handleFaceEmotionSelect = (selection: ISelectOption | undefined) => {
    //     if (selection) {
    //         const faceEmotion = displayedFace.emotions.find((item) => item.id === String(selection.value));
    //
    //         onFaceEmotionSelect(faceEmotion as IAvatarAssetFaceEmotion);
    //     }
    // };

    return (
        <>
            {!isLoading && (
                <div className={s.root}>
                    {/* <Avatar*/}
                    {/*    className={s.avatar}*/}
                    {/*    source={currentUser?.avatar}*/}
                    {/*    rounded={true}*/}
                    {/* />*/}

                    <Row alignment={ERowAlignment.TOP}>
                        <Column
                            className={cn(s.column, s.columnPreview)}
                            alignment={EColumnAlignment.CENTER}
                        >
                            <Row className={s.backWrapper}>
                                <TransparentButton
                                    className={cn(s.back, { [s.backVisible]: selectedCategory || isFaceListDisplayed })}
                                    caption={(
                                        <>
                                            <IconArrowLeftLine className={s.backIcon}/>
                                            {tc('back')}
                                        </>
                                    )}
                                    onClick={handleBackClick}
                                />
                            </Row>

                            <Preview
                                className={s.preview}
                                assets={assets}
                                displayedAssets={displayedAssets}
                                assetsCategories={assetsCategories}
                                face={displayedFace}
                                faceEmotion={displayedFaceEmotion}
                                layerPositions={assetsLayerPositions}
                                size={EPreviewSize.L}
                                hiddenLayerPositions={hiddenLayerPositions}
                                isScale={true}
                                skinColor={bodyColor}
                                testEyeColor={eyeColor}
                                variableColorsMap={variableColorsMap}
                            />

                            <div className={s.controls}>
                                <Button
                                    className={s.controlsSave}
                                    caption={tc('save')}
                                    color={EComponentColor.ACCENT}
                                    onClick={onSaveClick}
                                />

                                <div className={s.controlsIcons}>
                                    <div
                                        className={s.controlsIconsItem}
                                        onClick={handleControlClick}
                                    >
                                        <IconBagAlt className={s.controlsIconsItemIcon}/>
                                    </div>

                                    <div
                                        className={s.controlsIconsItem}
                                        onClick={onRandomizeClick}
                                    >
                                        <IconMagic className={s.controlsIconsItemIcon}/>
                                    </div>
                                </div>
                            </div>

                            {currentUser && (
                                <PersonalInfo currentUser={currentUser}/>
                            )}

                            {/* <Select*/}
                            {/*    className={s.faceEmotionSelect}*/}
                            {/*    options={displayedFace?.emotions.map((item)=>({*/}
                            {/*        id: item.id,*/}
                            {/*        value: item.id,*/}
                            {/*        caption: assetsFacesEmotionsCategories.find(*/}
                            {/*            (category) => category.id === item.categoryId*/}
                            {/*        )?.title || '',*/}
                            {/*        selected: item.id === displayedFaceEmotion.id,*/}
                            {/*    }))}*/}
                            {/*    onSelectionChanged={handleFaceEmotionSelect}*/}
                            {/* />*/}

                            {/* <div className={s.combinedSvg} dangerouslySetInnerHTML={{ __html: combinedSvg }}/>*/}
                        </Column>

                        <Column className={s.column}>
                            <div className={s.content}>
                                <PersonalConfig
                                    currentUser={currentUser}
                                    onNameChange={onNameChange}
                                />

                                {selectedCategory
                                    ? (
                                        <>
                                            <Row>
                                                <CategoryTabs
                                                    categories={categoriesTabs}
                                                    selectedCategory={selectedCategory}
                                                    isFaceListDisplayed={isFaceListDisplayed}
                                                    onSelect={onCategorySelect}
                                                    onFaceClick={onCategoryFaceSelect}
                                                />
                                            </Row>

                                            <Row>
                                                <AssetList
                                                    assets={assets}
                                                    filteredAssets={filteredAssets}
                                                    displayedAssets={displayedAssets}
                                                    variableColorsMap={variableColorsMap}
                                                    assetsFaces={assetsFaces}
                                                    assetsSkinColors={assetsSkinColors}
                                                    assetsCategories={assetsCategories}
                                                    layerPositions={assetsLayerPositions}
                                                    onSelect={onAssetSelect}
                                                    onUnSelect={onUnAssetSelect}
                                                    onVariableColorsMapSelectColor={onVariableColorsMapSelectColor}
                                                />
                                            </Row>
                                        </>
                                    )
                                    : (
                                        <>
                                            {isFaceListDisplayed
                                                ? (
                                                    <>
                                                        <Row>
                                                            <CategoryTabs
                                                                categories={categoriesTabs}
                                                                selectedCategory={selectedCategory}
                                                                isFaceListDisplayed={isFaceListDisplayed}
                                                                onSelect={onCategorySelect}
                                                                onFaceClick={onCategoryFaceSelect}
                                                            />
                                                        </Row>

                                                        <Row>
                                                            <FaceList
                                                                displayedFace={displayedFace}
                                                                displayedFaceEmotion={displayedFaceEmotion}
                                                                assetsFaces={assetsFaces}
                                                                assetsSkinColors={assetsSkinColors}
                                                                assets={assets}
                                                                assetsCategories={assetsCategories}
                                                                layerPositions={assetsLayerPositions}
                                                                assetsFacesEmotionsCategories={assetsFacesEmotionsCategories}
                                                                onSelect={onFaceSelect}
                                                                onUnSelect={onFaceUnSelect}
                                                            />
                                                        </Row>
                                                    </>
                                                )
                                                : (
                                                    <CategoryList
                                                        categories={assetsCategories}
                                                        onItemClick={onCategorySelect}
                                                        onFaceClick={onCategoryFaceSelect}
                                                    />
                                                )
                                            }
                                        </>
                                    )}
                            </div>

                            {!selectedCategory && !isFaceListDisplayed && (
                                <>
                                    <ColorSelector
                                        title={t('bodyColor')}
                                        colors={assetsSkinColors}
                                        value={bodyColor}
                                        onSelect={onBodyColorChange}
                                    />

                                    <ColorSelector
                                        title={t('eyeColor')}
                                        colors={assetsEyeColors}
                                        value={eyeColor}
                                        onSelect={onEyeColorChange}
                                    />
                                </>
                            )}
                        </Column>
                    </Row>
                </div>
            )}
        </>
    );
};
