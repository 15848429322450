import React from 'react';
import { Outlet } from 'react-router-dom';

import { useApp } from '@hooks';
import { PersonalAreaPage } from '@hyperclap/views';

interface IPersonalAreaProps {
    noRightSidebar?: boolean;
}

export const PersonalArea = (props: IPersonalAreaProps) => {
    const app = useApp();

    return (
        <PersonalAreaPage {...app} {...props}>
            <Outlet context={app}/>
        </PersonalAreaPage>
    );
};
