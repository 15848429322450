import {
    Button,
    Column,
    Divider,
    EComponentColor,
    EComponentSize,
    ERowAlignment,
    Hint,
    ITextFieldAction, ITextFieldMethods,
    Label,
    Loader,
    Row,
    Switch,
    TextField,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import { IconCopyLine, IconExclamationOctagonLine } from '@assets/images/svg';
import { AUTH_TOKEN_NAME, castToArray, copyToClipboard, OBS_TOKEN_NAME } from '@common';
import { NotificationType } from '@contexts';
import { useAppContext, useNotifications } from '@hooks';


import s from './ObsSettings.scss';

enum ObsLinkFieldActions {
    COPY = 'copy',
}

export const ObsSettings = () => {
    const app = useAppContext();
    const {
        currentUser: {
            currentUser,
            reissueObsToken,
        },
        settings: {
            streamer: {
                obsPage: {
                    sendRandomSticker,
                    toggleObsOverlay,
                    updateChannel,
                },
            },
        },
        translation: {
            t,
        },
    } = app;

    const { notify } = useNotifications();

    const [isObsLinkLoading, setIsObsLinkLoading] = useState(false);

    const [obsLink, setObsLink] = useState<string>();
    const [isObsTestMode, setIsObsTestMode] = useState(false);
    const [isCornersShown, setIsCornersShown] = useState(true);
    const [isGridShown, setIsGridShown] = useState(true);
    const [isStickingZoneShown, setIsStickingZoneShown] = useState(true);
    const [isMasteredObsSettings, setIsMasteredObsSettings] = useState(true);

    const obsLinkMethods = useRef<ITextFieldMethods>(null);

    const buildObsLink = () => {
        const mainToken = localStorage.getItem(AUTH_TOKEN_NAME);
        const obsToken = localStorage.getItem(OBS_TOKEN_NAME);

        return location.origin + '/r/' + currentUser?.id + '?' + OBS_TOKEN_NAME + '=' + (obsToken || mainToken) + '&mode=OBS';
    };

    const onToggleObsTestMode = (value?: boolean) => {
        if (value) {
            setIsObsTestMode(value);
        } else {
            setIsObsTestMode((prev) => !prev);
        }
    };

    const onTestObsLinkClick = () => {
        sendRandomSticker(false);
        setIsMasteredObsSettings(true);
        void updateChannel({ masteredObsSettings: true });
        notify(t('streamer-settings.notification-texts.sticker-sent'), NotificationType.SUCCESS);
    };

    const onTestFullscreenObsLinkCLick = () => {
        sendRandomSticker(true);
        setIsMasteredObsSettings(true);
        void updateChannel({ masteredObsSettings: true });
        notify(t('streamer-settings.notification-texts.sticker-sent'), NotificationType.SUCCESS);
    };

    const updateObsLink = () => {
        const newLink = buildObsLink();
        setObsLink(newLink);
    };

    const onReissueObsLinkClick = async () => {
        setIsObsLinkLoading(true);
        reissueObsToken().then(() => {
            notify(t('streamer-settings.notification-texts.link-refreshed'), NotificationType.SUCCESS);
            setIsObsLinkLoading(false);
            updateObsLink();

            const newLink = buildObsLink();
            obsLinkMethods.current?.set(newLink);
            void copyToClipboard(newLink);
        });
    };

    const onObsLinkFieldAction = (action: ITextFieldAction) => {
        if (action.name === ObsLinkFieldActions.COPY && obsLink) {
            void copyToClipboard(obsLink);
            notify(t('streamer-settings.notification-texts.link-copied'), NotificationType.SUCCESS);
        }
    };

    useEffect(() => {
        if (currentUser) {
            updateObsLink();
            setIsMasteredObsSettings(currentUser.channel.masteredObsSettings ?? false);
        }
    }, [currentUser]);

    useEffect(() => {
        toggleObsOverlay({
            showOverlay: isObsTestMode,
            showCorners: isCornersShown,
            showGrid: isGridShown,
            showStickingZone: isStickingZoneShown,
        });
    }, [isObsTestMode, isGridShown, isCornersShown, isStickingZoneShown]);

    return (
        <Row columnGap={20} margin={{ top: 15, bottom: 50 }} alignment={ERowAlignment.TOP}>
            <Column width={510} rowGap={10}>
                <Label padding={{ bottom: 10 }} caption={t('streamer-settings.obs.obs-settings-section')} size={EComponentSize.LARGE}/>

                <Row>
                    <TextField
                        caption={
                            <div className={cn(s.obsConfigCaptionLink, { [s.red]: !isMasteredObsSettings })}>
                                <div>{t('streamer-settings.obs.obs-browser-source-link')}</div>
                                { !isMasteredObsSettings &&
                                    <div className={s.obsConfigCaptionLinkIcon}><IconExclamationOctagonLine/></div>
                                }
                            </div>
                        }
                        defaultValue={obsLink}
                        readonly
                        actions={[
                            { name: ObsLinkFieldActions.COPY, icon: <IconCopyLine/> },
                        ]}
                        width={410}
                        methodsRef={obsLinkMethods}
                        onAction={onObsLinkFieldAction}
                        onChanged={setObsLink}
                    />
                </Row>

                <Row>
                    <Button
                        disabled={isObsLinkLoading}
                        caption={isObsLinkLoading
                            ? <Loader size={EComponentSize.SMALL}/>
                            : t('common.refresh')
                        }
                        color={EComponentColor.ACCENT}
                        width={160}
                        onClick={onReissueObsLinkClick}
                    />
                </Row>

                <Divider margin={{ top: 20, bottom: 5 }} width={510} />

                <Label padding={{ bottom: 10 }} caption={t('streamer-settings.obs.obs-test-section')} size={EComponentSize.LARGE}/>

                <Row>
                    <Column rowGap={10} className={s.testButtonsColumn}>
                        <Button width={160} caption={t('streamer-settings.obs.test-stickers')} onClick={onTestObsLinkClick}/>
                        <Hint muted grow padding={{ bottom: 15 }} textSize={EComponentSize.SMALL}>
                            {t('streamer-settings.obs.test-stickers-description')}
                        </Hint>
                        <Button width={160} caption={t('streamer-settings.obs.test-fullscreen')} onClick={onTestFullscreenObsLinkCLick}/>
                        <Hint muted grow padding={{ bottom: 10 }} textSize={EComponentSize.SMALL}>
                            {t('streamer-settings.obs.test-fullscreen-description')}
                        </Hint>
                        <Row>
                            <Label width={150} caption={t('streamer-settings.obs.test-size')} size={EComponentSize.MEDIUM} />

                            <Switch
                                checked={isObsTestMode}
                                color={EComponentColor.ACCENT}
                                onChange={(val) => onToggleObsTestMode(val)}
                            />
                        </Row>
                    </Column>
                </Row>

                { isObsTestMode && currentUser?.flags?.isAdmin &&
                    <Row className={s.obsConfigRow}>
                        <Column className={s.obsTestSwitches}>
                            <Row>
                                <Label width={250} caption={t('streamer-settings.obs.show-corners')} size={EComponentSize.MEDIUM} />
                                <Switch
                                    checked={isCornersShown}
                                    color={EComponentColor.ACCENT}
                                    onChange={(val) => setIsCornersShown(val)}
                                />
                            </Row>
                            <Row>
                                <Label width={250} caption={t('streamer-settings.obs.show-grid')} size={EComponentSize.MEDIUM} />
                                <Switch
                                    checked={isGridShown}
                                    color={EComponentColor.ACCENT}
                                    onChange={(val) => setIsGridShown(val)}
                                />
                            </Row>
                            <Row>
                                <Label width={250} caption={t('streamer-settings.obs.show-sticking-area')} size={EComponentSize.MEDIUM} />
                                <Switch
                                    checked={isStickingZoneShown}
                                    color={EComponentColor.ACCENT}
                                    onChange={(val) => setIsStickingZoneShown(val)}
                                />
                            </Row>
                        </Column>
                    </Row>
                }

            </Column>
            <Column grow rowGap={20} padding={{ top: 10 }}>
                {!isMasteredObsSettings &&
                    <Hint grow muted padding={17} textSize={EComponentSize.SMALL} className={s.coloredHintRed}>
                        <div>
                            {castToArray<string>(t('streamer-settings.obs.hints.add-browser-source', { returnObjects: true }))
                                .map((element, idx) => (
                                    <div key={idx}>
                                        <Trans i18nKey={element} components={[<a key={idx}/>]}>
                                            {element}
                                        </Trans>
                                    </div>
                                ))
                            }
                        </div>
                    </Hint>
                }
                <Hint grow muted padding={{ left: 0 }} textSize={EComponentSize.SMALL}>
                    <div>
                        {castToArray<string>(t('streamer-settings.obs.hints.setup-obs', { returnObjects: true }))
                            .map((element, idx) => (
                                <div key={idx}>
                                    <Trans i18nKey={element} components={[<a key={idx}/>]}>
                                        {element}
                                    </Trans>
                                </div>
                            ))
                        }
                    </div>
                </Hint>
            </Column>
        </Row>
    );
};
