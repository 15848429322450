import { ERelativePosition } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useState } from 'react';

import s from './MenuItem.scss';


export interface MenuItemProps<T = unknown> {
    active?: boolean,
    activeBorderPosition?: ERelativePosition;
    caption?: string;
    captionClassName?: string;
    containerClassName?: string;
    className?: string;
    divider?: boolean;
    dividerClassName?: string;
    icon?: JSX.Element;
    iconClassName?: string;
    iconPosition?: ERelativePosition,
    submenu?: JSX.Element,
    submenuClassName?: string,
    submenuOpened?: boolean;
    submenuSwitchable?: boolean;
    tag?: T;
    useActiveBorder?: boolean;
    visible?: boolean;

    onClick?: (tag?: T) => void;
}

export const MenuItem = <T, >(props: MenuItemProps<T>) => {
    const {
        active,
        activeBorderPosition = ERelativePosition.BOTTOM,
        caption,
        captionClassName,
        containerClassName,
        className,
        divider,
        dividerClassName,
        icon,
        iconClassName,
        iconPosition = ERelativePosition.LEFT,
        submenu,
        submenuClassName,
        submenuOpened = false,
        submenuSwitchable = false,
        tag,
        useActiveBorder = false,
        visible = true,

        onClick = noop,
    } = props;

    const [isSubmenuOpened, setIsSubmenuOpened] = useState(submenuOpened);

    const menuItemContainerClasses = cn(
        s.menuItemContainer,
        containerClassName,
    );

    const menuItemSubmenuClasses = cn(
        s.menuItemSubmenu,
        submenuClassName,
    );

    const menuItemClasses = cn(
        s.menuItem,
        s[`menuItemIcon${iconPosition}`],
        {
            [s.menuItemActive]: active,
            [s[`menuItemActiveBorder${activeBorderPosition}`]]: active && useActiveBorder,
        },
        className,
    );

    const menuItemIconClasses = cn(
        s.menuItemIconContainer,
        iconClassName,
    );

    const menuItemCaptionClasses = cn(
        s.menuItemCaptionContainer,
        captionClassName,
    );

    const dividerClasses = cn(
        s.divider,
        dividerClassName,
    );

    const onMenuItemClick = (tag?: T) => {
        if (!!submenu && submenuSwitchable) {
            setIsSubmenuOpened((prev) => !prev);
        }

        if (tag !== undefined) {
            onClick(tag);
        }
    };

    return divider
        ? <div className={dividerClasses}/>
        : visible
            ? (
                <div className={menuItemContainerClasses}>
                    <div className={menuItemClasses} onClick={() => onMenuItemClick(tag)}>
                        { !!icon &&
                            <div className={menuItemIconClasses}>{icon}</div>
                        }
                        { !!caption &&
                            <div className={menuItemCaptionClasses}>{caption}</div>
                        }
                    </div>
                    {!!submenu && isSubmenuOpened &&
                        (
                            <div className={menuItemSubmenuClasses}>
                                {submenu}
                            </div>
                        )
                    }
                </div>
            )
            : null;
};
