import { LoggerColor, useLogger } from '@hyperclap/ui';
// eslint-disable-next-line import/no-unresolved
import config from 'config';
import { useEffect, useRef, useState } from 'react';

// import { VERSION_CHECK_INTERVAL } from '@const';
import { AppVersion } from '@app';
import { VERSION_CHECK_INTERVAL } from '@common';
import { useApi, useVersionApi, useWebSocket } from '@hooks';
import { EPopupTypes, usePlayingStickersList, usePopupStickersList } from '@hooks/app';
import {
    EventKind,
    IBuyCurrencyEvent,
    IGeometry,
    IMemeCannonStickerSentEvent,
    IMemePartyState,
    ISticker,
    IStickerSentEvent,
    IWelcomeBonusEarnedEvent,
    ObsCommand,
    ObsWidgetType,
    StickerSize,
    TWSMessage,
    WSMessageType,
} from '@typings';


// import { AppVersion } from 'src/App';

export interface PostedSticker {
    uid: string;
    sticker: ISticker;
    geometry: IGeometry;
    size: string;
}

let memePartyResetTimeout: NodeJS.Timeout;
let memePartyTicker: NodeJS.Timeout;

export const useObsStickers = () => {
    const logger = useLogger({ target: useObsStickers.name, showTimestamp: true });

    const {
        memeParty: {
            useGetMemePartyStateQuery,
            useResetMemePartySessionMutation,
        },
        users: {
            useCurrentUserQuery,
        },
    } = useApi();

    const {
        useLazyGetVersionQuery,
    } = useVersionApi();

    const [unauthorized, setUnauthorized] = useState(false);
    const [stickerSize, setStickerSize] = useState<string>();
    const [stickerStickAreasMask, setStickerStickAreasMask] = useState<number>();
    const [isOverlayShown, setIsOverlayShown] = useState(false);
    const [isCornersShown, setIsCornersShown] = useState(true);
    const [isGridShown, setIsGridShown] = useState(true);
    const [isStickingZoneShown, setIsStickingZoneShown] = useState(true);
    const [isWidgetsShown] = useState(true);
    const [memePartyState, setMemePartyState] = useState<IMemePartyState>();
    const [memePartyIsActive, setMemePartyIsActive] = useState(false);
    const partyTime = useRef(75);

    const [getCurrentVersion] = useLazyGetVersionQuery();

    const {
        data: currentUser,
        isLoading,
        isSuccess,
        isError,
        refetch: refetchCurrentUser,
    } = useCurrentUserQuery();

    const {
        data: loadedMemePartyState,
        isSuccess: memePartyStateIsLoaded,
    } = useGetMemePartyStateQuery({});

    const [
        resetMemePartySession,
    ] = useResetMemePartySessionMutation();

    const {
        list,
        addToList,
        removeFromList,
    } = usePlayingStickersList({
        stickerSize: config?.obs.stickerSize[stickerSize ?? StickerSize.MEDIUM],
        availableAreaMask: stickerStickAreasMask,
    });

    const {
        list: memeCannonList,
        addToList: addToMemeCannonList,
        removeFromList: removeFromMemeCannonList,
    } = usePlayingStickersList({
        stickerSize: config?.obs.stickerSize[stickerSize ?? StickerSize.MEDIUM],
        availableAreaMask: 511,
    });

    const {
        list: fullscreenList,
        addToList: addToFullscreenList,
        removeFromList: removeFromFullscreenList,
    } = usePlayingStickersList({
        stickerSize: config?.obs.stickerSize[stickerSize ?? StickerSize.MEDIUM],
        availableAreaMask: stickerStickAreasMask,
        fullscreen: true,
    });

    const {
        popupInfo,
        addToList: addToPopupStickersList,
    } = usePopupStickersList();

    const {
        subscribe,
        unsubscribe,
    } = useWebSocket({
        clientId: 'ma-obs-stickers-hook',
    });

    const addToListByTimer = (sticker: ISticker, count: number, hidden: boolean = false) => {
        let delay;

        switch (count) {
            case 5:
                delay = 600;
                break;
            case 10:
                delay = 500;
                break;
            case 15:
                delay = 400;
                break;
            case 25:
                delay = 300;
                break;
            case 50:
                delay = 200;
                break;
            case 100:
                delay = 200;
                break;
            default:
                delay = 300;
                break;
        }

        const tail = delay / 2;

        const int = setInterval(() => {
            addToMemeCannonList(sticker, hidden);
        }, delay);

        setTimeout(() => {
            clearInterval(int);
        }, delay * count + tail);
    };

    const onMessageHandler = (message: TWSMessage) => {
        const { type, data } = message;

        if (type === WSMessageType.STICKER_RECEIVED) {
            const sticker = { ...data.sticker };
            const isSoundOnly = data.isSoundOnly;
            addToList(sticker, isSoundOnly);
        }

        if (type === WSMessageType.FULLSCREEN_STICKER_RECEIVED) {
            const sticker = { ...data.sticker };
            const isSoundOnly = data.isSoundOnly;
            addToFullscreenList(sticker, isSoundOnly);
        }

        if (type === WSMessageType.MEME_CANNON_STICKER_RECEIVED) {
            const sticker = { ...data.sticker };
            const count = data.count;
            const isSoundOnly = data.isSoundOnly;
            addToListByTimer(sticker, count, isSoundOnly);
        }

        if (type === WSMessageType.EVENT_CREATED && data.event.kind === EventKind.BUY_CURRENCY) {
            const event = data.event as IBuyCurrencyEvent;

            if (!event.preventStreamerNotification) {
                if (event.firstPayment) {
                    addToPopupStickersList({
                        uid: '',
                        type: EPopupTypes.NEW_SUPPORTER,
                        userName: event.userName,
                        userAvatar: event.userAvatar,
                    });
                } else {
                    addToPopupStickersList({
                        uid: '', type: EPopupTypes.NEW_SALE,
                        packDescription: event.packDescription,
                        userName: event.userName,
                        userAvatar: event.userAvatar,
                    });
                }
            }
        }

        if (type === WSMessageType.EVENT_CREATED && data.event.kind === EventKind.WELCOME_BONUS_EARNED) {
            const event = data.event as IWelcomeBonusEarnedEvent;
            addToPopupStickersList({
                uid: '',
                type: EPopupTypes.WELCOME_BONUS_EARNED,
                bonusValue: event.bonusValue,
                userName: event.userName,
                userAvatar: event.userAvatar,
            });
        }

        if (type === WSMessageType.EVENT_CREATED && data.event.kind === EventKind.STICKER_SENT) {
            const event = data.event as IStickerSentEvent;

            if (event.paidPrice > 0) {
                addToPopupStickersList({
                    uid: '',
                    type: EPopupTypes.NICK_NAME,
                    stickerName: event.stickerName,
                    userAlias: event.userAlias,
                    userAvatar: event.userAvatar,
                    fullscreen: event.fullscreen,
                    message: event.message,
                });
            }
        }

        if (type === WSMessageType.EVENT_CREATED && data.event.kind === EventKind.MEME_CANNON_STICKER_SENT) {
            const event = data.event as IMemeCannonStickerSentEvent;
            addToPopupStickersList({
                uid: '',
                type: EPopupTypes.MEME_CANNON_STICKER_SENT,
                stickerName: event.stickerName,
                userAlias: event.userAlias,
                userAvatar: event.userAvatar,
                count: event.count,
                message: event.message,
            });
        }

        if (type === WSMessageType.OBS_COMMAND) {
            switch (data.command) {
                case ObsCommand.TOGGLE_SETTINGS_OVERLAY:
                    setIsOverlayShown(data.parameters?.showOverlay as boolean);
                    setIsCornersShown(data.parameters?.showCorners as boolean);
                    setIsGridShown(data.parameters?.showGrid as boolean);
                    setIsStickingZoneShown(data.parameters?.showStickingZone as boolean);
                    break;
                case ObsCommand.REFETCH_SETTINGS:
                    refetchCurrentUser();
                    break;
            }
        }

        if (type === WSMessageType.WIDGET_STATE && data.state.widgetType === ObsWidgetType.MEME_PARTY) {
            const widgetData = data.state.widgetData as IMemePartyState;
            setMemePartyState({ ...widgetData });
        }
    };

    const removePostedSticker = (uid: string) => {
        removeFromList(uid);
    };

    const checkCurrentVersion = async () => {
        const versionData = await getCurrentVersion().unwrap();

        if (versionData.obsVersion !== AppVersion.obsVersion) {
            logger.debug(`Different versions: ${AppVersion.obsVersion} != ${versionData.obsVersion}`);
            logger.debug(`Page reload...`);
            let url = window.location.href;
            const urlParams = new URLSearchParams(window.location.search);
            const retryParam = urlParams.get('retry');

            if (retryParam) {
                const retryCount = parseInt(retryParam);

                if (retryCount < 3) {
                    logger.debug(`Try ${retryCount + 1}...`);
                    url = url.replace(`retry=${retryCount}`, `retry=${retryCount + 1}`);
                    window.location.href = url;
                } else {
                    logger.debug(`Try ${retryCount}. Limit reached. Stop!`);

                    return;
                }
            } else {
                if (url.indexOf('?') > -1) {
                    url += '&retry=1';
                } else {
                    url += '?retry=1';
                }

                logger.debug(`Try 1...`);
                window.location.href = url;
            }
        }

        scheduleVersionCheck();
    };

    const scheduleVersionCheck = async () => {
        setTimeout(async () => {
            checkCurrentVersion();
        }, VERSION_CHECK_INTERVAL);
    };

    useEffect(() => {
        if ((!isLoading && isSuccess && !currentUser) || isError) {
            setUnauthorized(true);
        }
    }, [currentUser, isLoading, isError, isSuccess]);

    useEffect(() => {
        if (currentUser) {
            setStickerSize(currentUser.channel.stickerSize ?? StickerSize.MEDIUM);
            setStickerStickAreasMask(memePartyState?.active ? 511 : (currentUser.channel?.stickerStickAreasMask ?? 511));
        }

        if (currentUser && stickerSize && stickerStickAreasMask) {
            subscribe(currentUser.id, onMessageHandler);
        }

        return () => {
            if (currentUser) {
                unsubscribe(currentUser.id, onMessageHandler);
            }
        };
    }, [currentUser, stickerSize, stickerStickAreasMask]);

    useEffect(() => {
        if (memePartyStateIsLoaded && loadedMemePartyState) {
            setMemePartyState({ ...loadedMemePartyState });
        }
    }, [loadedMemePartyState, memePartyStateIsLoaded]);

    useEffect(() => {
        if (memePartyState) {
            if (memePartyState.active && !memePartyIsActive) {
                setMemePartyIsActive(true);
                setStickerStickAreasMask(511);
                logger.trace(`Set area mask to 511, allow all areas to stick during meme party`, LoggerColor.PURPLE);
                const partyDuration = Math.ceil((memePartyState.willEndAt! - memePartyState.startedAt!) / 1000);
                logger.trace(`Party duration: ${partyDuration}`, LoggerColor.PURPLE);

                if (partyDuration > 0) {
                    partyTime.current = partyDuration;
                    memePartyTicker = setInterval(() => {
                        partyTime.current = partyTime.current - 1;
                        logger.trace(`Party is going on: ${partyTime.current}`, LoggerColor.PURPLE);

                        if (partyTime.current <= 0) {
                            clearInterval(memePartyTicker);
                            setMemePartyIsActive(false);
                        }
                    }, 1000);

                    logger.trace(`Timer set: ${memePartyTicker}`, LoggerColor.PURPLE);

                    memePartyResetTimeout = setTimeout(() => {
                        resetMemePartySession();
                        clearInterval(memePartyTicker);
                        setMemePartyIsActive(false);
                        logger.trace(`Timer unset: ${memePartyTicker}`, LoggerColor.PURPLE);
                        partyTime.current = 75;
                    }, partyDuration * 1000);
                } else {
                    logger.trace(`Party duration is: ${partyDuration}, reset meme party`, LoggerColor.PURPLE);
                    resetMemePartySession();
                }
            } else {
                setStickerStickAreasMask(currentUser?.channel?.stickerStickAreasMask ?? 511);
            }
        }
    }, [memePartyState]);

    useEffect(() => {
        scheduleVersionCheck();
    }, []);

    useEffect(() => {
        logger.trace(`OBS hook mounted`, LoggerColor.PURPLE);

        return () => {
            clearTimeout(memePartyResetTimeout);
            clearInterval(memePartyTicker);
        };
    }, []);

    return {
        currentUser,
        unauthorized,
        list,
        fullscreenList,
        memeCannonList,
        size: stickerSize,
        isOverlayShown,
        isCornersShown,
        isGridShown,
        isStickingZoneShown,
        isWidgetsShown,
        memePartyState,
        popupInfo,
        removePostedSticker,
        removeFromFullscreenList,
        removeFromMemeCannonList,
        resetMemePartySession,
    };
};
