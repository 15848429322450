import { EComponentSize, Label } from '@hyperclap/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useApp } from '@hooks';

import { AvatarEditor } from './components';

export const PersonalSettings = () => {
    const app = useApp();
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`PersonalSettings.${id}`);

    return (
        <>
            <Label padding={{ bottom: 20 }} caption={t('title')} size={EComponentSize.LARGE}/>

            <AvatarEditor {...app}/>
        </>
    );
};
