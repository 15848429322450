import { EComponentSize } from '@hyperclap/ui';
import React, { useEffect, useRef, useState } from 'react';

import { Avatar } from '@components';
import { EPopupTypes, IPopupInfo, IPopupNewSupporter } from '@hooks';
import { IUser } from '@typings';

import s from './PopupNewSupporter.scss';


interface PopupNewSupporterProps {
    currentUser?: IUser;
    popupInfo?: IPopupInfo;
}

export const PopupNewSupporter = (props: PopupNewSupporterProps) => {
    const {
        currentUser,
        popupInfo,
    } = props;

    const info = popupInfo as IPopupNewSupporter;

    const audioRef = useRef<HTMLAudioElement>(null);

    const [soundPlayed, setSoundPlayed] = useState(false);

    useEffect(() => {
        if (currentUser && popupInfo && popupInfo.type === EPopupTypes.NEW_SUPPORTER && audioRef.current && !soundPlayed) {
            setSoundPlayed(true);
            audioRef.current.volume = currentUser?.channel?.soundVolume ? currentUser?.channel?.soundVolume / 100 : 0.6;
            audioRef.current.play();
        }

        if ((!currentUser || !popupInfo) && audioRef.current && soundPlayed) {
            setSoundPlayed(false);
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    }, [popupInfo, currentUser]);

    return (
        <div className={s.popupNicknameWidget}>
            <audio src='/assets/sounds/magic-coins.mp3' ref={audioRef} />
            {popupInfo && popupInfo.type === EPopupTypes.NEW_SUPPORTER &&
                <div className={s.container}>
                    <div className={s.left}>
                        <div className={s.picture}>
                            <Avatar
                                source={info?.userAvatar}
                                rounded
                                isUserAvatar
                                size={EComponentSize.EXTRA_LARGE}
                            />
                        </div>
                    </div>
                    <div className={s.right}>
                        <div className={s.description}>
                            <div className={s.descriptionAction}>
                                {`НОВЫЙ ${currentUser?.channel?.supporterName?.toUpperCase() || 'САППОРТЕР'}`}
                            </div>
                        </div>
                        <div className={s.amount}>
                            { info?.userName || 'АНОНИМ' }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
