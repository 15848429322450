import {
    IInviteActivationRequest,
    IInviteActivationResponse,
    IInviteActivationStatusResponse,
    TInvitesList,
} from '@typings';

import { baseApi } from './base.api';


/** Invites API provides methods are related to invites management */
export const invitesApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Invites_ActivationStatus',
            'Invites_List',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            /** Send a request to activate an invitation */
            activateInvite: build.mutation<IInviteActivationResponse, IInviteActivationRequest>({
                query: (activationRequest: IInviteActivationRequest) => ({
                    url: 'invite/activate',
                    method: 'POST',
                    body: activationRequest,
                }),
                invalidatesTags: ['Invites_ActivationStatus'],
            }),

            /** Fetches the invitation activation status for the current user */
            inviteActivationStatus: build.query<IInviteActivationStatusResponse, void>({
                query: () => ({
                    url: 'invite/status',
                    method: 'GET',
                }),
                providesTags: ['Invites_ActivationStatus'],
            }),

            /** Fetches a list of invites for the current user */
            invitesList: build.query<TInvitesList, void>({
                query: () => ({
                    method: 'GET',
                    url: 'invite',
                }),
                providesTags: ['Invites_List'],
            }),
        }),
    });
