import cn from 'classnames';
import * as React from 'react';


import s from './AssetListItem.scss';
import { useAssetListItem } from './hooks';
import { IAssetListItemProps } from './types';
import { EPreviewSize, Preview } from '../Preview';


export const AssetListItem = (props: IAssetListItemProps) => {
    const {
        className,
        asset,
        assets,
        assetsCategories,
        layerPositions,
        face,
        faceEmotion,
        isSelected,
        assetsFaces,
        assetsSkinColors,
        onClick,
    } = props;
    const {
        defaultFace,
        defaultFaceEmotion,
        skinColor,
    } = useAssetListItem({
        assetsFaces,
        assetsSkinColors,
    });

    return (
        <div
            className={cn(
                s.root,
                { [s.selected]: isSelected },
                className,
            )}
            onClick={onClick}
        >
            <Preview
                className={s.preview}
                displayedAssets={asset ? [asset] : []}
                assets={assets}
                assetsCategories={assetsCategories}
                layerPositions={layerPositions}
                face={face || defaultFace}
                faceEmotion={faceEmotion || defaultFaceEmotion}
                size={EPreviewSize.M}
                hiddenLayerPositions={asset?.hiddenLayerPositions}
                skinColor={skinColor?.color}
                isScale={true}
            />
        </div>
    );
};
