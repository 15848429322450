import cn from 'classnames';
import React from 'react';

import { IconCloseLine } from '@assets/images/svg';
import { NotificationData, NotificationType } from '@contexts';

import s from './Notification.scss';


interface NotificationProps extends NotificationData {
    showClose?: boolean;
    onClose?: () => void;
}

export const Notification = (props: NotificationProps) => {
    const {
        showClose,
        text,
        type = NotificationType.INFO,
        onClose,
    } = props;

    return (
        <div className={cn(s.notification, s[type])}>
            { !!showClose &&
                <div className={s.notificationCloseIcon} onClick={onClose}>
                    <IconCloseLine />
                </div>
            }
            <div className={s.notificationText}>
                {text}
            </div>
        </div>
    );
};
