import { IAuthObsTokenResponse } from '@typings';

import { baseApi } from './base.api';

export const authApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Users_CurrentUser',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            logout: build.mutation<void, void>({
                query: () => ({
                    url: 'auth/logout',
                    method: 'POST',
                }),
                invalidatesTags: ['Users_CurrentUser'],
            }),
            fetchObsToken: build.query<IAuthObsTokenResponse, void>({
                query: () => ({
                    url: 'auth/obs',
                    method: 'POST',
                }),
            }),
        }),
    });
