import {
    Column,
    Row,
    applyMargin,
    applyPadding,
    EComponentSize,
    EColumnAlignment,
    IBlockComponentProps,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { CSSProperties, ReactElement } from 'react';

import { IconHuskySolid } from '@assets/images/svg';

import s from './Empty.scss';


interface EmptyProps extends IBlockComponentProps {
    content?: React.ReactElement;
    icon?: React.ReactElement;
    iconSize?: EComponentSize | number;
    text: string | ReactElement;
}

export const Empty = (props: EmptyProps) => {
    const {
        className,
        content,
        icon = <IconHuskySolid/>,
        iconSize,
        height,
        margin,
        padding,
        style,
        text,
        width,
    } = props;

    const emptyStyles: CSSProperties = {
        ...style,
        height,
        width,
    };

    const iconStyles: CSSProperties = {};
    const iconClasses = [];
    const iconSizeValue = iconSize ?? EComponentSize.MEDIUM;

    if (typeof iconSizeValue === 'number') {
        iconStyles.width = iconSize;
        iconStyles.height = iconSize;
        iconStyles.minWidth = iconSize;
        iconStyles.minHeight = iconSize;
    }

    if (typeof iconSizeValue === 'string') {
        iconClasses.push(s['size' + iconSize]);
    }

    applyPadding(emptyStyles, padding);
    applyMargin(emptyStyles, margin);

    return (
        <Column grow alignment={EColumnAlignment.CENTER} className={cn(s.empty, className)} style={emptyStyles}>
            <Row className={cn(s.emptyIcon, iconClasses)} style={iconStyles}>{icon}</Row>
            <Row className={s.emptyText}>{text}</Row>
            {!!content &&
                <Row>
                    {content}
                </Row>
            }
        </Column>
    );
};
