import { Button, EComponentColor, ETheme, ThemeContext, useModals } from '@hyperclap/ui';
import cn from 'classnames';
import React, { useContext, useLayoutEffect } from 'react';

import { TApp } from '@hooks';
import { Footer, Logo, LogoKind } from '@hyperclap/views';

import s from './Mobile.scss';
import { JoinPopup } from '../../components/JoinPopup';


interface IMobileProps extends TApp {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Mobile = (props: IMobileProps) => {
    const { changeTheme } = useContext(ThemeContext);
    const { showModal } = useModals();

    useLayoutEffect(() => {
        changeTheme(ETheme.HYPERCLAP, true);

        document.body.classList.add(s.body);

        return () => {
            document.body.classList.remove(s.body);
        };
    }, []);

    const handleJoinClick = () => {
        showModal({
            content: <JoinPopup {...props}/>,
            options: {
                bodyClassName: s.joinPopup,
            },
        });
    };

    return (
        <div className={s.root}>
            <div className={s.top}>
                <Logo kind={LogoKind.DARK}/>
            </div>

            <div className={s.bring}>
                <div className={s.bringBg}/>
                <div className={s.bringPolygon}/>

                <div className={s.bringContent}>
                    <div className={s.bringContentText}>
                        Let your community create and send video stickers to your stream. Goodbye boredom,
                        Hello laughs and epic moments!
                    </div>

                    <Button
                        caption="Join"
                        color={EComponentColor.ACCENT}
                        className={s.bringContentButton}
                        onClick={handleJoinClick}
                    />
                </div>
            </div>

            <div className={s.launch}>
                <div className={s.launchBg}/>
                <div className={s.launchPicture}/>

                <div className={s.launchContent}>
                    <div className={s.launchContentTitle}>Launch YOUR Meme Party!</div>
                    <div className={s.launchContentText}>
                        Hyperclap not only offers thousands of
                        memes but also lets viewers create new
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        ones from your stream's fun moments.
                        Your stream will be loaded with local
                        jokes, so your viewers will be cracking up.
                    </div>
                </div>
            </div>

            <div className={s.moderation}>
                <div className={cn(s.moderationPicture, s.moderationPictureTop)}/>
                <div className={s.moderationContent}>
                    <div className={s.moderationContentTitle}>STRICT MODERATION</div>
                    <div className={s.moderationContentText}>
                        No worries! Every sticker is checked to
                        follow Twitch’s rules, and you control
                        what’s allowed and set the humor tone
                        for your community.
                    </div>
                </div>
                <div className={cn(s.moderationPicture, s.moderationPictureBottom)}/>
            </div>

            <div className={s.reinvented}>
                <div className={s.reinventedBg}/>
                <div className={s.reinventedPolygon}>
                    <div className={s.reinventedContent}>
                        <div className={s.reinventedContentTitle}>Reinvented donations</div>
                        <div className={s.reinventedContentText}>
                            Viewers can create a <span className={s.reinventedContentTextStreamoji}>Streamoji</span>, their own
                            virtual avatar that pops up when they
                            donate, complete with a unique voice.
                            They can also choose emotions and
                            special effects to make it even more epic.
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.money}>
                <div className={s.moneyBgTop}/>
                <div className={s.moneyBgBottom}/>
                <div className={s.moneyPicture}>
                    <div className={s.moneyContent}>
                        <div className={s.moneyContentTitle}>Memes that make money</div>
                        <div className={s.moneyContentText}>
                            Viewers buy your virtual currency to send
                            stickers and donations. Reward them for
                            watching or joining contests, and gift
                            them branded items for their Streamoji
                            avatars.
                        </div>
                    </div>

                    <div className={s.moneyCoin2}/>
                    <div className={s.moneyCards}/>
                    <div className={s.moneyCoin1}/>
                </div>
            </div>

            <div className={s.join}>
                <div className={s.joinTitle}>Hyperclap setup is quicker than reading this PAGE.</div>

                <Button
                    className={s.joinButton}
                    caption="Join now"
                    color={EComponentColor.ACCENT}
                    onClick={handleJoinClick}
                />

                <div className={s.joinPicture}/>
            </div>

            <Footer
                isLogoGrayscale={false}
                isSupportEnabled={false}
                isCopyRightEnabled={true}
                logoKind={LogoKind.SHORT}
                {...props}
            />
        </div>
    );
};
