import { IAvatarAssetColorsMapVariableItem } from './IAvatarAssetColorsMapVariableItem';
import { IAvatarAssetImage } from './IAvatarAssetImage';
import { IAvatarAssetImageSize } from './IAvatarAssetImageSize';

export interface IAvatarAssetColorsMapVariableItemWithTestColors {
    item: IAvatarAssetColorsMapVariableItem;
    testColor?: string;
    testImage?: IAvatarAssetImage;
    testImageSize?: IAvatarAssetImageSize;
}
