import {
    Row,
    Column,
    ERowAlignment,
    IComponentProps,
} from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import { BackgroundMode } from '@typings';

import s from './MobilePage.scss';

interface IMobilePageProps extends IComponentProps {
    /** Additional style classes to apply to the root element of the component */
    className?: string;

    /** Optional footer element */
    footer?: React.ReactElement;

    /** Optional header element */
    header?: React.ReactElement;

    /** Page background picture */
    pageBackgroundUrl?: string;

    /** Page background picture mode (0 - cover, 1 - tile) */
    pageBackgroundMode?: BackgroundMode;

    /** Prevent scroll content under popups */
    preventScroll?: boolean;
}

export const MobilePage = (props: React.PropsWithChildren<IMobilePageProps>) => {
    const {
        children,
        className,
        footer,
        header,
        pageBackgroundMode = BackgroundMode.COVER,
        pageBackgroundUrl,
        preventScroll,
        style,
    } = props;

    const pageStyles = pageBackgroundUrl
        ? {
            ...style,
            backgroundImage: `url(${pageBackgroundUrl})`,
            backgroundPosition: pageBackgroundMode ? '0 0' : 'center',
            backgroundSize: pageBackgroundMode ? 'center center' : 'cover',
        }
        : {
            ...style,
        };

    return (
        <Column grow className={cn(s.mobilePage, className)} style={pageStyles}>
            <Row>
                {header}
            </Row>

            <Row grow alignment={ERowAlignment.STRETCH} className={cn({ [s.scrollBlocker]: preventScroll })}>
                {children}
            </Row>

            <Row>
                {footer}
            </Row>
        </Column>
    );
};
