import { ISourceOptions } from '@tsparticles/engine';

export const confettiConfig: ISourceOptions = {
    fullScreen: {
        zIndex: 1,
    },
    emitters: [
        {
            position: {
                x: 25,
                y: 100,
            },
            rate: {
                quantity: 50,
                delay: 0.5,
            },
            life: {
                count: 1,
                duration: 10,
            },
        },
        {
            position: {
                x: 75,
                y: 100,
            },
            rate: {
                quantity: 50,
                delay: 0.5,
            },
            life: {
                count: 1,
                duration: 10,
            },
        },
    ],
    particles: {
        color: {
            value: ['#F99B01'],
        },
        move: {
            decay: 0.05,
            direction: 'top',
            enable: true,
            gravity: {
                enable: true,
                acceleration: 3,
            },
            outModes: {
                top: 'none',
                default: 'destroy',
            },
            speed: {
                min: 50,
                max: 100,
            },
        },
        number: {
            value: 0,
        },
        opacity: {
            value: 1,
        },
        rotate: {
            value: {
                min: 0,
                max: 360,
            },
            direction: 'random',
            animation: {
                enable: true,
                speed: 30,
            },
        },
        tilt: {
            direction: 'random',
            enable: true,
            value: {
                min: 0,
                max: 360,
            },
            animation: {
                enable: true,
                speed: 30,
            },
        },
        size: {
            value: 5,
            animation: {
                enable: true,
                startValue: 'min',
                count: 1,
                speed: 16,
                sync: true,
            },
        },
        roll: {
            darken: {
                enable: true,
                value: 5,
            },
            enlighten: {
                enable: true,
                value: 25,
            },
            enable: true,
            speed: {
                min: 5,
                max: 15,
            },
        },
        wobble: {
            distance: 30,
            enable: true,
            speed: {
                min: -7,
                max: 7,
            },
        },
        shape: {
            type: ['circle'],
            options: {

            },
        },
    },
    responsive: [
        {
            maxWidth: 1024,
            options: {
                particles: {
                    move: {
                        speed: {
                            min: 33,
                            max: 66,
                        },
                    },
                },
            },
        },
    ],
};
