import { useLogger } from '@hyperclap/ui';
import { useEffect, useState } from 'react';

import { useApi } from '@hooks';
import { IHookBaseParams, ISticker, IThematicSet, IUser } from '@typings';

const PAGE_SIZE = 20;

interface IThematicSetParams extends IHookBaseParams {
    streamer?: IUser;
    thematicSet?: IThematicSet;
}

export const useThematicSet = (params: IThematicSetParams) => {
    const logger = useLogger({ target: useThematicSet.name });

    const {
        active = false,
        streamer,
        thematicSet,
    } = params;

    const {
        stickers: {
            useLazyLoadPageStreamerAreaThematicSetQuery,

            useUpdateStickerCustomSettingsMutation,
            useAddStickerToFavoritesMutation,
            useRemoveStickerFromFavoritesMutation,
            useAddStickerToChannelMutation,
            useRemoveStickerFromChannelMutation,
        },
    } = useApi();

    const [loadPage, { isFetching }] = useLazyLoadPageStreamerAreaThematicSetQuery();
    const [updateCustomSettings] = useUpdateStickerCustomSettingsMutation();
    const [addToFavorites] = useAddStickerToFavoritesMutation();
    const [removeFromFavorites] = useRemoveStickerFromFavoritesMutation();
    const [addToChannel] = useAddStickerToChannelMutation();
    const [removeFromChannel] = useRemoveStickerFromChannelMutation();

    const [stickers, setStickers] = useState<Array<ISticker>>([]);
    const [isEnd, setIsEnd] = useState(false);

    const updateStickerCustomSettings = (sticker: ISticker) => {
        logger.info(sticker);
        setStickers(stickers.map((s) => sticker.id === s.id ? { ...sticker } : s));

        if (sticker.customSettings) {
            updateCustomSettings({
                stickerId: sticker.id,
                stickerCustomSettings: sticker.customSettings,
            });
        }
    };

    const updateStickerFavoriteState = async (sticker: ISticker) => {
        const method = sticker.isFavorite ? addToFavorites : removeFromFavorites;
        const result = await method(sticker.id).unwrap();
        setStickers(stickers.map((s) => result.id === s.id ? { ...result } : s));

        return result;
    };

    const updateStickerInChannelState = async (sticker: ISticker) => {
        const method = sticker.isAddedToChannel ? addToChannel : removeFromChannel;
        const result = await method(sticker.id).unwrap();
        setStickers(stickers.map((s) => result.id === s.id ? { ...result } : s));

        return result;
    };

    const loadNextPage = async () => {
        if (active && streamer && !isEnd && !isFetching && thematicSet) {
            const params = {
                thematicSetId: thematicSet?.id,
                limit: PAGE_SIZE,
                skip: stickers.length,
                streamerId: streamer.id,
            };

            const page = await loadPage(params, false).unwrap();
            setStickers(stickers.concat(page));

            if (page.length < PAGE_SIZE) {
                setIsEnd(true);
            }
        }
    };

    useEffect(() => {
        setStickers([]);
        setIsEnd(false);
    }, [thematicSet?.id]);

    useEffect(() => {
        if (active && !stickers.length) {
            void loadNextPage();
        }
    }, [stickers, active]);

    return {
        stickers,
        thematicSet,
        isEnd,
        isFetching,
        isLoadingAllowed: !isEnd && !isFetching,
        loadNextPage,
        updateStickerCustomSettings,
        updateStickerFavoriteState,
        updateStickerInChannelState,
    };
};
