import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { mapDataToEvent } from '@common';
import { useApi, useCurrentUser, useWebSocket } from '@hooks';
import { IEventData, IHookBaseParams, Period, TWSMessage, WSMessageType } from '@typings';


const PAGE_SIZE = 20;

export enum EventsFilterPreset {
    PURCHASES,
    BONUSES,
    STICKERS,
    MEME_CANNON,
    FULLSCREEN,
    MODERATION,
}

interface IEventsParams extends IHookBaseParams {}

export const useEvents = (params: IEventsParams) => {
    const { timestamp } = useParams();

    const {
        active,
    } = params;

    const {
        events: {
            useLazyLoadEventsForPeriodQuery,
            useLazyHasUnreadEventsQuery,
            useRegisterStreamerPageOpenEventMutation,
        },
        stickers: {
            useLazyReplayStickerQuery,
            useLazyToggleStickerDisableStateQuery,
        },
    } = useApi();

    const {
        currentUser,
    } = useCurrentUser();

    const {
        subscribe,
        unsubscribe,
    } = useWebSocket({ clientId: useEvents.name });

    const [loadEvents] = useLazyLoadEventsForPeriodQuery();

    const [checkUnreadEvents] = useLazyHasUnreadEventsQuery();
    const [replaySticker] = useLazyReplayStickerQuery();
    const [toggleStickerDisableState] = useLazyToggleStickerDisableStateQuery();
    const [registerStreamerPageOpenEvent] = useRegisterStreamerPageOpenEventMutation();

    const [period, setPeriod] = useState<Period>(Period.MONTH);
    const [events, setEvents] = useState<Array<IEventData>>();
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);
    const [filters, setFilters] = useState<Array<EventsFilterPreset>>([
        EventsFilterPreset.PURCHASES,
        EventsFilterPreset.BONUSES,
        EventsFilterPreset.STICKERS,
        EventsFilterPreset.MEME_CANNON,
        EventsFilterPreset.FULLSCREEN,
        EventsFilterPreset.MODERATION,
    ]);
    const [hasUnreadEvents, setHasUnreadEvents] = useState(false);

    const eventsRef = useRef(events);

    const changePeriod = (val: Period) => setPeriod(val);
    const changePage = (val: number) => setPage(val);
    const changeFilters = (val: Array<EventsFilterPreset>) => setFilters(val);

    const checkReadState = async () => {
        if (currentUser) {
            const hasUnreadEventsResult = await checkUnreadEvents().unwrap();

            if (hasUnreadEvents != hasUnreadEventsResult) {
                setHasUnreadEvents(hasUnreadEventsResult);
            }
        }
    };

    const onMessageHandler = (message: TWSMessage, callback?: (data: unknown) => void) => {
        const { type, data } = message;

        if (type === WSMessageType.EVENT_CREATED) {
            if (callback) {
                callback({ wasRead: true });
            }

            const event = data.event;
            event.id = data.eventId ?? '';
            const mappedEvent = mapDataToEvent(event) as IEventData;
            setEvents([mappedEvent].concat(eventsRef?.current || []));
        }

        if (type === WSMessageType.UNREAD_EVENTS) {
            const state = data.state;
            setHasUnreadEvents(state);
        }
    };

    useEffect(() => {
        if (page > 0 && active) {
            loadEvents({
                period,
                skip: (page - 1) * PAGE_SIZE,
                limit: PAGE_SIZE,
                filters,
                date: Number(timestamp),
            }, false)
                .unwrap()
                .then((loadedEvents) => {
                    if (loadedEvents) {
                        setEvents(loadedEvents.data);
                        setPagesCount(Math.ceil(loadedEvents.total / PAGE_SIZE));
                    }
                });
        }
    }, [page, period, filters, active, timestamp]);

    useEffect(() => {
        if (events) {
            eventsRef.current = events;
        }
    }, [events]);

    useEffect(() => {
        if (currentUser) {
            subscribe(currentUser.id, onMessageHandler);
            void checkReadState();
        }

        return () => {
            if (currentUser) {
                unsubscribe(currentUser.id, onMessageHandler);
            }
        };
    }, [currentUser]);

    return {
        date: Number(timestamp),
        events,
        hasUnreadEvents,
        page,
        pagesCount,
        changeFilters,
        changePage,
        changePeriod,
        replaySticker,
        registerStreamerPageOpenEvent,
        toggleStickerDisableState,
    };
};
