import { Column, EColumnAlignment, Hint, Row, useModals } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import { IconCloseLine } from '@assets/images/svg';
import { TApp } from '@hooks';
import { AuthButtons, Logo } from '@views';

import s from './Login.scss';


/** Props for Login component that is extended from TApp type */
interface ILoginProps extends TApp {
    className?: string;
    returnUrl?: string;
}

export const Login = (props: ILoginProps) => {
    const {
        className,
        returnUrl,
        deviceInfo: {
            isMobileLayoutRecommended,
        },
        translation: {
            t,
        },
    } = props;

    const { closeModal } = useModals();

    return (
        <Column
            grow
            rowGap={15}
            className={cn(s.login, { [s.loginMobileLayout]: isMobileLayoutRecommended }, className)}
            alignment={EColumnAlignment.CENTER}
        >
            <Row>
                <Logo width={96}/>
            </Row>
            <Row>
                <Hint style={{ textAlign: 'center' }}>
                    {t('login.prompt')}
                </Hint>
            </Row>
            <Row>
                <Column>
                    <AuthButtons returnUrl={returnUrl} className={s.authButtons}/>
                </Column>
            </Row>
            <div className={cn(s.closeButton, { [s.closeButtonMobileLayout]: isMobileLayoutRecommended } )} onClick={closeModal}>
                <IconCloseLine/>
            </div>
        </Column>
    );
};
