import {
    Button,
    Column,
    Row,
    useModals,
} from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import { ImageDefaultCurrency } from '@assets/images/png';
import { IconPlusLine } from '@assets/images/svg';
import { Avatar } from '@components';
import { TApp } from '@hooks';
import { BuyCurrencyPopup, HeaderButton } from '@views';

import s from './BalanceButton.scss';

interface IBalanceButtonProps extends TApp {
    noShadow?: boolean;
    className?: string;
}

export const BalanceButton = (props: IBalanceButtonProps) => {
    const { showModal } = useModals();
    const {
        balance: {
            balance,
        },
        currentUser: {
            currentUser,
        },
        deviceInfo: {
            isAppleMobileDevice,
            isMobileLayoutRecommended,
        },
        className,
        streamer: {
            streamer,
            state: {
                isLoading: isStreamerLoading,
            },
        },
        noShadow,
    } = props;

    const caption = (
        <Row columnGap={7}>
            <Column>{balance}</Column>
            <Column width={22}><IconPlusLine/></Column>
        </Row>
    );

    const icon = (
        <Avatar
            source={streamer?.channel.currencyImageUrl}
            fallbackImage={ImageDefaultCurrency}
            preventShowDefault={isStreamerLoading}
            width={20}
            height={20}
            style={{ borderRadius: '50%' }}
        />
    );

    return noShadow
        ? <Button
            caption={caption}
            className={cn(s.balanceButton, className)}
            icon={icon}
            onClick={() => showModal({
                content: (
                    <BuyCurrencyPopup
                        streamer={streamer!}
                        currentUser={currentUser!}
                        isMobileLayoutRecommended={isMobileLayoutRecommended}
                    />
                ),
                options: {
                    bodyClassName: cn(
                        s.buyCurrencyPopupBody,
                        {
                            [s.buyCurrencyPopupBodyMobileLayout]: isMobileLayoutRecommended,
                        },
                    ),
                    containerClassName: cn(
                        s.buyCurrencyPopupContainer,
                        {
                            [s.buyCurrencyPopupContainerMobileLayout]: isMobileLayoutRecommended,
                            [s.buyCurrencyPopupContainerMobileLayoutApple]: isAppleMobileDevice,
                        },
                    ),
                },
            })}
        />
        : <HeaderButton
            caption={caption}
            className={cn(s.balanceButton, className)}
            icon={icon}
            onClick={() => showModal({
                content: (
                    <BuyCurrencyPopup
                        streamer={streamer!}
                        currentUser={currentUser!}
                        isMobileLayoutRecommended={isMobileLayoutRecommended}
                    />
                ),
                options: {
                    bodyClassName: cn(
                        s.buyCurrencyPopupBody,
                        {
                            [s.buyCurrencyPopupBodyMobileLayout]: isMobileLayoutRecommended,
                        },
                    ),
                    containerClassName: cn(
                        s.buyCurrencyPopupContainer,
                        {
                            [s.buyCurrencyPopupContainerMobileLayout]: isMobileLayoutRecommended,
                            [s.buyCurrencyPopupContainerMobileLayoutApple]: isAppleMobileDevice,
                        },
                    ),
                },
            })}
        />;
};
