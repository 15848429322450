import {
    Column,
    Button,
    Label,
    Row,
    useModals,
    EComponentColor,
    EComponentSize,
} from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React, { useEffect, useState } from 'react';

import { IconCloseLine } from '@assets/images/svg';
import { IChannel, ITag, IUser } from '@typings';

import s from './ForbiddenTagsPopup.scss';
import { TagSelect } from '../../fragments';

interface ForbiddenTagsPopupProps {
    currentUser: IUser;
    update?: (data: Partial<IChannel>) =>void;
}

export const ForbiddenTagsPopup = (props: ForbiddenTagsPopupProps) => {
    const { closeModal } = useModals();
    const {
        currentUser,
        update = noop,
    } = props;

    const [forbiddenTags, setForbiddenTags] = useState<ITag[]>();

    const onSaveClick = () => {
        update({
            forbiddenTags,
        });
    };

    useEffect(() => {
        if (currentUser) {
            setForbiddenTags(currentUser.channel?.forbiddenTags ?? []);
        }
    }, [currentUser]);

    return (
        <Column className={s.forbiddenTagsPopup}>
            <Column className={s.forbiddenTagsPopupContent}>
                <Label caption={'Стоп-слова'} size={EComponentSize.LARGE} />
                <Label
                    caption={'Добавляйте слова, чтобы запретить отправлять стикеры, содержащие их.'}
                    style={{ lineHeight: '20px' }}
                    size={EComponentSize.SMALL}
                />
                <Row>
                    <TagSelect
                        fullWidth
                        defaultValue={forbiddenTags}
                        placeholder={'Добавить слово'}
                        debounceTime={500}
                        maxLength={20}
                        onTagsChanged={setForbiddenTags}
                    />
                </Row>
                <Row className={s.footerRow}>
                    <Button
                        color={EComponentColor.ACCENT}
                        caption={'Сохранить'}
                        width={160}
                        onClick={onSaveClick}
                    />
                </Row>
            </Column>

            <div className={s.closeButton} onClick={closeModal}>
                <IconCloseLine/>
            </div>
        </Column>
    );
};
