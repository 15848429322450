import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { STICKER_SEARCH_QUERY_NAME } from '@common';
import { useApi } from '@hooks';
import { IHookBaseParams, ISticker, IUser } from '@typings';

const PAGE_SIZE = 20;

export interface SearchParams extends IHookBaseParams {
    streamer?: IUser;
}

export const useSearch = (params: SearchParams) => {
    const [searchParams] = useSearchParams();
    const query = searchParams.get(STICKER_SEARCH_QUERY_NAME) ?? '';

    const {
        active,
        streamer,
    } = params;

    const {
        stickers: {
            useLazyLoadPageStreamerAreaSearchQuery,
            useUpdateStickerCustomSettingsMutation,
            useAddStickerToFavoritesMutation,
            useRemoveStickerFromFavoritesMutation,
            useAddStickerToChannelMutation,
            useRemoveStickerFromChannelMutation,
        },
    } = useApi();

    const [loadPage, { isFetching }] = useLazyLoadPageStreamerAreaSearchQuery();
    const [updateCustomSettings] = useUpdateStickerCustomSettingsMutation();
    const [addToFavorites] = useAddStickerToFavoritesMutation();
    const [removeFromFavorites] = useRemoveStickerFromFavoritesMutation();
    const [addToChannel] = useAddStickerToChannelMutation();
    const [removeFromChannel] = useRemoveStickerFromChannelMutation();

    const [searchQuery, setSearchQuery] = useState(query);
    const [stickers, setStickers] = useState<Array<ISticker>>([]);
    const [isEnd, setIsEnd] = useState(false);

    const stickersRef = useRef(stickers);

    const changeSearchQuery = (query: string) => setSearchQuery(query);

    const updateStickerCustomSettings = (sticker: ISticker) => {
        setStickers(stickers.map((s) => sticker.id === s.id ? { ...sticker } : s));

        if (sticker.customSettings) {
            updateCustomSettings({
                stickerId: sticker.id,
                stickerCustomSettings: sticker.customSettings,
            });
        }
    };

    const updateStickerFavoriteState = async (sticker: ISticker) => {
        const method = sticker.isFavorite ? addToFavorites : removeFromFavorites;
        const result = await method(sticker.id).unwrap();
        setStickers(stickers.map((s) => result.id === s.id ? { ...result } : s));

        return result;
    };

    const updateStickerInChannelState = async (sticker: ISticker) => {
        const method = sticker.isAddedToChannel ? addToChannel : removeFromChannel;
        const result = await method(sticker.id).unwrap();
        setStickers(stickers.map((s) => result.id === s.id ? { ...result } : s));

        return result;
    };

    const loadNextPage = async () => {
        if (active && streamer && searchQuery && !isEnd && !isFetching) {
            const params = {
                pageSize: PAGE_SIZE,
                skip: stickersRef.current.length,
                searchQuery,
                streamerId: streamer.id,
            };

            const page = await loadPage(params, false).unwrap();
            stickersRef.current = stickersRef.current.concat(page);
            setStickers(stickersRef.current);

            if (page.length < PAGE_SIZE) {
                setIsEnd(true);
            }
        }
    };

    useEffect(() => {
        stickersRef.current = [];
        setStickers([]);
        setIsEnd(false);

        if (searchQuery) {
            void loadNextPage();
        }
    }, [searchQuery]);

    useEffect(() => {
        if (!!searchQuery && active && !stickers.length) {
            void loadNextPage();
        }
    }, [stickers, streamer]);

    return {
        stickers,
        searchQuery,
        isEnd,
        isFetching,
        isLoadingAllowed: !isEnd && !isFetching,

        changeSearchQuery,
        loadNextPage,
        updateStickerCustomSettings,
        updateStickerFavoriteState,
        updateStickerInChannelState,
    };
};
