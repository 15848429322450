import { Column, EColumnAlignment, EComponentSize, Loader } from '@hyperclap/ui';
import React from 'react';

import { TApp } from '@hooks';
import { StreamerList } from '@views';

interface IMobileProps extends TApp {}

export const Mobile = (props: IMobileProps) => {
    const {
        personalArea: {
            supporters: {
                isSupportedStreamerFetching,
                supportedStreamers,
            },
        },
    } = props;

    return (
        <Column grow alignment={EColumnAlignment.CENTER}>
            { isSupportedStreamerFetching
                ? <Loader size={EComponentSize.MEDIUM}/>
                : <StreamerList useLargeSize streamers={supportedStreamers}/>
            }
        </Column>
    );
};
