import { IPageOf, ISupportedStreamer, TLoadPageUsing } from '@typings';
import { ISupporter, ISupportersFilters } from '@typings/api/Supporters';

import { baseApi } from './base.api';

export const supportersApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Supporters_List',
            'Supporters_SupportedStreamers',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            loadSupportersList: build.query<IPageOf<ISupporter>, TLoadPageUsing<ISupportersFilters>>({
                query: (params: TLoadPageUsing<ISupportersFilters>) => ({
                    url: 'supporters',
                    method: 'POST',
                    body: { ...params },
                }),
                providesTags: ['Supporters_List'],
            }),
            getSupporter: build.query<ISupporter, string>({
                query: (supporterId: string) => ({
                    url: `supporters/${supporterId}`,
                    method: 'POST',
                }),
            }),
            getSupportersCount: build.query<number, string>({
                query: (streamerId: string) => ({
                    url: 'supporters/count',
                    method: 'POST',
                    body: { streamerId },
                }),
            }),
            getSupportedStreamers: build.query<IPageOf<ISupportedStreamer>, void>({
                query: () => ({
                    url: 'supporters/me',
                    method: 'GET',
                }),
                providesTags: ['Supporters_SupportedStreamers'],
            }),
        }),
    });
