import { useLogger } from '@hyperclap/ui';
import cn from 'classnames';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';

import { Avatar } from '@components';

import s from './SendButton.scss';


export enum SendButtonColor {
    ORANGE = 'Orange',
    ROSE = 'Rose',
    BLUE = 'Blue',
}

interface SendButtonProps {
    caption: string;
    color?: SendButtonColor;
    className?: string;
    currencyIcon?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    price: number;
    sendDelay: number;
    onClick?: () => Promise<boolean>;
}

enum SendButtonState {
    READY,
    SENDING,
    SENT,
    TIMER,
}

const CAPTIONED_STATES = [SendButtonState.READY, SendButtonState.SENDING, SendButtonState.SENT];
const PROGRESS_STATES = [SendButtonState.SENDING, SendButtonState.TIMER];

const ONE_SECOND = 1000;
const ONE_10TH_OF_SECOND = ONE_SECOND / 10;
const SENDING_TIME_MS = 800;
const SENT_SHOW_TIME = 800;

export const SendButton = (props: SendButtonProps) => {
    let cooldownIntervalTimer: NodeJS.Timeout;
    let switchStateTimer: NodeJS.Timeout;

    const logger = useLogger({ target: SendButton.name, showTimestamp: true });
    const {
        caption,
        color = SendButtonColor.ORANGE,
        className,
        currencyIcon,
        disabled,
        fullWidth,
        price,
        sendDelay,
        onClick,
    } = props;

    const [buttonState, setButtonState] = useState(SendButtonState.READY);
    const [cooldownCaption, setCooldownCaption] = useState('');
    const [textCaption, setTextCaption] = useState(caption);
    const [progressStyles, setProgressStyles] = useState<CSSProperties>({});
    const cooldownTime = useRef(0);

    const sendButtonClassnames = cn(
        s.sendButton,
        s['color' + color],
        {
            [s.sendButtonFullWidth]: fullWidth,
            [s.sendButtonClickable]: !!onClick && !disabled,
            [s['color' + color + 'Disabled']]: disabled || buttonState !== SendButtonState.READY,

            [s.sendButtonSent]: buttonState === SendButtonState.SENT,
        },
        className,
    );

    const msecToTime = (msec: number) => {
        const sec = Math.round(msec / 1000);
        const timeMin = Math.floor(sec / 60);
        const timeSec = sec - (timeMin * 60);

        return `${timeMin.toString().padStart(2, '0')}:${timeSec.toString().padStart(2, '0')}`;
    };

    const decreaseCooldownTime = () => {
        logger.trace(`Cooldown time = ${cooldownTime.current}`);
        cooldownTime.current -= ONE_10TH_OF_SECOND;
        setCooldownCaption(msecToTime(cooldownTime.current));

        if (cooldownTime.current <= 0) {
            switchToState(SendButtonState.READY);
        } else {
            setTimeout(() => decreaseCooldownTime(), ONE_10TH_OF_SECOND);
        }
    };

    const switchToState = (targetState: SendButtonState) => {
        switch (targetState) {
            case SendButtonState.READY:
                clearInterval(cooldownIntervalTimer);
                clearTimeout(switchStateTimer);
                cooldownTime.current = 0;
                setTextCaption(caption);
                break;
            case SendButtonState.SENDING:
                switchStateTimer = setTimeout(() => switchToState(SendButtonState.SENT), SENDING_TIME_MS);
                setTextCaption('Отправляем');
                setProgressStyles({ animationTimingFunction: 'cubic-bezier(0.3, 0.6, 0.8, 0)', animationDuration: `${SENDING_TIME_MS}ms` });
                break;
            case SendButtonState.SENT:
                switchStateTimer = setTimeout(
                    () => switchToState(sendDelay ? SendButtonState.TIMER : SendButtonState.READY),
                    SENT_SHOW_TIME,
                );
                setTextCaption('Отправлено');
                break;
            case SendButtonState.TIMER:
                cooldownTime.current = sendDelay;
                cooldownIntervalTimer = setTimeout(() => decreaseCooldownTime(), ONE_10TH_OF_SECOND);
                setProgressStyles({ animationTimingFunction: 'linear', animationDuration: `${cooldownTime.current}ms` });
                break;
        }

        setButtonState(targetState);
    };

    const onButtonClick = async () => {
        if (buttonState !== SendButtonState.READY) {
            return;
        }

        if (onClick) {
            switchToState(SendButtonState.SENDING);
            const result = await onClick();

            if (!result) {
                switchToState(SendButtonState.READY);
            }
        }
    };

    useEffect(() => {
        if (buttonState === SendButtonState.READY) {
            setTextCaption(caption);
        }
    }, [caption, buttonState]);

    useEffect(() => {
        return () => {
            clearTimeout(cooldownIntervalTimer);
            clearTimeout(switchStateTimer);
        };
    }, []);

    return (
        <div
            className={sendButtonClassnames}
            onClick={onButtonClick}
        >
            { PROGRESS_STATES.includes(buttonState) &&
                <div
                    className={cn(
                        s.progress,
                        {
                            [s['progress' + color]]: buttonState !== SendButtonState.TIMER,
                        },
                    )}
                >
                    <div
                        className={s.progressBar}
                        style={progressStyles}
                    />
                </div>
            }
            <div className={s.sendButtonCaptionContainer}>
                { CAPTIONED_STATES.includes(buttonState) &&
                    <>
                        <div className={s.sendButtonCaption}>{textCaption}</div>
                        <div className={s.sendButtonPrice}>
                            <div className={s.sendButtonPriceCurrencyIcon}><Avatar rounded source={currencyIcon}/></div>
                            <div className={s.sendButtonPriceValue}>{price}</div>
                        </div>
                    </>
                }
                { buttonState === SendButtonState.TIMER &&
                    <div className={s.sendButtonTimer}>{cooldownCaption}</div>
                }
            </div>
        </div>
    );
};
