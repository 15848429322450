import { useLogger } from '@hyperclap/ui';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { STICKER_SEARCH_QUERY_NAME } from '@common';
import { useApi } from '@hooks';
import { ISticker } from '@typings';

const PAGE_SIZE = 20;

export interface ISearchParams {
    active?: boolean;
}

export const useSearch = (params: ISearchParams) => {
    const logger = useLogger({ target: useSearch.name });
    const [searchParams] = useSearchParams();
    const query = searchParams.get(STICKER_SEARCH_QUERY_NAME) ?? '';

    const {
        active = false,
    } = params;

    const {
        stickers: {
            useLazyLoadPagePersonalAreaSearchQuery,
        },
    } = useApi();

    const [loadPage, { isFetching: isStickersFetching, isError }] = useLazyLoadPagePersonalAreaSearchQuery();

    const [searchQuery, setSearchQuery] = useState(query);
    const [stickers, setStickers] = useState<Array<ISticker>>([]);
    const [isEndReached, setIsEndReached] = useState(false);
    const [isPageLoadingAllowed, setIsPageLoadingAllowed] = useState(false);

    const stickersRef = useRef(stickers);

    const changeSearchQuery = (query: string) => setSearchQuery(query);

    const loadNextPage = async () => {
        if (!active || isEndReached || isStickersFetching) return;

        if (isError) {
            logger.error(`Previous page loading failed. Stop loading next pages`);

            return;
        }

        const loadedPage = await loadPage({
            limit: PAGE_SIZE,
            skip: stickersRef.current.length,
            searchQuery,
        }, false).unwrap();

        if (loadedPage?.length) {
            stickersRef.current = stickersRef.current.concat(loadedPage);
            setStickers(stickersRef.current);
        }

        setIsEndReached(!loadedPage?.length || loadedPage?.length < PAGE_SIZE);
    };

    const updateLoadedSticker = (sticker: ISticker) => {
        setStickers(stickers.map((s) => s.id === sticker.id ? sticker : s));
    };

    const deleteLoadedSticker = (sticker: ISticker) => {
        setStickers(stickers.filter((s) => s.id === sticker.id));
    };

    useEffect(() => {
        stickersRef.current = [];
        setStickers([]);
        setIsEndReached(false);

        if (searchQuery) {
            void loadNextPage();
        }
    }, [searchQuery]);

    useEffect(() => {
        if (!!searchQuery && active && !stickers.length) {
            void loadNextPage();
        }
    }, [stickers]);

    useEffect(() => {
        setIsPageLoadingAllowed(!isStickersFetching && !isEndReached);
    }, [isEndReached, isStickersFetching]);

    return {
        stickers,
        searchQuery,
        isEndReached,
        isStickersFetching,
        isPageLoadingAllowed,

        changeSearchQuery,
        deleteLoadedSticker,
        loadNextPage,
        updateLoadedSticker,
    };
};
