import { useEffect, useState } from 'react';

import { IUseAssetsControlParams } from './types';

export const useAssetsControl = (params: IUseAssetsControlParams) => {
    const {
        selectedAssets,
        assetsFaces,
        onChangeAssetsList,
    } = params;
    const face = assetsFaces[0];
    const faceEmotion = face?.emotions?.[0];
    const [selected, setSelected] = useState<string[]>([]);

    const onItemClick = (id: string) => {
        const newSelected = selected.includes(id)
            ? selected.filter((item) => item !== id)
            : selected.concat(id);

        setSelected(newSelected);

        onChangeAssetsList(
            selectedAssets.filter((item) => newSelected.includes(item.id)),
        );
    };

    useEffect(
        () => {
            setSelected(
                selectedAssets.map((item) => item.id),
            );
        },
        [],
    );

    return {
        face,
        faceEmotion,
        selected,
        onItemClick,
    };
};
