import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line import/no-unresolved
import config from 'config';

import { AUTH_TOKEN_NAME } from '@common';

export const baseApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: config.hosts.streamojiApiHost,
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${localStorage.getItem(AUTH_TOKEN_NAME)}`);

            return headers;
        },
    }),
    endpoints: () => ({}),
    reducerPath: 'app/streamoji-api',
});
