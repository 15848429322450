/* eslint-disable sonarjs/no-duplicate-string */
import {
    Button,
    Column,
    Hint,
    Label,
    NumericField,
    Row,
    Select,
    SelectOptionValueType,
    Switch,
    ERowAlignment,
    EComponentColor,
    EComponentSize,
} from '@hyperclap/ui';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { castToArray, declensionUserCurrency, MEME_PARTY_DEFAULT_DURATION, MEME_PARTY_DEFAULT_SEND_DELAY } from '@common';
import { useApi, useAppContext, useWebSocket } from '@hooks';
import { IMemePartyState, IPosition, ObsWidgetType, TWSMessage, WSMessageType } from '@typings';
import { MemeParty } from '@views';

import { CustomSoundUploader, PositionSelector } from './components';
import s from './MemePartySettings.scss';

const MEME_PARTY_DEFAULT_SEND_DELAY_SETTING_NAME = 'channel.memeParty.defaultSendDelay';
const MEME_PARTY_DEFAULT_DURATION_SETTING_NAME = 'channel.memeParty.defaultDuration';

export const MemePartySettings = () => {
    const app = useAppContext();
    const {
        currentUser: {
            currentUser,
        },
        settings: {
            streamer: {
                page: {
                    updateChannel,
                },
                payments: {
                    channelCurrencyImageData,
                },
                memeparty: {
                    resetMemeParty,
                },
            },
            system,
        },
        translation: {
            t,
        },
    } = app;

    const { isLoaded: settingsAreLoaded } = system;

    const {
        memeParty: {
            useGetMemePartyStateQuery,
            useUpdateMemePartyWidgetPositionMutation,
        },
        channel: {
            useUpdateChannelMemePartySoundMutation,
            useClearChannelMemePartySoundMutation,
        },
    } = useApi();

    const {
        subscribe,
        unsubscribe,
    } = useWebSocket({ clientId: MemePartySettings.name });


    const {
        data: loadedMemePartyState,
        isSuccess: memePartyStateIsLoaded,
    } = useGetMemePartyStateQuery({});

    const [
        updatePosition,
    ] = useUpdateMemePartyWidgetPositionMutation();

    const [
        updateMemePartySound,
    ] = useUpdateChannelMemePartySoundMutation();

    const [
        clearMemePartySound,
    ] = useClearChannelMemePartySoundMutation();

    const [memePartyActive, setMemePartyActive] = useState(false);
    const [memePartySoundEnabled, setMemePartySoundEnabled] = useState(false);
    const [memePartyOwnSoundEnabled, setMemePartyOwnSoundEnabled] = useState(false);
    const [memePartyConfetti, setMemePartyConfetti] = useState(false);
    const [memePartyProgressPosition, setMemePartyProgressPosition] = useState<IPosition>({ x: 0, y: 0 });
    const [memePartyGoal, setMemePartyGoal] = useState(100);
    const [memePartyState, setMemePartyState] = useState<IMemePartyState>();
    const [memePartyDuration, setMemePartyDuration] = useState(75000);
    const [memePartySendDelay, setMemePartySendDelay] = useState(3000);

    const onSaveMemePartyClick = () => {
        void updateChannel({
            memePartyActive,
            memePartyConfetti,
            memePartyGoal,
            memePartyProgressPosition,
            memePartySoundEnabled,
            memePartyOwnSoundEnabled,
            memePartyDuration,
            memePartySendDelay,
        });
    };

    const onMessageHandler = (message: TWSMessage) => {
        const { type, data } = message;

        if (type === WSMessageType.WIDGET_STATE && data.state.widgetType === ObsWidgetType.MEME_PARTY) {
            const widgetData = data.state.widgetData as IMemePartyState;
            setMemePartyState({ ...widgetData });
        }
    };

    const onChangeSendDelay = (value?: SelectOptionValueType) => {
        setMemePartySendDelay(Number(value));
    };

    const onChangeMemePartyDuration = (value?: SelectOptionValueType) => {
        setMemePartyDuration(Number(value));
    };

    useEffect(() => {
        if (currentUser) {
            setMemePartyActive(currentUser.channel.memePartyActive ?? false);
            setMemePartySoundEnabled(currentUser.channel.memePartySoundEnabled ?? false);
            setMemePartyConfetti(currentUser.channel.memePartyConfetti ?? false);
            setMemePartyGoal(currentUser.channel.memePartyGoal || 100);
            setMemePartyProgressPosition(currentUser.channel.memePartyProgressPosition ?? { x: 0, y: 0 });
            setMemePartyOwnSoundEnabled(currentUser.channel.memePartyOwnSoundEnabled || false);
            setMemePartyDuration(
                currentUser.channel.memePartyDuration ??
                system.asInt(MEME_PARTY_DEFAULT_DURATION_SETTING_NAME) ??
                MEME_PARTY_DEFAULT_DURATION,
            );
            setMemePartySendDelay(
                currentUser.channel.memePartySendDelay ??
                system.asInt(MEME_PARTY_DEFAULT_SEND_DELAY_SETTING_NAME) ??
                MEME_PARTY_DEFAULT_SEND_DELAY,
            );
            subscribe(currentUser.id, onMessageHandler);
        }

        return () => {
            if (currentUser) {
                unsubscribe(currentUser.id, onMessageHandler);
            }
        };
    }, [currentUser, settingsAreLoaded]);

    useEffect(() => {
        if (memePartyStateIsLoaded && loadedMemePartyState) {
            setMemePartyState({ ...loadedMemePartyState });
        }
    }, [loadedMemePartyState, memePartyStateIsLoaded]);

    const onPositionChanged = (p: IPosition) => {
        updatePosition(p);
        setMemePartyProgressPosition(p);
    };

    const onSoundSelected = (soundFile?: File) => {
        if (soundFile) {
            const form = new FormData();
            form.append('sound', soundFile);
            updateMemePartySound(form);
        }
    };

    return (
        <Row padding={{ top: 15, bottom: 50 }} columnGap={20} alignment={ERowAlignment.TOP}>
            <Column width={510}>
                <Label padding={{ bottom: 10 }} caption={t('streamer-settings.memeparty.memeparty-section')} size={EComponentSize.LARGE}/>

                <Row margin={{ bottom: 10 }}>
                    <Label caption={t('streamer-settings.memeparty.enable-memeparty')} size={EComponentSize.MEDIUM} width={190}/>
                    <Switch
                        checked={currentUser?.channel?.memePartyActive ?? false}
                        color={EComponentColor.ACCENT}
                        onChange={setMemePartyActive}
                    />
                </Row>

                <Row margin={{ bottom: 10 }}>
                    <Column>
                        <NumericField
                            caption={t('streamer-settings.memeparty.memeparty-goal', {
                                currency: currentUser?.channel.currencyNameDeclensions.multiple.prepositional,
                            })}
                            min={1}
                            max={999999}
                            defaultValue={currentUser?.channel.memePartyGoal || 100}
                            debounceTime={500}
                            actions={[{ text: declensionUserCurrency(Number(memePartyGoal), currentUser), noInteraction: true }]}
                            onChanged={(val) => setMemePartyGoal(val)}
                        />
                        <Hint muted margin={{ top: 5 }} textSize={EComponentSize.SMALL}>
                            {t('streamer-settings.memeparty.memeparty-goal-description')}
                        </Hint>
                    </Column>
                </Row>

                <Row>
                    <Label caption={t('streamer-settings.memeparty.enable-sound')} size={EComponentSize.MEDIUM} width={190}/>
                    <Switch
                        checked={currentUser?.channel?.memePartySoundEnabled ?? false}
                        color={EComponentColor.ACCENT}
                        onChange={setMemePartySoundEnabled}
                    />
                </Row>

                <Row>
                    <Column grow>
                        <Row>
                            <Label caption={t('streamer-settings.memeparty.custom-sound')} size={EComponentSize.MEDIUM} width={190}/>
                            <Switch
                                checked={currentUser?.channel?.memePartyOwnSoundEnabled ?? false}
                                color={EComponentColor.ACCENT}
                                onChange={setMemePartyOwnSoundEnabled}
                            />
                        </Row>
                        { memePartyOwnSoundEnabled &&
                            <Row>
                                <CustomSoundUploader
                                    handleChange={onSoundSelected}
                                    clear={clearMemePartySound}
                                    soundLink={currentUser?.channel?.memePartyOwnSoundUrl}
                                />
                            </Row>
                        }
                    </Column>
                </Row>

                <Row margin={{ bottom: 10 }}>
                    <Label caption={t('streamer-settings.memeparty.enable-confetti')} size={EComponentSize.MEDIUM} width={190}/>
                    <Switch
                        checked={currentUser?.channel?.memePartyConfetti ?? false}
                        color={EComponentColor.ACCENT}
                        onChange={setMemePartyConfetti}
                    />
                </Row>

                <Row>
                    <Label
                        padding={{ bottom: 10 }}
                        caption={t('streamer-settings.memeparty.meme-party-duration')}
                        size={EComponentSize.MEDIUM}
                        width={190}
                    />
                </Row>
                <Row margin={{ bottom: 10 }}>
                    <Select
                        className={s.selector}
                        options={[
                            { order: 0, value: 30000, caption: `30 ${t('common.seconds-alt')}`, selected: memePartyDuration === 30000 },
                            { order: 1, value: 45000, caption: `45 ${t('common.seconds-alt')}`, selected: memePartyDuration === 45000 },
                            { order: 2, value: 60000, caption: `60 ${t('common.seconds-alt')}`, selected: memePartyDuration === 60000 },
                            { order: 3, value: 75000, caption: `75 ${t('common.seconds-alt')}`, selected: memePartyDuration === 75000 },
                        ]}
                        onValueChanged={onChangeMemePartyDuration}
                    />
                </Row>

                <Row>
                    <Label padding={{ bottom: 10 }} caption={t('streamer-settings.memeparty.sending-delay')} size={EComponentSize.MEDIUM}/>
                </Row>
                <Row margin={{ bottom: 10 }}>
                    <Select
                        className={s.selector}
                        options={[
                            { order: 0, value: 0, caption: `${t('common.no-delay')}`, selected: memePartySendDelay === 0 },
                            { order: 1, value: 3000, caption: `3 ${t('common.seconds')}`, selected: memePartySendDelay === 3000 },
                            { order: 2, value: 5000, caption: `5 ${t('common.seconds-alt')}`, selected: memePartySendDelay === 5000 },
                            { order: 3, value: 10000, caption: `10 ${t('common.seconds-alt')}`, selected: memePartySendDelay === 10000 },
                            { order: 4, value: 15000, caption: `15 ${t('common.seconds-alt')}`, selected: memePartySendDelay === 15000 },
                        ]}
                        onValueChanged={onChangeSendDelay}
                    />
                </Row>

                <Row>
                    <Label
                        padding={{ bottom: 10 }}
                        caption={t('streamer-settings.memeparty.progress-position')}
                        size={EComponentSize.MEDIUM}
                    />
                </Row>
                <Row margin={{ bottom: 20 }}>
                    <PositionSelector
                        initialPosition={currentUser?.channel?.memePartyProgressPosition}
                        onPositionChanged={(p) => onPositionChanged(p)}
                    />
                </Row>

                <Row>
                    <Button color={EComponentColor.ACCENT} caption={t('common.save')} onClick={onSaveMemePartyClick} />
                </Row>
            </Column>
            <Column grow rowGap={20} padding={{ top: 10 }}>
                <Row margin={{ bottom: 20 }}>
                    <Hint grow muted textSize={EComponentSize.SMALL}>
                        <div>
                            {castToArray<string>(t('streamer-settings.memeparty.hints.memeparty-advices', { returnObjects: true }))
                                .map((element, idx) => (
                                    <div key={idx}>
                                        <Trans i18nKey={element} components={[<a key={idx}/>]}>
                                            {element}
                                        </Trans>
                                    </div>
                                ))
                            }
                        </div>
                    </Hint>
                </Row>
                <Row>
                    <Column margin={{ top: 390 }} rowGap={20}>
                        <MemeParty
                            currencyImageUrl={channelCurrencyImageData || currentUser?.channel.currencyImageUrl}
                            goalValue={memePartyState?.goal ?? 100}
                            currentValue={memePartyState?.currentValue ?? 0}
                        />
                        <Button caption={t('common.reset')} width={120} onClick={() => resetMemeParty()}/>
                    </Column>
                </Row>
            </Column>
        </Row>
    );
};

