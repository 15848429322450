export enum SortOrder {
    ASC,
    DESC,
}

export const sortObjects = <T>(arr: Array<T>, prop: keyof T, order: SortOrder = SortOrder.ASC) => {
    return order === SortOrder.ASC
        ? arr.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1)
        : arr.sort((a, b) => a[prop] > b[prop] ? -1 : a[prop] === b[prop] ? 0 : 1);
};

export const alternateArrays = <T>(arr1: Array<T>, arr2: Array<T>) => {
    const result: Array<T> = [];
    const minLength = Math.min(arr1.length, arr2.length);

    for (let i = 0; i < minLength; i++) {
        result.push(arr1[i], arr2[i]);
    }

    result.push(...arr1.slice(minLength), ...arr2.slice(minLength));

    return result;
};

export const castToArray = <T>(val: unknown) => {
    if (Array.isArray(val)) {
        return val as Array<T>;
    } else {
        return [];
    }
};

export const mergeObjectArrays = <T>(arr1: Array<T>, arr2: Array<T>, key: keyof T) => {
    const map = new Map<string, T>();

    arr1.forEach((element) => map.set(element[key] as string, element));
    arr2.forEach((element) => map.set(element[key] as string, element));

    return Array.from(map.values());
};
