/** IMPORTANT!! Please, use prefix "Image" and alphabetical order when placing new image export */

export { default as ImageAfoninabondLogoMini } from './afoninabond-logo-mini.png';
export { default as ImageDefaultCurrency } from './default-currency.png';
export { default as ImageDefaultUser } from './default-user.png';
export { default as ImageHappyZerGLogoMini } from './happyzerg-logo-mini.png';
export { default as ImageLandingLogo } from './landing-logo.png';
export { default as ImageMemeAlertsBanner } from './ma-button.png';
export { default as ImageOlyashaaLogoMini } from './olyashaa-logo-mini.png';
export { default as ImageRomanovaleraLogoMini } from './romanovalera-logo-mini.png';
export { default as ImageRurualiceLogoMini } from './rurualice-logo-mini.png';
export { default as ImageWitnessFromFryazino } from './witness-from-fryazino.png';
