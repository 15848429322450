import { EComponentSize } from '@hyperclap/ui';
import React, { useEffect, useRef, useState } from 'react';

import { declensionUserCurrency } from '@common';
import { Avatar } from '@components';
import { EPopupTypes, IPopupInfo, IWelcomeBonusEarned } from '@hooks';
import { IUser } from '@typings';

import s from './WelcomeBonusEarned.scss';


interface WelcomeBonusEarnedProps {
    currentUser?: IUser;
    popupInfo?: IPopupInfo;
}

export const WelcomeBonusEarned = (props: WelcomeBonusEarnedProps) => {
    const {
        currentUser,
        popupInfo,
    } = props;

    const info = popupInfo as IWelcomeBonusEarned;

    const audioRef = useRef<HTMLAudioElement>(null);

    const [soundPlayed, setSoundPlayed] = useState(false);

    useEffect(() => {
        if (currentUser && popupInfo && popupInfo.type === EPopupTypes.WELCOME_BONUS_EARNED && audioRef.current && !soundPlayed) {
            setSoundPlayed(true);
            audioRef.current.volume = currentUser?.channel?.soundVolume ? currentUser?.channel?.soundVolume / 100 : 0.6;
            audioRef.current.play();
        }

        if ((!currentUser || !popupInfo) && audioRef.current && soundPlayed) {
            setSoundPlayed(false);
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    }, [popupInfo, currentUser]);

    return (
        <div className={s.popupNicknameWidget}>
            <audio src={'/assets/sounds/magic-coins.mp3'} ref={audioRef} />
            {popupInfo && popupInfo.type === EPopupTypes.WELCOME_BONUS_EARNED &&
                <div className={s.container}>
                    <div className={s.left}>
                        <div className={s.picture}>
                            <Avatar
                                source={info?.userAvatar}
                                rounded
                                isUserAvatar
                                size={EComponentSize.EXTRA_LARGE}
                            />
                        </div>
                    </div>
                    <div className={s.right}>
                        <div className={s.description}>
                            <div className={s.descriptionName}>{ info?.userName || 'АНОНИМ' }</div>
                            <div className={s.descriptionAction}>ПОЛУЧИЛ WELCOME BONUS</div>
                        </div>
                        <div className={s.amount}>
                            { `${info?.bonusValue} ${declensionUserCurrency(info?.bonusValue, currentUser)}` }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
