import { useLogger } from '@hyperclap/ui';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AUTH_TOKEN_NAME, OBS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '@common';

export const AuthRedirect = () => {
    const logger = useLogger({ target: AuthRedirect.name, showTimestamp: true });
    const [searchParams] = useSearchParams();

    const processToken = () => {
        console.log('REDIRECT HERE');

        const accessToken = searchParams.get(AUTH_TOKEN_NAME);
        const refreshToken = searchParams.get(REFRESH_TOKEN_NAME);
        const obsToken = searchParams.get(OBS_TOKEN_NAME);

        if (!accessToken) {
            logger.error('Authorization error!');
        } else {
            localStorage.setItem(AUTH_TOKEN_NAME, accessToken);
            if (refreshToken) localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
            if (obsToken) localStorage.setItem(OBS_TOKEN_NAME, obsToken);
        }
    };

    useEffect(() => {
        processToken();
        window.opener.postMessage({ action: 'close' });
    }, []);

    return (<></>);
};
