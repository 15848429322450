import { applyMargin, IBlockComponentProps } from '@hyperclap/ui';
import cn from 'classnames';
import React, { CSSProperties, useState } from 'react';

import s from './HeaderButton.scss';

interface IHeaderButtonProps extends IBlockComponentProps {
    caption?: string | React.ReactNode;
    circle?: boolean;
    disabled?: boolean;
    icon?: React.ReactNode;
    onClick?: (event: React.MouseEvent) => void;
}

export const HeaderButton = (props: IHeaderButtonProps) => {
    const {
        caption,
        circle,
        className,
        disabled,
        height,
        icon,
        margin,
        padding,
        style,
        width,
        onClick,
    } = props;

    const [isPressed, setIsPressed] = useState(false);

    const buttonClassnames = cn(
        s.headerButton,
        {
            [s.headerButtonCircle]: circle,
            [s.headerButtonPressed]: isPressed,
            [s.headerButtonClickable]: !!onClick && !disabled,
            [s.disabled]: disabled,
        },
        className,
    );

    const buttonStyle: CSSProperties = {
        ...style,
        height,
        width,
    };

    applyMargin(buttonStyle, margin);
    applyMargin(buttonStyle, padding);

    return (
        <button
            className={buttonClassnames}
            style={buttonStyle}
            type={'button'}
            onMouseDown={() => setIsPressed(true)}
            onMouseLeave={() => setIsPressed(false)}
            onMouseUp={() => setIsPressed(false)}
            onClick={onClick}
        >
            { icon &&
                <div className={s.headerButtonIcon}>{icon}</div>
            }
            { caption &&
                <div className={s.headerButtonCaption}>{caption}</div>
            }
        </button>
    );
};
