import { IThematicSet } from '@typings';

import { baseApi } from './base.api';

export const thematicSetsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'ThematicSets_List',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            loadThematicSets: build.query<IThematicSet[], void>({
                query: () => ({
                    url: `thematic-set`,
                    method: 'GET',
                }),
                providesTags: ['ThematicSets_List'],
            }),
            loadAvailableThematicSets: build.query<IThematicSet[], void>({
                query: () => ({
                    url: `thematic-set/available`,
                    method: 'GET',
                }),
                providesTags: ['ThematicSets_List'],
            }),
            createThematicSet: build.mutation<IThematicSet, FormData>({
                query: (data: FormData) => ({
                    url: `thematic-set`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['ThematicSets_List'],
            }),
            updateThematicSet: build.mutation<IThematicSet, { data: FormData, id: string }>({
                query: (data: { data: FormData, id: string }) => ({
                    url: `thematic-set/${data.id}`,
                    method: 'PUT',
                    body: data.data,
                }),
                invalidatesTags: ['ThematicSets_List'],
            }),
            removeThematicSet: build.mutation<void, string>({
                query: (id: string) => ({
                    url: `thematic-set/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['ThematicSets_List'],
            }),
        }),
    });
