import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import {
    IAvatarAsset, IAvatarAssetCategory,
    IAvatarAssetColorsMapVariableWithTestColorsByAsset,
    IAvatarAssetFace,
    IAvatarAssetFaceEmotion, IAvatarAssetLayerPosition,
} from '@typings';
import { getCombinedSvg } from '@views/pages/PersonalSettings/components/AvatarEditor/utils/getCombinedSvg';

import s from './Preview.scss';

export enum EPreviewSize {
    S = 'S',
    M = 'M',
    L = 'L ',
}

interface IPreviewProps {
    className?: string;
    assets: IAvatarAsset[];
    displayedAssets: IAvatarAsset[];
    assetsCategories: IAvatarAssetCategory[];
    layerPositions: IAvatarAssetLayerPosition[];
    face: IAvatarAssetFace;
    faceEmotion: IAvatarAssetFaceEmotion;
    size: EPreviewSize;
    hiddenLayerPositions: string[];
    variableColorsMap?: IAvatarAssetColorsMapVariableWithTestColorsByAsset[];
    testEyeColor?: string;
    isHiddenBody?: boolean;
    isScale?: boolean;
    skinColor?: string;
}

export const Preview = (props: IPreviewProps) => {
    const {
        className,
        assets,
        displayedAssets,
        assetsCategories,
        layerPositions,
        face,
        faceEmotion,
        size,
        isHiddenBody,
        hiddenLayerPositions,
        variableColorsMap,
        testEyeColor,
        isScale,
        skinColor,
    } = props;
    const rootRef = useRef<HTMLDivElement>(null);
    const shadowRootRef = useRef<ShadowRoot>();
    // const [combinedSvg, setCombinedSvg] = useState('');

    const initCombinedSvg = async () => {
        const result = await getCombinedSvg({
            assets,
            categories: assetsCategories,
            displayedAssets,
            layerPositions,
            displayedFace: face,
            displayedFaceEmotion: faceEmotion,
            hiddenLayerPositions,
            eyeColor: { color: testEyeColor || '', title: '' },
            skinColor: skinColor || '',
            variableColorsMap,
            isHiddenBody,
        });

        // setCombinedSvg(result);
        if (shadowRootRef.current) {
            shadowRootRef.current.innerHTML = result;
        }
    };

    useEffect(
        () => {
            void initCombinedSvg();
        },
        [
            props,
        ],
    );

    useEffect(
        () => {
            if (rootRef?.current && !rootRef?.current.shadowRoot) {
                shadowRootRef.current = rootRef?.current?.attachShadow({ mode: 'open' });
            }
        },
        [],
    );

    return (
        <div
            className={
                cn(
                    s.root,
                    className,
                )
            }
        >
            <div
                className={cn(
                    s.picture,
                    {
                        [s.sizeS]: size === EPreviewSize.S,
                        [s.sizeM]: size === EPreviewSize.M,
                        [s.sizeL]: size === EPreviewSize.L,
                        [s.scale]: isScale,
                    },
                )}
                // dangerouslySetInnerHTML={{ __html: combinedSvg }}
                ref={rootRef}
            />
        </div>
    );
};
