import { LoggerColor, useLogger } from '@hyperclap/ui';
import { useEffect, useRef, useState } from 'react';

import { DEFAULT_SOUND_VOLUME } from '@common';
import { useApi, useStreamer, useWebSocket } from '@hooks';
import {
    IMemePartyState,
    ISticker,
    IStickerCustomSettings,
    IUser, ObsWidgetType,
    StickerSendTopic,
    TWSMessage,
    WSMessageType,
} from '@typings';


export type TStickerHandlingCallback = (sticker: ISticker) => void;

interface IStickersParams {
    streamerChannelName?: string;
}

let memePartyTimer: NodeJS.Timeout;

export const useStickers = (params: IStickersParams) => {
    const logger = useLogger({ target: useStickers.name, showTimestamp: true });

    const {
        streamerChannelName,
    } = params;

    const {
        events: {
            useRegisterSendStickerDialogOpenEventMutation,
        },
        stickers: {
            useAddStickerToChannelMutation,
            useAddStickerToFavoritesMutation,
            useRemoveStickerFromChannelMutation,
            useRemoveStickerFromFavoritesMutation,
            useUpdateStickerCustomSettingsMutation,
            useDeleteStickerMutation,
            useSendStickerMutation,
            useSendMemeCannonStickerMutation,
        },
        memeParty: {
            useGetMemePartyStateQuery,
        },
    } = useApi();

    const {
        streamer,
    } = useStreamer({ streamerChannelName });

    const {
        subscribe,
        unsubscribe,
    } = useWebSocket({ clientId: useStickers.name });

    const [addToChannel] = useAddStickerToChannelMutation();
    const [addToFavorites] = useAddStickerToFavoritesMutation();
    const [removeFromChannel] = useRemoveStickerFromChannelMutation();
    const [removeFromFavorites] = useRemoveStickerFromFavoritesMutation();
    const [updateCustomSettings] = useUpdateStickerCustomSettingsMutation();
    const [markStickerAsDeleted] = useDeleteStickerMutation();
    const [sendSticker] = useSendStickerMutation();
    const [sendMemeCannonSticker] = useSendMemeCannonStickerMutation();
    const [registerSendStickerDialogOpenEvent] = useRegisterSendStickerDialogOpenEventMutation();
    const { data: loadedMemePartyState } = useGetMemePartyStateQuery({ streamerId: streamer?.id }, { skip: !streamer });

    const [selectedSticker, setSelectedSticker] = useState<ISticker>();
    const [topic, setTopic] = useState<StickerSendTopic>(StickerSendTopic.DEFAULT);
    const [memePartySessionState, setMemePartySessionState] = useState<IMemePartyState>();
    const [memePartyRemainder, setMemePartyRemainder] = useState(0);

    const memePartyTimerValue = useRef(0);

    const controllableVideoPlayerRef = useRef<HTMLVideoElement>(null);

    const addStickerToChannel = async (sticker: ISticker): Promise<ISticker> => {
        return await addToChannel(sticker.id).unwrap();
    };

    const addStickerToFavorites = async (sticker: ISticker): Promise<ISticker> => {
        return await addToFavorites(sticker.id).unwrap();
    };

    const removeStickerFromChannel = async (sticker: ISticker): Promise<ISticker> => {
        return await removeFromChannel(sticker.id).unwrap();
    };

    const removeStickerFromFavorites = async (sticker: ISticker): Promise<ISticker> => {
        return await removeFromFavorites(sticker.id).unwrap();
    };

    const deleteSticker = async (sticker: ISticker, callback?: TStickerHandlingCallback): Promise<ISticker> => {
        const deletedSticker = await markStickerAsDeleted(sticker.id).unwrap();
        callback?.(deletedSticker);

        return deletedSticker;
    };

    const changeStickerCustomSettings = async (
        sticker: ISticker,
        newSettings: IStickerCustomSettings,
        callback?: TStickerHandlingCallback,
    ): Promise<ISticker> => {
        const updatedSticker = await updateCustomSettings({
            stickerId: sticker.id,
            stickerCustomSettings: newSettings,
        }).unwrap();
        callback?.(updatedSticker);

        return updatedSticker;
    };

    const switchStickerFavoriteState = async (sticker: ISticker, callback?: TStickerHandlingCallback) => {
        const updatedSticker = sticker.isFavorite
            ? await removeStickerFromFavorites(sticker)
            : await addStickerToFavorites(sticker);
        callback?.(updatedSticker);

        return updatedSticker;
    };

    const switchStickerInChannelState = async (sticker: ISticker, callback?: TStickerHandlingCallback) => {
        const updatedSticker = sticker.isAddedToChannel
            ? await removeStickerFromChannel(sticker)
            : await addStickerToChannel(sticker);
        callback?.(updatedSticker);

        return updatedSticker;
    };

    const setStickerToSend = (sticker: ISticker, streamer: IUser, topic: StickerSendTopic) => {
        setSelectedSticker(sticker);
        setTopic(topic);

        registerSendStickerDialogOpenEvent({
            stickerId: sticker.id,
            streamerId: streamer.id,
            streamerName: streamer?.channel?.link ?? '',
        });

        const stickerVolume = sticker.customSettings?.customVolume ||
            streamer.channel.soundVolume ||
            DEFAULT_SOUND_VOLUME;

        if (controllableVideoPlayerRef.current) {
            controllableVideoPlayerRef.current.muted = false;
            controllableVideoPlayerRef.current.volume = stickerVolume / 100;
            void controllableVideoPlayerRef.current?.play();
        }
    };

    const resetStickerToSend = () => {
        setSelectedSticker(undefined);
        setTopic(StickerSendTopic.DEFAULT);

        if (controllableVideoPlayerRef.current) {
            controllableVideoPlayerRef.current.pause();
        }
    };

    const resetMemePartyTimer = () => {
        clearInterval(memePartyTimer);
        memePartyTimerValue.current = 0;
    };

    const onMessageHandler = (message: TWSMessage) => {
        const { type, data } = message;
        logger.debug(data, LoggerColor.MAGENTA);

        if (type === WSMessageType.WIDGET_STATE && data.state.widgetType === ObsWidgetType.MEME_PARTY) {
            const widgetData = data.state.widgetData as IMemePartyState;
            setMemePartySessionState(widgetData);
        }
    };

    useEffect(() => {
        if (streamer) {
            subscribe('public_' + streamer?.channel.link, onMessageHandler);
        }

        return () => {
            if (streamer) {
                unsubscribe('public_' + streamer?.channel.link, onMessageHandler);
            }
        };
    }, [streamer]);

    useEffect(() => {
        if (loadedMemePartyState) {
            setMemePartySessionState(loadedMemePartyState);
        }
    }, [loadedMemePartyState]);

    useEffect(() => {
        if (memePartyRemainder) {
            resetMemePartyTimer();
        }

        if (memePartySessionState?.active && streamer) {
            memePartyTimerValue.current = memePartySessionState.willEndAt - Date.now();
            memePartyTimer = setInterval(() => {
                memePartyTimerValue.current = memePartyTimerValue.current - 100;
                setMemePartyRemainder(memePartyTimerValue.current);
            }, 100);
        } else {
            clearInterval(memePartyTimer);
            memePartyTimerValue.current = 0;
        }
    }, [memePartySessionState?.active]);

    useEffect(() => {
        if (memePartyRemainder <= 0) {
            resetMemePartyTimer();
        }
    }, [memePartyRemainder]);

    useEffect(() => {
        return () => {
            resetMemePartyTimer();
        };
    }, []);

    return {
        addStickerToChannel,
        addStickerToFavorites,
        removeStickerFromChannel,
        removeStickerFromFavorites,
        switchStickerFavoriteState,
        switchStickerInChannelState,
        changeStickerCustomSettings,
        deleteSticker,

        selectedSticker,
        topic,
        memePartySessionState,
        memePartyRemainder,
        controllableVideoPlayerRef,
        setStickerToSend,
        resetStickerToSend,
        sendSticker,
        sendMemeCannonSticker,
    };
};
