import { useEffect, useState } from 'react';

import { useApi } from '@hooks';
import { IHookBaseParams, ISticker, IUser } from '@typings';

const PAGE_SIZE = 20;

interface IChannelParams extends IHookBaseParams {
    streamer?: IUser;
}

export const useChannel = (params: IChannelParams) => {
    const {
        active = false,
        streamer,
    } = params;

    const {
        stickers: {
            useLazyLoadPageStreamerAreaChannelQuery,

            useUpdateStickerCustomSettingsMutation,
            useAddStickerToFavoritesMutation,
            useRemoveStickerFromFavoritesMutation,
            useAddStickerToChannelMutation,
            useRemoveStickerFromChannelMutation,
        },
    } = useApi();

    const [loadPage, { isFetching }] = useLazyLoadPageStreamerAreaChannelQuery();

    const [updateCustomSettings] = useUpdateStickerCustomSettingsMutation();
    const [addToFavorites] = useAddStickerToFavoritesMutation();
    const [removeFromFavorites] = useRemoveStickerFromFavoritesMutation();
    const [addToChannel] = useAddStickerToChannelMutation();
    const [removeFromChannel] = useRemoveStickerFromChannelMutation();

    const [stickers, setStickers] = useState<Array<ISticker>>([]);
    const [isEnd, setIsEnd] = useState(false);

    const updateStickerCustomSettings = (sticker: ISticker) => {
        setStickers(stickers.map((s) => sticker.id === s.id ? { ...sticker } : s));

        if (sticker.customSettings) {
            updateCustomSettings({
                stickerId: sticker.id,
                stickerCustomSettings: sticker.customSettings,
            });
        }
    };

    const updateStickerFavoriteState = async (sticker: ISticker) => {
        const method = sticker.isFavorite ? addToFavorites : removeFromFavorites;
        const result = await method(sticker.id).unwrap();
        setStickers(stickers.map((s) => result.id === s.id ? { ...result } : s));

        return result;
    };

    const updateStickerInChannelState = async (sticker: ISticker) => {
        const method = sticker.isAddedToChannel ? addToChannel : removeFromChannel;
        const result = await method(sticker.id).unwrap();
        setStickers(stickers.map((s) => result.id === s.id ? { ...result } : s));

        return result;
    };

    const loadNextPage = async () => {
        if (active && streamer && !isEnd && !isFetching) {
            const params = {
                limit: PAGE_SIZE,
                skip: stickers.length,
                streamerId: streamer.id,
            };

            const page = await loadPage(params).unwrap();
            setStickers(stickers.concat(page));

            if (page.length < PAGE_SIZE) {
                setIsEnd(true);
            }
        }
    };

    useEffect(() => {
        if (active && !stickers.length) {
            loadNextPage();
        }
    }, [stickers, active]);

    return {
        stickers,
        isEnd,
        isFetching,
        isLoadingAllowed: !isEnd && !isFetching,
        loadNextPage,
        updateStickerCustomSettings,
        updateStickerFavoriteState,
        updateStickerInChannelState,
    };
};
