import {
    Column,
    Hint,
    Label,
    Row,
    TextField,
    ERowAlignment,
    EComponentSize,
} from '@hyperclap/ui';
// eslint-disable-next-line import/no-unresolved
import config from 'config';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { IconCopyLine } from '@assets/images/svg';
import { castToArray, copyToClipboard } from '@common';
import { NotificationType } from '@contexts';
import { useAppContext, useNotifications } from '@hooks';


import s from './ReferralSettings.scss';

export const ReferralSettings = () => {
    const app = useAppContext();

    const {
        currentUser: {
            currentUser,
        },
        settings: {
            streamer: {
                referrals: {
                    currentUserReferrers,
                },
            },
        },
        translation: {
            t,
        },
    } = app;

    const { notify } = useNotifications();

    const [link, setLink] = useState('');


    const onReferralLinkFieldAction = () => {
        void copyToClipboard(link?.toString() ?? '');
        notify('Ссылка скопирована в буфер обмена', NotificationType.SUCCESS);
    };

    const formatCreationTimestamp = (timestamp?: number) => {
        if (!timestamp) {
            return '';
        }

        const date = new Date(timestamp);

        return date.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    useEffect(() => {
        if (currentUser) {
            setLink(`https://t.me/${config.additional.telegramBotName}?start=ref_${currentUser.channel.refCode}`);
        }
    }, [currentUser]);

    return (
        <Row columnGap={20} margin={{ top: 15, bottom: 50 }} alignment={ERowAlignment.TOP}>
            <Column width={510} rowGap={10}>
                <Label
                    padding={{ bottom: 10 }}
                    caption={t('streamer-settings.referrals.referral-program-section')}
                    size={EComponentSize.LARGE}
                />

                <Row>
                    <Column grow>
                        { currentUser?.channel.donationAlertsUserId &&
                            <TextField
                                caption={t('streamer-settings.referrals.bot-link')}
                                defaultValue={link}
                                readonly
                                actions={[{ icon: <IconCopyLine/> }]}
                                width={410}
                                onAction={onReferralLinkFieldAction}
                            />
                        }
                        { !currentUser?.channel.donationAlertsUserId &&
                            <div className={s.warning}>
                                {t('streamer-settings.referrals.referral-program-warning')}
                            </div>
                        }
                    </Column>
                </Row>

                <Row>
                    <Column grow>
                        <Label caption={t('streamer-settings.referrals.your-referrals')} size={EComponentSize.MEDIUM}/>
                        <table className={s.refsTable}>
                            <thead>
                                <tr>
                                    <th>{t('streamer-settings.referrals.table.nickname')}</th>
                                    <th>{t('streamer-settings.referrals.table.registration-date')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                { currentUserReferrers?.map((referral) => (
                                    <tr key={referral.id}>
                                        <td>
                                            <a
                                                href={`${config.hosts.appHost}/${referral.channel.link}`}
                                                target='_blank' rel="noreferrer">{referral.channel.link}
                                            </a>
                                        </td>
                                        <td>{formatCreationTimestamp(Date.parse(referral.createdAt))}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Column>
                </Row>
            </Column>
            <Column grow padding={{ top: 10 }}>
                <Row>
                    <Hint grow muted textSize={EComponentSize.SMALL}>
                        <div>
                            {castToArray<string>(t('streamer-settings.referrals.hints.referral-program', { returnObjects: true }))
                                .map((element, idx) => (
                                    <div key={idx}>
                                        <Trans i18nKey={element} components={[<a key={idx}/>]}>
                                            {element}
                                        </Trans>
                                    </div>
                                ))
                            }
                        </div>
                    </Hint>
                </Row>
            </Column>
        </Row>
    );
};
