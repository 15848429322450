
import {
    EOrientation,
    ERelativePosition,
} from '@hyperclap/ui';
import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';

import { CACHED_STATE_INVITE_ACTIVATED_NAME, isNotUndefined, parseBool } from '@common';
import { Menu, MenuItem, MenuItemProps } from '@components';
import { TApp, useInvites } from '@hooks';

import s from './StickersSubmenu.scss';


interface IStickersSubmenuProps extends TApp {
    hideMenuActivation?: boolean;
    onSelectionChange?: (/* selection: PersonalAreaMode */) => void;
}

const defaultMenuItemProps: MenuItemProps<string> = {
    className: s.stickersSubmenuItem,
    iconClassName: s.stickersSubmenuItemIcon,
    iconPosition: ERelativePosition.LEFT,
};

export const StickersSubmenu = (props: IStickersSubmenuProps) => {
    const {
        navigation: {
            goToPath,
        },
        translation: {
            t,
        },
    } = props;

    const {
        inviteActivated,
    } = useInvites();

    const [isChannelVisible, setIsChannelVisible] = useState<boolean>(
        parseBool(localStorage.getItem(CACHED_STATE_INVITE_ACTIVATED_NAME) ?? 'false'),
    );

    const menuStructure: MenuItemProps<string>[] = [
        {
            active: !!useMatch('/stickers/favorites'),
            caption: t('common.favorites'),
            tag: '/stickers/favorites',
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/stickers/mine'),
            caption: t('common.mine'),
            tag: '/stickers/mine',
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/stickers/channel'),
            caption: t('common.channel'),
            tag: '/stickers/channel',
            visible: isChannelVisible,
            ...defaultMenuItemProps,
        },
    ];

    const onMenuItemClick = (tag?: string ) => {
        if (tag) goToPath(tag);
    };

    const menuItems = menuStructure.map((itemProps, idx) => (
        <MenuItem key={idx} {...itemProps} onClick={onMenuItemClick}/>
    ));

    useEffect(() => {
        if (isNotUndefined(inviteActivated)) {
            setIsChannelVisible(inviteActivated ?? false);
        }
    }, [inviteActivated]);

    return (
        <Menu orientation={EOrientation.VERTICAL} className={s.stickersSubmenu}>
            {menuItems}
        </Menu>
    );
};
