import { EAuthMode } from '../contexts';

export interface IAppState {
    mode: EAuthMode | string;
    playSoundOnStickerHover: boolean;
    isStreamerPageFloatingHeaderVisible: boolean;
}

export const initialAppState: IAppState = {
    mode: new URLSearchParams(window.location.search).get('mode') || EAuthMode.DEFAULT,
    playSoundOnStickerHover: false,
    isStreamerPageFloatingHeaderVisible: false,
};
