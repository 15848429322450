import { ModalProvider, ThemeContext } from '@hyperclap/ui';
import cn from 'classnames';
import React, { ReactElement, useContext } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

import appVersionJson from '@assets/json/version.json';
import { AuthProvider, NotificationProvider } from '@contexts';

import s from './App.scss';
import { AppVersion as appVersion } from '../typings';

interface IAppProps {
    landing: ReactElement;
    routing: Array<RouteObject>;
}

export const App = (props: IAppProps) => {
    const { landing, routing } = props;
    const { theme } = useContext(ThemeContext);
    const routes = useRoutes(routing);

    return (
        <AuthProvider redirectUnauthorizedTo={landing}>
            <ModalProvider>
                <NotificationProvider>
                    <div className={cn(s.root, `theme-${theme}`)}>
                        {routes}
                    </div>
                </NotificationProvider>
            </ModalProvider>
        </AuthProvider>
    );
};

export const AppVersion: appVersion = appVersionJson;
