import cn from 'classnames';
import * as React from 'react';


import s from './ColorSelector.scss';
import { IColorSelectorProps } from './types';


export const ColorSelector = (props: IColorSelectorProps) => {
    const {
        title,
        colors,
        value,
        onSelect,
    } = props;

    return (
        <div className={s.root}>
            <div className={s.title}>{title}</div>
            <div className={s.colors}>
                <label className={cn(s.picker, s.item)}>
                    <input
                        type="color"
                        value={value}
                        className={s.pickerInput}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSelect(event.target.value)}
                    />
                </label>

                {colors.map((item) => (
                    <div
                        key={item.id}
                        className={cn(s.item, { [s.selected]: item.color === value })}
                        style={{ backgroundColor: item.color }}
                        onClick={() => onSelect(item.color)}
                    />
                ))}
            </div>
        </div>
    );
};
