import { useEffect, useState } from 'react';

import { useStreamojiApi } from '@hooks';
import {
    IAvatarAsset,
    IAvatarAssetCategory,
    IAvatarAssetColorsMapVariableItemWithTestColors,
    IAvatarAssetColorsMapVariableWithTestColorsByAsset,
    IAvatarAssetEyeColor,
    IAvatarAssetFace,
    IAvatarAssetFaceEmotion,
    IAvatarAssetFaceEmotionCategory,
    IAvatarAssetLayerPosition,
    IAvatarAssetSkinColor, IUser,
} from '@typings';

import { getRandomAssetList, getDefaultFaceEmotion } from '../../utils';

interface IUseAvatarEditorParams {
    currentUser?: IUser;
    updateName(name: string): void;
}

export const useAvatarEditor = (params: IUseAvatarEditorParams) => {
    const {
        currentUser,
        updateName,
    } = params;
    const {
        avatarAssets: {
            useLoadAvatarAssetsListQuery,
            useLoadAvatarAssetsFacesListQuery,
            useLoadAvatarAssetsFacesEmotionsCategoriesListQuery,
            useLoadAvatarAssetsCategoriesListQuery,
            useLoadAvatarAssetsLayerPositionsListQuery,
            useLoadAvatarAssetsSkinColorsListQuery,
            useLoadAvatarAssetsEyeColorsListQuery,
            useUpdateAvatarMutation,
        },
    } = useStreamojiApi();
    const { data: assetsData, isFetching: isAssetsFetching } = useLoadAvatarAssetsListQuery();
    const { data: assetsFacesData, isFetching: isAssetsFacesFetching } = useLoadAvatarAssetsFacesListQuery();
    const {
        data: assetsFacesEmotionsCategoriesData,
        isFetching: isAssetsFacesEmotionsCategoriesFetching,
    } = useLoadAvatarAssetsFacesEmotionsCategoriesListQuery();
    const { data: assetsCategoriesData, isFetching: isAssetsCategoriesFetching } = useLoadAvatarAssetsCategoriesListQuery();
    const { data: assetsLayerPositionsData, isFetching: isAssetsLayerPositionsFetching } = useLoadAvatarAssetsLayerPositionsListQuery();
    const { data: assetsSkinColorsData, isFetching: isAssetsSkinColorsFetching } = useLoadAvatarAssetsSkinColorsListQuery();
    const { data: assetsEyeColorsData, isFetching: isAssetsEyeColorsFetching } = useLoadAvatarAssetsEyeColorsListQuery();
    const [updateAvatar] = useUpdateAvatarMutation();

    const assets = assetsData as IAvatarAsset[];
    const assetsFaces = assetsFacesData as IAvatarAssetFace[];
    const assetsFacesEmotionsCategories = assetsFacesEmotionsCategoriesData as IAvatarAssetFaceEmotionCategory[];
    const assetsCategories = assetsCategoriesData as IAvatarAssetCategory[];
    const assetsLayerPositions = assetsLayerPositionsData as IAvatarAssetLayerPosition[];
    const assetsSkinColors = assetsSkinColorsData as IAvatarAssetSkinColor[];
    const assetsEyeColors = assetsEyeColorsData as IAvatarAssetEyeColor[];

    const isLoading = isAssetsFetching ||
        isAssetsFacesFetching ||
        isAssetsFacesEmotionsCategoriesFetching ||
        isAssetsCategoriesFetching ||
        isAssetsLayerPositionsFetching ||
        isAssetsSkinColorsFetching ||
        isAssetsEyeColorsFetching ||
        !currentUser;
    const [displayedAssets, setDisplayedAssets] = useState<IAvatarAsset[]>([]);
    const [displayedFace, setDisplayedFace] = useState<IAvatarAssetFace>(null as unknown as IAvatarAssetFace);
    const [displayedFaceEmotion, setDisplayedFaceEmotion] = useState<IAvatarAssetFaceEmotion>(null as unknown as IAvatarAssetFaceEmotion);
    const [selectedCategory, setSelectedCategory] = useState<string>(null as unknown as string);
    const [bodyColor, setBodyColor] = useState<string>(null as unknown as string);
    const [eyeColor, setEyeColor] = useState<string>(null as unknown as string);
    const [isFaceListDisplayed, setIsFaceListDisplayed] = useState(false);
    const [variableColorsMap, setVariableColorsMap] = useState<IAvatarAssetColorsMapVariableWithTestColorsByAsset[]>([]);
    const hiddenLayerPositions = displayedAssets
        .map((item) => item.hiddenLayerPositions)
        .flat();
    const filteredAssets = assets?.filter((item) => item.categoryId === selectedCategory) || [];
    const categoriesTabs = assetsCategories?.filter((item) => !item.isNotVariable) || [];
    const [name, setName] = useState<string>();

    const randomize = () => {
        const randomFace = assetsFaces[Math.floor(Math.random() * assetsFaces.length)];
        const faceEmotion = getDefaultFaceEmotion(assetsFacesEmotionsCategories, randomFace);

        setDisplayedAssets(getRandomAssetList(assetsCategories, assets));
        setDisplayedFace(randomFace);
        setDisplayedFaceEmotion(faceEmotion);

        setEyeColor(assetsEyeColors[Math.floor(Math.random() * assetsEyeColors.length)]?.color);
        setBodyColor(assetsSkinColors[Math.floor(Math.random() * assetsSkinColors.length)]?.color);
    };

    const restore = () => {
        const avatarAssets = currentUser?.avatarAssets;

        if (avatarAssets) {
            const restoredAssets = avatarAssets.assets.map(
                (item) => assets.find((asset) => asset.id === item.assetId),
            ) as unknown as IAvatarAsset[];
            const restoredFace = assetsFaces.find((item) => item.id === avatarAssets.faceId) as unknown as IAvatarAssetFace;
            const restoredFaceEmotion = restoredFace?.emotions?.find(
                (item) => item.id === avatarAssets.faceEmotionId,
            ) as unknown as IAvatarAssetFaceEmotion;
            const restoredBodyColor = assetsSkinColors.find(
                (item) => item.id === avatarAssets.bodyColorId,
            ) as unknown as IAvatarAssetSkinColor;
            const restoredEyeColor = assetsEyeColors.find((item) => item.id === avatarAssets.eyeColorId) as unknown as IAvatarAssetEyeColor;
            const restoredVariableColorsMap = avatarAssets.assets.map((item) => {
                return {
                    assetId: item.assetId,
                    colorMap: {
                        colors: item.colorMap.map((colorMapItem) => ({
                            item: { color: colorMapItem.srcColor, title: '' },
                            testColor: colorMapItem.dstColor,
                        })),
                        images: [],
                    },
                };
            }) as IAvatarAssetColorsMapVariableWithTestColorsByAsset[];

            setDisplayedAssets(restoredAssets);
            setDisplayedFace(restoredFace);
            setDisplayedFaceEmotion(restoredFaceEmotion);
            setBodyColor(
                restoredBodyColor
                    ? restoredBodyColor.color
                    : avatarAssets.customBodyColor || '',
            );
            setEyeColor(
                restoredEyeColor
                    ? restoredEyeColor?.color
                    : avatarAssets.customEyeColor || '',
            );
            setVariableColorsMap(restoredVariableColorsMap);
        }
    };

    const onDisplayedAssetsChange = (
        value: {
            assets: IAvatarAsset[];
            faceEmotion: IAvatarAssetFaceEmotion;
            face: IAvatarAssetFace;
        }) => {
        setDisplayedAssets(value.assets);
        setDisplayedFace(value.face);
        setDisplayedFaceEmotion(value.faceEmotion);
    };

    const onCategorySelect = (value: string) => {
        setSelectedCategory(value);
        setIsFaceListDisplayed(false);
    };

    const onCategoryFaceSelect = () => {
        setSelectedCategory(null as unknown as string);
        setIsFaceListDisplayed(true);
    };

    const onBackClick = () => {
        setIsFaceListDisplayed(false);
        setSelectedCategory(null as unknown as string);
    };

    const onVariableColorsMapSelectColor = (assetId: string, value: IAvatarAssetColorsMapVariableItemWithTestColors) => {
        const assetMap = variableColorsMap.find((item) => item.assetId === assetId);

        if (assetMap) {
            const alreadyExistsColor = assetMap.colorMap.colors.some((item) => item.item.color === value.item.color);
            const newColorMap = alreadyExistsColor
                ? (
                    assetMap.colorMap.colors.map((item) => (
                        item.item.color === value.item.color
                            ? value
                            : item
                    ))
                )
                : assetMap.colorMap.colors.concat(value);

            setVariableColorsMap(
                variableColorsMap.map((item) => (
                    item.assetId === assetId
                        ? {
                            ...item,
                            colorMap: {
                                ...item.colorMap,
                                colors: newColorMap,
                            },
                        } as IAvatarAssetColorsMapVariableWithTestColorsByAsset
                        : item
                )),
            );
        } else {
            setVariableColorsMap(
                variableColorsMap.concat({
                    assetId,
                    colorMap: {
                        images: [],
                        colors: [value],
                    },
                }),
            );
        }
    };

    const onAssetSelect = (asset: IAvatarAsset) => {
        setDisplayedAssets(
            displayedAssets.some((item) => item.categoryId === asset.categoryId)
                ? displayedAssets.map((item) => (
                    item.categoryId === asset.categoryId
                        ? asset
                        : item
                ))
                : displayedAssets.concat(asset),
        );
    };

    const onUnAssetSelect = (asset: IAvatarAsset) => {
        setDisplayedAssets(
            displayedAssets.filter((item) => item.id !== asset.id),
        );
    };

    const onFaceSelect = (face: IAvatarAssetFace, faceEmotion: IAvatarAssetFaceEmotion) => {
        setDisplayedFace(face);
        setDisplayedFaceEmotion(faceEmotion);
    };

    const onFaceUnSelect = () => {
        const face = assetsFaces[0];

        setDisplayedFace(face);
        setDisplayedFaceEmotion(
            getDefaultFaceEmotion(
                assetsFacesEmotionsCategories,
                face,
            ),
        );
    };

    const onFaceEmotionSelect = (faceEmotion: IAvatarAssetFaceEmotion) => {
        setDisplayedFaceEmotion(faceEmotion);
    };

    const onBodyColorChange = (value: string) => setBodyColor(value);

    const onEyeColorChange = (value: string) => setEyeColor(value);

    const onAssetsControlChangeAssetsList = (value: IAvatarAsset[]) => setDisplayedAssets(value);

    const onRandomizeClick = () => {
        randomize();
    };

    const onNameChange = (value: string) => setName(value);

    const onSaveClick = async () => {
        const presetEyeColor = assetsEyeColors.find((item) => item.color === eyeColor);
        const presetBodyColor = assetsSkinColors.find((item) => item.color === bodyColor);

        await updateAvatar({
            assets: displayedAssets
                .map((item) => {
                    const variableColorMap = variableColorsMap.find((colorMapItem) => colorMapItem.assetId === item.id);

                    return {
                        assetId: item.id,
                        colorMap: variableColorMap
                            ? variableColorMap.colorMap.colors.map((colorMapItem) => ({
                                srcColor: colorMapItem.item.color,
                                dstColor: colorMapItem.testColor || '',
                            }))
                            : [],
                    };
                }),
            faceId: displayedFace.id,
            faceEmotionId: displayedFaceEmotion.id,
            // eyeColorId: presetEyeColor?.id,
            // bodyColorId: presetBodyColor?.id,
            ...(presetEyeColor
                ? { eyeColorId: presetEyeColor.id, customEyeColor: null as unknown as string }
                : { eyeColorId: null as unknown as string, customEyeColor: eyeColor }
            ),
            ...(presetBodyColor
                ? { bodyColorId: presetBodyColor.id, customBodyColor: null as unknown as string }
                : { bodyColorId: null as unknown as string, customBodyColor: bodyColor }
            ),
            // customEyeColor: presetEyeColor ? undefined : eyeColor,
            // customBodyColor: presetBodyColor ? undefined : bodyColor,
        });

        if (name) {
            updateName(name);
        }
    };

    useEffect(
        () => {
            if (!isLoading) {
                if (currentUser?.avatarAssets) {
                    restore();
                } else {
                    randomize();
                }
            }
        },
        [isLoading],
    );

    useEffect(
        () => {
            setName(currentUser?.name);
        },
        [currentUser?.name],
    );

    return {
        isLoading,
        assets,
        assetsFaces,
        assetsFacesEmotionsCategories,
        assetsCategories,
        assetsLayerPositions,
        displayedAssets,
        hiddenLayerPositions,
        displayedFace,
        displayedFaceEmotion,
        selectedCategory,
        filteredAssets,
        assetsSkinColors,
        assetsEyeColors,
        bodyColor,
        eyeColor,
        isFaceListDisplayed,
        variableColorsMap,
        categoriesTabs,
        onDisplayedAssetsChange,
        onCategorySelect,
        onAssetSelect,
        onUnAssetSelect,
        onFaceSelect,
        onFaceUnSelect,
        onFaceEmotionSelect,
        onBodyColorChange,
        onEyeColorChange,
        onAssetsControlChangeAssetsList,
        onCategoryFaceSelect,
        onBackClick,
        onVariableColorsMapSelectColor,
        onRandomizeClick,
        onNameChange,
        onSaveClick,
    };
};
