import React from 'react';

import { useAppContext } from '@hooks';

import { Mobile, Desktop } from './layouts';

export const Supporter = () => {
    const app = useAppContext();

    return app.deviceInfo.isMobileLayoutRecommended
        ? <Mobile/>
        : <Desktop {...app}/>;
};
