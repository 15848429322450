import * as React from 'react';

import { IAvatarAsset, IAvatarAssetFace } from '@typings';

import s from './FaceList.scss';
import { IFaceListProps } from './types';
import { getDefaultFaceEmotion } from '../../utils';
import { AssetListItem } from '../AssetListItem';


export const FaceList = (props: IFaceListProps) => {
    const {
        displayedFace,
        displayedFaceEmotion,
        assetsFaces,
        assetsSkinColors,
        assets,
        assetsCategories,
        layerPositions,
        assetsFacesEmotionsCategories,
        onSelect,
        onUnSelect,
    } = props;

    const handleItemClick = (face: IAvatarAssetFace) => () => {
        if (face.id === displayedFace.id) {
            onUnSelect();
        } else {
            onSelect(
                face,
                getDefaultFaceEmotion(assetsFacesEmotionsCategories, face),
            );
        }
    };

    return (
        <div className={s.root}>
            {assetsFaces.map((face) => (
                <AssetListItem
                    key={face.id}
                    asset={null as unknown as IAvatarAsset}
                    face={face}
                    faceEmotion={
                        face.id === displayedFace.id
                            ? displayedFaceEmotion
                            : face.emotions[0]
                    }
                    isSelected={face.id === displayedFace.id}
                    assetsFaces={assetsFaces}
                    assetsSkinColors={assetsSkinColors}
                    assetsCategories={assetsCategories}
                    layerPositions={layerPositions}
                    assets={assets}
                    onClick={handleItemClick(face)}
                />
            ))}
        </div>
    );
};
