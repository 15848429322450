import { IAvatarAssets } from './AvatarAssets';
import { IChannel } from './Channel';

export interface IFlags {
    isAdmin?: boolean;
    isModer?: boolean;
    isScout?: boolean;
}

export interface IUser {
    id: string;
    name: string;
    username: string;
    avatar: string;
    avatarAssets: IAvatarAssets;
    flags: IFlags;
    createdAt: string;
    channel: IChannel;
}

export interface ISupportedStreamer {
    streamerName: string,
    streamerLink: string,
    streamerAvatar: string,
    streamerId: string,
}

export interface IGiveBonusParams {
    userId: string;
    streamerId: string;
    value: number;
}

export interface IWalletState {
    balance: number;
    welcomeBonusEarned: boolean;
    newbieActionUsed: boolean;
}
