import React from 'react';
import { RouteObject } from 'react-router-dom';

import { Supporter, Supporters } from '@views';

export const SupportersRoutes: Array<RouteObject> = [
    {
        path: '',
        element: <Supporters/>,
    },
    {
        path: ':supporterId',
        element: <Supporter/>,
    },
];
