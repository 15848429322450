import cn from 'classnames';
// eslint-disable-next-line import/no-unresolved
import config from 'config';
import React from 'react';

import { StickerSize } from '@typings';

import s from './SettingsControl.scss';


interface SettingsControlProps {
    stickerSize?: string;
    showCorners?: boolean;
    showGrid?: boolean;
    showStickingZone?: boolean;
}

export const SettingsControl = (props: SettingsControlProps) => {
    const {
        stickerSize,
        showCorners = true,
        showGrid = true,
        showStickingZone = true,
    } = props;

    const calcStickingZoneStyles = () => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        const configuredStickerSizePercent = config?.obs.stickerSize?.[stickerSize ?? StickerSize.MEDIUM] ?? 15;

        const viewportDiagonal = Math.sqrt(Math.pow(windowWidth, 2) + Math.pow(windowHeight, 2));

        const deadZoneX = viewportDiagonal / 100 * configuredStickerSizePercent / 2;
        const deadZoneY = viewportDiagonal / 100 * configuredStickerSizePercent / 2;

        return {
            top: deadZoneY,
            right: deadZoneX,
            bottom: deadZoneY,
            left: deadZoneX,
        };
    };

    return (
        <div className={s.settingsControlOverlay}>
            { showCorners &&
                <>
                    <div className={cn(s.corner, s.cornerTop, s.cornerTopLeft)}></div>
                    <div className={cn(s.corner, s.cornerTop, s.cornerTopRight)}></div>
                    <div className={cn(s.corner, s.cornerBottom, s.cornerBottomLeft)}></div>
                    <div className={cn(s.corner, s.cornerBottom, s.cornerBottomRight)}></div>
                </>
            }

            { showGrid &&
                <div className={s.grid}>
                    <div className={s.gridLineHorizontal}/>
                    <div className={s.gridLineHorizontal}/>
                    <div className={s.gridLineVertical}/>
                    <div className={s.gridLineVertical}/>
                </div>
            }

            { showStickingZone &&
                <div className={s.deadZone} style={calcStickingZoneStyles()}/>
            }
        </div>
    );
};
