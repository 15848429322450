import { useEffect, useState } from 'react';

import { isNotUndefined } from '@common';
import { useInvites } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';

export const RootRedirect = () => {
    const {
        goToDashboard,
        goToStickersCatalogue,
    } = useNavigator();

    const {
        invitesEnabled, inviteActivated,
    } = useInvites();

    const [isStreamer, setIsStreamer] = useState<boolean>();

    useEffect(() => {
        if (isNotUndefined(isStreamer)) {
            if (isStreamer) {
                goToDashboard();
            } else {
                goToStickersCatalogue();
            }
        }
    }, [isStreamer]);


    useEffect(() => {
        setIsStreamer(invitesEnabled ? inviteActivated : true);
    }, [inviteActivated, invitesEnabled]);

    return null;
};
