export interface IBuyCurrencyParams {
    toChannel: string;
    value: number;
    preventStreamerNotification?: boolean;
    isNewbieAction?: boolean;
}

export interface IHasPaymentsParams {
    onChannel: string;
}
