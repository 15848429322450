import { useLogger } from '@hyperclap/ui';
import { useEffect, useState } from 'react';

import { useApi } from '@hooks';
import { IHookBaseParams, ISticker } from '@typings';

const PAGE_SIZE = 20;

interface IMineParams extends IHookBaseParams {}

export const useMine = (params: IMineParams) => {
    const logger = useLogger({ target: useMine.name, showTimestamp: true });
    const {
        active,
    } = params;

    const {
        stickers: {
            useLazyLoadPagePersonalAreaMineQuery,
        },
    } = useApi();

    const [loadPage, { isFetching: isStickersFetching, isError }] = useLazyLoadPagePersonalAreaMineQuery();

    const [stickers, setStickers] = useState<Array<ISticker>>([]);
    const [isEndReached, setIsEndReached] = useState(false);
    const [isPageLoadingAllowed, setIsPageLoadingAllowed] = useState(false);

    const loadNextPage = async () => {
        if (!active || isEndReached || isStickersFetching) return;

        if (isError) {
            logger.error(`Previous page loading failed. Stop loading next pages`);

            return;
        }

        const loadedPage = await loadPage({
            limit: PAGE_SIZE,
            skip: stickers.length,
        }, false).unwrap();

        if (loadedPage?.length) {
            setStickers(stickers.concat(loadedPage));
        }

        setIsEndReached(!loadedPage?.length || loadedPage?.length < PAGE_SIZE);
    };

    const updateLoadedSticker = (sticker: ISticker) => {
        setStickers(stickers.map((s) => s.id === sticker.id ? sticker : s));
    };

    const deleteLoadedSticker = (sticker: ISticker) => {
        setStickers(stickers.filter((s) => s.id !== sticker.id));
    };

    useEffect(() => {
        if (!active) {
            setStickers([]);
            setIsEndReached(false);
        }

        if (active && !stickers.length) {
            void loadNextPage();
        }
    }, [active]);

    useEffect(() => {
        setIsPageLoadingAllowed(!isStickersFetching && !isEndReached);
    }, [isEndReached, isStickersFetching]);

    return {
        isEndReached,
        isPageLoadingAllowed,
        isStickersFetching,
        stickers,

        deleteLoadedSticker,
        loadNextPage,
        updateLoadedSticker,
    };
};
