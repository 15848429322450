import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line import/no-unresolved
import config from 'config';

import { AUTH_TOKEN_NAME } from '@common';

export const baseApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: config.hosts.mcApiHost,
        prepareHeaders: (headers) => {
            const lsToken = localStorage.getItem(AUTH_TOKEN_NAME) ?? '';

            if (lsToken) {
                headers.set('authorization', `Bearer ${lsToken}`);
            }

            return headers;
        },
    }),
    endpoints: () => ({}),
    reducerPath: 'app/creator-api',
});
