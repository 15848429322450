import React from 'react';

import { useAppContext } from '@hooks';

import { Desktop, Mobile } from './layouts';

export const Dashboard = () => {
    const app = useAppContext();

    return app.deviceInfo.isMobileLayoutRecommended
        ? <Mobile {...app}/>
        : <Desktop {...app}/>;
};
