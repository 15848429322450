import cn from 'classnames';
import React from 'react';

import { ImageDefaultCurrency } from '@assets/images/png';
import { DATE_FORMAT_SHORT } from '@common';
import { Avatar } from '@components';
import { TApp } from '@hooks';

import s from './StatisticTable.scss';

interface IStatisticTableProps extends TApp {}

const formatDate = (date: Date | number) => {
    const d = typeof date === 'number' ? new Date(date) : date;

    return d.toLocaleDateString('ru', DATE_FORMAT_SHORT);
};

export const StatisticTable = (props: IStatisticTableProps) => {
    const {
        currentUser: {
            currentUser,
        },
        navigation: {
            goToEvents,
        },
        personalArea: {
            dashboard: {
                dashboardData: {
                    daysInfo: data,
                },
            },
        },
        translation: {
            t,
        },
    } = props;

    const currencyIcon = <Avatar
        className={s.roundedAvatar}
        source={(currentUser?.channel.currencyImageUrl || ImageDefaultCurrency)}
        width={12}
        height={12}
        rounded
    />;

    const rows = data.map((day, idx) => {
        const hasData = day.purchasedValue || day.bonusesValue || day.spent || day.supportersCount || day.stickers ||
            day.memeCannon || day.fullscreen || day.earnings;

        return (
            <tr
                key={idx}
                className={cn(s.statisticTableBodyRow, { [s.statisticTableBodyRowNoData]: !hasData })}
                onClick={() => goToEvents(day.date)}
            >
                <td className={s.statisticTableBodyRowCell}>{formatDate(day.date)}</td>
                <td className={s.statisticTableBodyRowCell}>{day.purchasedValue || '-'}</td>
                <td className={s.statisticTableBodyRowCell}>{day.bonusesValue || '-'}</td>
                <td className={s.statisticTableBodyRowCell}>{day.spent || '-'}</td>
                <td className={s.statisticTableBodyRowCell}>{day.supportersCount || '-'}</td>
                <td className={s.statisticTableBodyRowCell}>{day.stickers || '-'}</td>
                <td className={s.statisticTableBodyRowCell}>{day.memeCannon || '-'}</td>
                <td className={s.statisticTableBodyRowCell}>{day.fullscreen || '-'}</td>
                <td className={s.statisticTableBodyRowCell}>{day.earnings ? `${day.earnings} Р` : '-'}</td>
            </tr>
        );
    });

    return (
        <table className={s.statisticTable}>
            <thead>
                <tr className={s.statisticTableHeadRow}>
                    <td className={s.statisticTableHeadRowCell}>{t('dashboard.table.day')}</td>
                    <td className={s.statisticTableHeadRowCell}>
                        <div className={s.statisticTableHeadRowCellFlex}>
                            {currencyIcon}{t('dashboard.table.purchased')}
                        </div>
                    </td>
                    <td className={s.statisticTableHeadRowCell}>
                        <div className={s.statisticTableHeadRowCellFlex}>
                            {currencyIcon}{t('dashboard.table.bonuses')}
                        </div>
                    </td>
                    <td className={s.statisticTableHeadRowCell}>
                        <div className={s.statisticTableHeadRowCellFlex}>
                            {currencyIcon}{t('dashboard.table.spent')}
                        </div>
                    </td>
                    <td className={s.statisticTableHeadRowCell}>{t('dashboard.table.supporters')}</td>
                    <td className={s.statisticTableHeadRowCell}>{t('dashboard.table.stickers')}</td>
                    <td className={s.statisticTableHeadRowCell}>{t('dashboard.table.meme-cannon')}</td>
                    <td className={s.statisticTableHeadRowCell}>{t('dashboard.table.fullscreen')}</td>
                    <td className={s.statisticTableHeadRowCell}>{t('dashboard.table.earnings')}</td>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    );
};
