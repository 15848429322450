/** Reflects data structure for the single system setting record */
export interface ISetting {
    /** Name of the setting */
    name: string;

    /** String presentation of the value */
    value: string;
}

export type TSettingsList = Array<ISetting>;
