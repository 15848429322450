import { useEffect, useState } from 'react';

import { useApi } from '@hooks';
import { IHookBaseParams, ISticker } from '@typings';

const PAGE_SIZE = 20;

interface ICatalogueParams extends IHookBaseParams {}

export const useCatalogue = (params: ICatalogueParams) => {
    const {
        active,
    } = params;

    const {
        stickers: {
            useLazyLoadPagePersonalAreaCatalogueQuery,
        },
    } = useApi();

    const [loadPage, { isFetching: isStickersFetching }] = useLazyLoadPagePersonalAreaCatalogueQuery();

    const [stickers, setStickers] = useState<Array<ISticker>>([]);
    const [isEndReached, setIsEndReached] = useState(false);
    const [isPageLoadingAllowed, setIsPageLoadingAllowed] = useState(false);

    const loadNextPage = async () => {
        if (!active || isEndReached || isStickersFetching) return;
        const loadedPage = await loadPage({ limit: PAGE_SIZE, skip: stickers.length }, false).unwrap();

        if (loadedPage?.length) {
            setStickers(stickers.concat(loadedPage));
        }

        setIsEndReached(!loadedPage?.length || loadedPage?.length < PAGE_SIZE);
    };

    const updateLoadedSticker = (sticker: ISticker) => {
        setStickers(stickers.map((s) => s.id === sticker.id ? sticker : s));
    };

    const deleteLoadedSticker = (sticker: ISticker) => {
        setStickers(stickers.filter((s) => s.id === sticker.id));
    };

    useEffect(() => {
        if (!active) {
            setStickers([]);
            setIsEndReached(false);
        }

        if (active && !stickers.length) {
            void loadNextPage();
        }
    }, [active]);

    useEffect(() => {
        setIsPageLoadingAllowed(!isStickersFetching && !isEndReached);
    }, [isEndReached, isStickersFetching]);

    return {
        isEndReached,
        isPageLoadingAllowed,
        isStickersFetching,
        stickers,

        deleteLoadedSticker,
        loadNextPage,
        updateLoadedSticker,
    };
};
