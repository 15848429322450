import { Column, Row } from '@hyperclap/ui';
import React from 'react';

import { useApp } from '@hooks';
import { InformationPage } from '@hyperclap/views';

import s from './Privacy.scss';


export const Privacy = () => {
    const app = useApp();

    return (
        <InformationPage {...app}>
            <Column className={s.contentArea}>
                <Row className={s.title}>{'MEMEALERTS PRIVACY POLICY'}</Row>
                <Row>
                    <Column>
                        <p>Last Updated: 9/29/2023</p>

                        <p>
                            MemeAlerts is committed to maintaining your privacy.
                            Our Privacy Policy (“Policy”) is designed to help
                            you understand how we collect, use, disclose, and
                            safeguard the information we collect from or about
                            you when you visit memealerts.com (the “Service”).
                        </p>

                        <p>
                            Please read this policy carefully. We reserve the
                            right to change this policy at any time. We will
                            notify you of significant changes to the Policy.
                            Significant changes will go into effect 30 days
                            following such notification. Non-material changes or
                            clarifications will take effect immediately. You
                            should periodically check the Site and this page for
                            updates.
                        </p>

                        <p>1. WHAT IS PERSONAL INFORMATION?</p>

                        <p>
                            When we refer to “Personal Information” in this
                            Policy, we mean any information relating to an
                            identified or identifiable natural person. A natural
                            person is considered to be identifiable if, directly
                            or indirectly, in particular by means of assignment
                            to an identifier such as a name, an identification
                            number, location data, an online identifier or to
                            one or more special features, the expression of the
                            physical, physiological, genetic, psychological,
                            economic, cultural or social identity of this
                            natural person can be identified.
                        </p>

                        <p>
                            Personal Information does not include information
                            that is publicly available through government
                            records or information that has been deidentified or
                            aggregated in a way that it cannot be used to
                            identify a specific individual.
                        </p>

                        <p>2. PERSONAL INFORMATION WE COLLECT</p>

                        <p>
                            Depending on how you use the Site and Services, we
                            may collect the following types of Personal
                            Information about you:
                        </p>

                        <ul>
                            <li>
                                Account Information. When you choose to install
                                the Service, a Stream Stickers account is
                                automatically created based on your Twitch
                                account information. We store your stickers,
                                settings, and preferences in your account.
                            </li>
                            <li>
                                Live Stream Platform Account and Channel
                                Information: Depending on the permissions you
                                grant us when you install the Service or visit a
                                channel that has installed the Service, we may
                                receive your Platform ID, username, email
                                address, a list of your channel’s subscribers,
                                and your channel’s moderation data.
                                Additionally, if you install the service, we may
                                screenshot or record your live stream on your
                                channel.
                            </li>
                            <li>
                                Commercial Information: Viewed and completed
                                content, your consent to our terms and
                                conditions and other important policies, content
                                of messages or emails you send to us, context of
                                feedback or survey responses you send to us,
                                comments you post in the Services, and comments
                                or messages you post on our social media pages.
                            </li>
                            <li>
                                Sensory Information: Including a recording of
                                your voice when you use the text-to-speech
                                feature, or your profile image.
                            </li>
                            <li>Other information you provide to us.</li>
                        </ul>

                        <p>3. SOURCES OF PERSONAL INFORMATION</p>

                        <p>
                            We may collect Personal information that you provide
                            to us, that is collected automatically using
                            technology, and from third parties in the ways
                            described in this Policy.
                        </p>

                        <p>
                            INFORMATION YOU PROVIDE TO US BY USING THE SERVICE
                        </p>

                        <p>
                            You may provide your Personal Information to us when
                            you access, use, or otherwise interact with the Site
                            or Services. Below are some of the ways you provide
                            us with your Personal Information.
                        </p>

                        <ul>
                            <li>When you install the Service.</li>
                            <li>When you contact us.</li>
                            <li>
                                When you input information into your account.
                            </li>
                            <li>
                                When you participate in promotions and other
                                offers.
                            </li>
                            <li>
                                When you submit a sample of your voice to create
                                a voice clone on the Services.
                            </li>
                        </ul>

                        <p>INFORMATION WE COLLECT THROUGH TECHNOLOGY</p>

                        <p>
                            We use technology to automatically collect
                            information when you use the Site or Service.
                        </p>

                        <p>
                            We collect this information through cookies and
                            other tracking technologies (“Cookies”), which are
                            small text files that often connect you to a unique
                            identifier. Cookies are sent to a user’s browser
                            from our servers and are stored on the user’s
                            computer hard drive. Sending a cookie to a user’s
                            browser enables us to collect information about that
                            user and keep a record of the user’s preferences
                            when utilizing the Services, both on an individual
                            and aggregate basis.
                        </p>

                        <p>
                            When you install the Service on your Twitch account,
                            we will use Cookies to store your account
                            preferences and configurations.
                        </p>

                        <p>
                            INFORMATION WE RECEIVE FROM SERVICE PROVIDERS AND
                            THIRD PARTIES
                        </p>

                        <p>
                            We may also receive information (which may include
                            Personal Information) about you from our service
                            providers and third-party business partners, such as
                            Twitch and other gaming platforms we partner with,
                            and Social Media.
                        </p>

                        <ul>
                            <li>
                                Service Providers: This includes third parties
                                that provide services to us related to the
                                functionality and operability of the Site and
                                Service, such as hosting the Site and Service
                                and managing communications we send and receive.
                            </li>

                            <li>
                                Social Media Platforms: This includes Social
                                Media owned or operated by third parties, such
                                as Twitter and Discord, where we maintain a
                                social media account or where you otherwise
                                interact with us or share our content.
                            </li>
                        </ul>

                        <p>4. HOW WE USE PERSONAL INFORMATION</p>

                        <p>
                            We use Personal Information for various purposes
                            including to provide and support the Site and
                            Services and improve the Site and Services.
                            Specifically, we:
                        </p>

                        <ul>
                            <li>
                                Customer Service and Other Communications with
                                You: We may use Personal Information to contact
                                and communicate with you to provide customer
                                service and technical support, send you emails
                                to provide you with alerts and updates to the
                                Site or Service, including updates to our Terms
                                and this Policy, and other purposes for which
                                you authorize or instruct us to use your
                                Personal Information.
                            </li>

                            <li>
                                Maintaining Your Account. We use your Stream
                                Stickers account, created automatically when you
                                install the Service, to keep track of your
                                stickers, settings, configurations, and
                                preferences.
                            </li>

                            <li>
                                Operating and Maintaining the Site and Service:
                                We use your Personal Information to provide you
                                with the Service.
                            </li>

                            <li>
                                Marketing and Promotions: We may use your Twitch
                                or YouTube Channel Information and screenshots
                                of your live streams on Twitch for promotional
                                or marketing purposes, including posting
                                screenshots or short clips of your live stream
                                on the Site and marketing materials.
                            </li>

                            <li>
                                Improving the Service: To support, analyze,
                                develop, improve, and enhance our products,
                                services, user experience, and operations,
                                including the Site and Service.
                            </li>

                            <li>
                                Business Purposes: We may use Personal
                                Information for business purposes including but
                                not limited to: (1) carrying out our obligations
                                and enforcing our rights arising from any
                                contracts we have entered into, including,
                                without limitation, any contracts between you
                                and us; (2) operating and improving our products
                                and services; (3) conducting statistical or
                                demographic analysis; (4) customizing your
                                experience with our Site and Service; (5)
                                preventing fraud; and (6) other business
                                purposes permitted under applicable law.
                            </li>

                            <li>
                                Legal/Enforcement Purposes: We may use Personal
                                Information to cooperate with law enforcement or
                                other legal purposes including: (1) complying
                                with legal and regulatory requirements; (2)
                                satisfying contractual obligations; (3)
                                cooperating with law enforcement or other
                                governmental agencies for purposes of
                                investigations, public safety or matters of
                                public importance when we are legally required
                                to do so or believe that disclosure of Personal
                                Information is necessary or appropriate to
                                protect the public interest; (4) protecting and
                                defending against legal actions or claims.
                            </li>

                            <li>
                                Other purposes that you request or for which you
                                provide consent.
                            </li>
                        </ul>

                        <p>5. HOW LONG WE KEEP PERSONAL INFORMATION</p>

                        <p>
                            We retain your Personal Information for as long as
                            necessary to provide our products and services to
                            you. In certain circumstances, your information may
                            also need to be retained for a longer period of
                            time, such as when a legal hold or litigation hold
                            (i.e., a prohibition of information deletion for the
                            duration of the proceedings) is ordered in
                            connection with an administrative or judicial
                            proceeding. We will retain and use this information
                            as long as necessary to comply with our legal
                            obligations, resolve disputes and enforce our
                            agreements, and then delete it.
                        </p>

                        <p>6. WITH WHOM WE SHARE PERSONAL INFORMATION</p>

                        <p>
                            To operate our business, we may share Personal
                            Information with third parties, including:
                        </p>

                        <ul>
                            <li>
                                Service Providers: We may share Personal
                                Information in connection with the services our
                                service providers provide to us, including data
                                hosting and processing, email services, and
                                fraud protection and prevention. We require
                                these Service Providers to exercise reasonable
                                care to protect your Personal Information and
                                restrict their use of Personal Information to
                                the purposes for which we provided it to them.
                            </li>

                            <li>
                                Third Party Business Partners: We may disclose
                                Personal Information to third parties to provide
                                you with the Site and Service, including the TTS
                                Feature, perform data and statistical analysis
                                about the Site or Service, and for marketing
                                purposes.
                            </li>

                            <li>
                                Parties to Business Transactions: In the event
                                of an anticipated or actual merger or
                                acquisition, bankruptcy or other sale of all or
                                a portion of our assets, we may transfer or
                                allow third parties to use information owned or
                                controlled by us, including Personal
                                Information. We reserve the right, in connection
                                with these types of transactions, to transfer,
                                share, assign, or sell your Personal Information
                                and other information we have collected to third
                                parties or to authorize such third parties to
                                use any such information including for due
                                diligence as part of any of the transactions
                                described in this paragraph. Any Personal
                                Information you submit or that is collected
                                after this type of transfer may be subject to a
                                new privacy policy adopted by the successor
                                entity.
                            </li>

                            <li>
                                Government and Law Enforcement: We may share
                                your Personal Information with individuals or
                                private parties where necessary to enforce and
                                comply with the law, to protect our property or
                                legal rights, for the safety of the public or
                                any person, to prevent harm, to protect the
                                security and integrity of the Site and Services
                                and any equipment used to make the Site and
                                Services available.
                            </li>

                            <li>
                                Professional Advisors: We may disclose your
                                Personal Information to our professional
                                advisors, such as our attorneys, accountants,
                                financial advisors, and business advisors, in
                                their capacity as advisors to us.
                            </li>

                            <li>
                                Others: We may disclose your Personal
                                Information to any other third party upon your
                                request or with your express consent to do so.
                            </li>
                        </ul>

                        <p>
                            7. ADDITIONAL INFORMATION ABOUT OUR PRIVACY
                            PRACTICES
                        </p>

                        <p>
                            We implement and maintain information security
                            measures designed to protect Personal Information
                            from the risk of unauthorized access, disclosure,
                            alteration, loss, or destruction. Your account is
                            protected by your account password, and we urge you
                            to take steps to keep your personal information safe
                            by not disclosing your password and by logging out
                            of your account after each use.
                        </p>

                        <p>
                            WHILE WE STRIVE TO PROTECT PERSONAL INFORMATION, WE
                            DO NOT GUARANTEE THE SECURITY OF PERSONAL
                            INFORMATION. YOU PROVIDE PERSONAL INFORMATION AT
                            YOUR OWN RISK.
                        </p>

                        <p>ACCESS BY CHILDREN</p>

                        <p>
                            The Site and the Service are not directed to anyone
                            under the age of 13. The Site does not knowingly
                            collect or solicit information from anyone under the
                            age of 13 and the Service prohibits anyone under the
                            age of 13 to create an account.
                        </p>

                        <p>SOCIAL MEDIA AND THIRD-PARTY WEBSITES</p>

                        <p>
                            As part of the Service, we may provide links to or
                            compatibility with other websites and applications.
                            We also participate in and link to social media
                            platforms such as Twitter and Discord (“Social
                            Media”) that enable online sharing and collaboration
                            among customers.
                        </p>

                        <p>
                            However, we are not responsible for the privacy
                            practices employed by those websites or the
                            information or content they contain. This Policy
                            applies solely to information collected by us
                            through the Site and the Service and does not apply
                            to your use of a third-party website accessed by
                            selecting a link on our Site or via our Service, nor
                            when you interact with us or our account on Social
                            Media. We encourage our users to read the privacy
                            statements of other websites before proceeding to
                            use them.
                        </p>

                        <p>
                            To the extent that you access or use the Service
                            through or on another website or application, then
                            the privacy policy of that other website or
                            application will apply to your access or use of that
                            website or application in addition to this Policy.
                        </p>

                        <p>8. YOUR PERSONAL INFORMATION CHOICES</p>

                        <p>
                            Below, we describe ways for you to exercise control
                            over your Personal Information online, generally,
                            and when you interact with us.
                        </p>

                        <p>
                            Update Your Contact Information: You may update your
                            contact information and communication preferences by
                            contacting us as described below or logging into
                            your online account.
                        </p>

                        <p>
                            Delete Cookies on Your Browser: Most browsers
                            automatically accept cookies. You can disable this
                            function by changing your browser settings but
                            disabling cookies may impact your use and enjoyment
                            of the Site. You cannot disable all Cookies, such as
                            Cookies that are essential to the functioning of the
                            Site. You can manually delete persistent Cookies, or
                            Cookies that track your activity across websites,
                            through your browser settings.
                        </p>

                        <p>
                            Configure Your Device: The electronic device you use
                            to access the Site or Services may offer additional
                            privacy settings; please see the device settings for
                            details. Please note that even if you opt-out of
                            receiving interest-based advertising, you will still
                            receive advertisements, but they will be more
                            general in nature.
                        </p>

                        <p>
                            Consumer Resources: To change your preferences with
                            respect to certain online ads and to obtain more
                            information about third-party ad networks and online
                            behavioral advertising, visit National Advertising
                            Initiative Consumer opt-out page or the Digital
                            Advertising Alliance Self-Regulatory Program.
                            Changing your settings with individual browsers or
                            ad networks will not necessarily carry over to other
                            browsers or ad networks. As a result, depending on
                            the opt-outs you request, you may still see our ads.
                        </p>

                        <p>
                            Opt Out of Marketing Communications: If you opt-in
                            to receive marketing emails from us, we may collect
                            your name, email, mailing address, and phone number.
                            We use this information to send you promotional and
                            other electronic and hardcopy communications. We may
                            use third-party providers to deliver communications
                            to you.
                        </p>

                        <p>9. YOUR PERSONAL INFORMATION CHOICES</p>

                        <p>
                            Pursuant to California Civil Code Section 1798.83,
                            if you are a California resident, you have the right
                            to obtain: (a) a list of all third parties to whom
                            we may have disclosed your personal information
                            within the past year for direct marketing purposes,
                            and (b) a description of the categories of personal
                            information disclosed, by contacting us per the
                            “Contact Us” Section below.
                        </p>

                        <p>10. CONTACT US</p>

                        <p>
                            If you have any questions regarding this Privacy
                            Policy or the practices of this Site or Service,
                            please contact us by sending an email to
                            admin@memealerts.com.
                        </p>
                    </Column>
                </Row>
            </Column>
        </InformationPage>
    );
};
