import React from 'react';

import s from './WidgetsOverlay.scss';

interface IWidgetsOverlayProps {
    // TODO: replace by forwardRef
    areaRef?: React.Ref<HTMLDivElement>,
}

export const WidgetsOverlay = (props: React.PropsWithChildren<IWidgetsOverlayProps>) => {
    const {
        areaRef,
        children,
    } = props;

    return (
        <div ref={areaRef} className={s.widgetsOverlay}>
            {children}
        </div>
    );
};
