import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Rectangle, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import { DATE_FORMAT_LOCALE, DATE_FORMAT_SHORT } from '@common';
import { TApp } from '@hooks';
import { CustomTooltip } from '@views/pages/PersonalArea/components/Dashboard/components/StatisticChart/components';

import s from './StatisticChart.scss';

interface StatisticChartProps extends TApp {}

interface IChartRecord {
    date: string;
    value: number;
}

export const StatisticChart = (props: StatisticChartProps) => {
    const {
        personalArea: {
            dashboard: {
                dashboardData,
            },
        },
        translation: { t, i18n },
    } = props;

    const [chartData, setChartData] = useState<Array<IChartRecord>>();

    useEffect(() => {
        if (dashboardData) {
            setChartData(dashboardData.daysInfo.map((d) => ({
                date: new Date(d.date).toLocaleString(DATE_FORMAT_LOCALE, DATE_FORMAT_SHORT),
                value: d.earnings,
            })).reverse());
        }
    }, [dashboardData]);

    return (
        <ResponsiveContainer width={'100%'} height={'100%'}>
            <BarChart width={500} height={300} data={chartData}>
                <CartesianGrid strokeDasharray={'1 3'} className={s.chartGrid}/>
                <XAxis dataKey={'date'} fontSize={12} />
                <Tooltip
                    content={<CustomTooltip language={i18n.language}/>}
                    wrapperClassName={s.chartTooltipWrapper}
                    animationDuration={100}
                    cursor={false}
                    labelClassName={s.chartTooltipWrapperLabel}
                    labelFormatter={(l) => `${l}`}
                />
                <Bar
                    isAnimationActive={true}
                    dataKey={'value'}
                    name={t('dashboard.chart.earnings')}
                    animationDuration={1000}
                    className={s.chartBar}
                    activeBar={<Rectangle className={s.chartBarActive} />}
                />
            </BarChart>
        </ResponsiveContainer>
    );
};
