export interface IAvatarAssets {
    assets: Array<{
        assetId: string;
        colorMap: Array<{
            srcColor: string;
            dstColor: string;
        }>;
    }>;
    faceId: string;
    faceEmotionId: string;
    eyeColorId?: string;
    bodyColorId?: string;
    customEyeColor?: string;
    customBodyColor?: string;
}
