import { EComponentSize } from '@hyperclap/ui';
import React, { useEffect, useRef, useState } from 'react';

import { Avatar } from '@components';
import { EPopupTypes, IPopupInfo, IPopupNewSale } from '@hooks';
import { IUser } from '@typings';

import s from './PopupNewSale.scss';


interface PopupNewSaleProps {
    currentUser?: IUser;
    popupInfo?: IPopupInfo;
}

export const PopupNewSale = (props: PopupNewSaleProps) => {
    const {
        currentUser,
        popupInfo,
    } = props;

    const info = popupInfo as IPopupNewSale;

    const audioRef = useRef<HTMLAudioElement>(null);

    const [soundPlayed, setSoundPlayed] = useState(false);

    useEffect(() => {
        if (currentUser && popupInfo && popupInfo.type === EPopupTypes.NEW_SALE && audioRef.current && !soundPlayed) {
            setSoundPlayed(true);
            audioRef.current.volume = currentUser?.channel?.soundVolume ? currentUser?.channel?.soundVolume / 100 : 0.6;
            void audioRef.current.play();
        }

        if ((!currentUser || !popupInfo) && audioRef.current && soundPlayed) {
            setSoundPlayed(false);
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    }, [popupInfo, currentUser]);

    return (
        <div className={s.popupNicknameWidget}>
            <audio src={'/assets/sounds/magic-coins.mp3'} ref={audioRef} />
            {popupInfo && popupInfo.type === EPopupTypes.NEW_SALE &&
                <div className={s.container}>
                    <div className={s.left}>
                        <div className={s.picture}>
                            <Avatar
                                source={info?.userAvatar}
                                rounded
                                isUserAvatar
                                size={EComponentSize.EXTRA_LARGE}
                            />
                        </div>
                    </div>
                    <div className={s.right}>
                        <div className={s.description}>
                            <div className={s.descriptionName}>{ info?.userName || 'АНОНИМ' }</div>
                            <div className={s.descriptionAction}>КУПИЛ</div>
                        </div>
                        <div className={s.amount}>
                            { info?.packDescription }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
