import { TKeysOf } from '@typings';

export interface ILoadPage {
    skip?: number;
    limit?: number;
}

export type TLoadPageUsing<T = object> = ILoadPage & {
    [P in TKeysOf<T>]: T[P];
}

export interface IPageOf<T> {
    data: Array<T>,
    total: number;
}
