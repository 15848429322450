import { useLogger } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';

import { IPosition } from '@typings';

import s from './PositionSelector.scss';

interface PositionSelectorProps {
    initialPosition?: IPosition;
    onPositionChanged: (position: IPosition) => void;
}

export const PositionSelector = (props: PositionSelectorProps) => {
    const {
        initialPosition,
        onPositionChanged = noop,
    } = props;

    const logger = useLogger({ target: PositionSelector.name });

    const [initPos, setInitPos] = useState<IPosition>();

    const moverRef = useRef<HTMLDivElement>(null);
    const areaRef = useRef<HTMLDivElement>(null);
    const isMoverDragged = useRef(false);
    const mouseStartPosition = useRef<IPosition>({ x: 0, y: 0 });
    const moverPosition = useRef<IPosition>({ x: 0, y: 0 });
    let maxMoverX = 0;
    let maxMoverY = 0;

    const onMoverGrabbed = (e: MouseEvent) => {
        e.stopPropagation();
        if (!moverRef.current || !areaRef.current) return;

        maxMoverX = areaRef.current.clientWidth - moverRef.current.clientWidth;
        maxMoverY = areaRef.current.clientHeight - moverRef.current.clientHeight;

        const { offsetLeft: left, offsetTop: top } = moverRef.current;
        mouseStartPosition.current.x = e.pageX - moverRef.current.clientLeft;
        mouseStartPosition.current.y = e.pageY - moverRef.current.clientTop;
        moverPosition.current.x = left;
        moverPosition.current.y = top;

        isMoverDragged.current = true;
        document.addEventListener('mousemove', onMoverDrag);
        document.addEventListener('mouseup', onMoverReleased);
    };

    const onMoverDrag = (e: MouseEvent) => {
        if (!isMoverDragged.current || !moverRef.current || !areaRef.current) return;
        const { pageX: currentX, pageY: currentY } = e;
        const mouseDeltaX = currentX - mouseStartPosition.current.x;
        const mouseDeltaY = currentY - mouseStartPosition.current.y;
        let newX = moverPosition.current.x + mouseDeltaX;
        let newY = moverPosition.current.y + mouseDeltaY;

        if (newX < 0) {
            newX = 0;
        }

        if (newX > maxMoverX) {
            newX = maxMoverX;
        }


        if (newY < 0) {
            newY = 0;
        }

        if (newY > maxMoverY) {
            newY = maxMoverY;
        }

        moverRef.current.style.left = newX + 'px';
        moverRef.current.style.top = newY + 'px';
    };

    const onMoverReleased = () => {
        isMoverDragged.current = false;
        document.removeEventListener('mousemove', onMoverDrag);
        document.removeEventListener('mouseup', onMoverReleased);

        if (moverRef.current && areaRef.current) {
            const pos: IPosition = {
                x: (moverRef.current.offsetLeft + moverRef.current.clientWidth / 2) / areaRef.current.clientWidth,
                y: (moverRef.current.offsetTop + moverRef.current.clientHeight / 2) / areaRef.current.clientHeight,
            };

            logger.debug(JSON.stringify(pos));

            onPositionChanged(pos);
        }
    };

    useEffect(() => {
        if (initialPosition && initialPosition.x && initialPosition.y ) {
            setInitPos(initialPosition);
        }
    }, [initialPosition]);

    useEffect(() => {
        if (initPos && areaRef.current && moverRef.current) {
            logger.debug(JSON.stringify(initPos));

            moverRef.current.style.left = areaRef.current.clientWidth * initPos.x - moverRef.current.clientWidth / 2 + 'px';
            moverRef.current.style.top = areaRef.current.clientHeight * initPos.y - moverRef.current.clientHeight / 2 + 'px';
        }
    }, [initPos]);

    useEffect(() => {
        moverRef.current?.addEventListener('mousedown', onMoverGrabbed);

        return () => moverRef.current?.removeEventListener('mousedown', onMoverGrabbed);
    }, []);

    return (
        <div
            ref={areaRef}
            className={s.positionSelector}
        >
            <div className={s.positionSelectorDescription}>
                <div className={s.positionSelectorDescriptionTitle}>{t('streamer-settings.memeparty.stream-window')}</div>
                <div>{t('streamer-settings.memeparty.drag-n-drop-description')}</div>
            </div>
            <div
                ref={moverRef}
                className={s.mover}
            >
                {t('streamer-settings.memeparty.progress-bar')}
            </div>
        </div>
    );
};
