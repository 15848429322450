import { useDeviceInfo, useModals } from '@hyperclap/ui';
import { useTranslation } from 'react-i18next';
import { useMatch, useOutletContext, useParams } from 'react-router-dom';

import { useStickerCreation } from '@hooks/app/creator';
import { usePersonalArea } from '@hooks/app/personal-area';
import { useStreamerArea } from '@hooks/app/streamer-area';
import { useBalance } from '@hooks/app/users/Balance';

import { useEvents } from './events';
import { useInvites } from './invites';
import { useNavigator } from './navigator';
import { useSettings } from './settings';
import { useStickers } from './stickers';
import { useTelegram } from './telegram';
import { useCurrentUser, useStreamer } from './users';
import { useWebSocket } from '../websocket';

export const useApp = () => {
    const { streamerChannelName } = useParams();

    useWebSocket({ clientId: 'memealerts-streamer-area', queryParams: { streamerName: streamerChannelName } });

    const balance = useBalance({ active: !!streamerChannelName, streamerName: streamerChannelName });
    const currentUser = useCurrentUser();
    const deviceInfo = useDeviceInfo();
    const events = useEvents({ active: !!useMatch('/events/*') });
    const invites = useInvites();
    const modals = useModals();
    const navigation = useNavigator();
    const personalArea = usePersonalArea();
    const stickers = useStickers({ streamerChannelName });
    const stickersCreation = useStickerCreation();
    const streamer = useStreamer({ streamerChannelName });
    const streamerArea = useStreamerArea({ streamerChannelName });
    const settings = useSettings();
    const telegram = useTelegram();
    const translation = useTranslation();

    return {
        balance,
        currentUser,
        deviceInfo,
        events,
        invites,
        modals,
        navigation,
        personalArea,
        stickers,
        stickersCreation,
        streamer,
        streamerArea,
        settings,
        telegram,
        translation,

        streamerChannelName,
    };
};

export type TApp = ReturnType<typeof useApp>;

export const useAppContext = () => useOutletContext<TApp>();
