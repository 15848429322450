import cn from 'classnames';
import React from 'react';


import { ImageDefaultCurrency } from '@assets/images/png';
import { PictureDog, PictureMemeParty } from '@assets/images/svg';
import { Avatar } from '@components';

import s from './MemeParty.scss';

interface MemePartyProps {
    currencyImageUrl?: string;
    goalValue: number;
    currentValue: number;
    obsMode?: boolean;
}

export const MemeParty = (props: MemePartyProps) => {
    const {
        currencyImageUrl,
        currentValue = 0,
        goalValue = 100,
        obsMode,
    } = props;

    let progress = currentValue / goalValue * 100;
    if (progress > 100) progress = 100;

    return (
        <div className={cn(
            s.memePartyWidget,
            {
                [s.memePartyWidgetPulse]: (goalValue <= currentValue) && obsMode,
            },
        )}>
            <div className={s.progressBar}>
                <div
                    className={cn(
                        s.progressBarProgress,
                        {
                            [s.progressBarProgressShadow]: goalValue - currentValue >= 2,
                            [s.progressBarProgressAlternative]: progress < 4,
                        },
                    )}
                    style={{
                        width: `${progress}%`,
                        borderWidth: progress < 5
                            ? '0'
                            : '2px',
                        margin: progress < 2
                            ? '7px 2px'
                            : progress < 3
                                ? '5px 2px'
                                : progress < 4
                                    ? '4px 2px'
                                    : progress < 5
                                        ? '2px 2px'
                                        : '0',
                        height: progress < 2
                            ? '26px'
                            : progress < 3
                                ? '28px'
                                : progress < 4
                                    ? '31px'
                                    : progress < 5
                                        ? '35px'
                                        : '39px',
                    }}
                />
                <div className={s.progressBarText}>
                    {`${currentValue} / ${goalValue}`}
                </div>
                <div className={s.progressBarCurrency}>
                    <Avatar
                        source={currencyImageUrl || ImageDefaultCurrency}
                        fallbackImage={ImageDefaultCurrency}
                        width={20}
                        height={20}
                    />
                    {/* <img src={(currencyImageUrl ?? ImageDefaultCurrency)} />*/}
                </div>
            </div>
            <div className={s.picture}>
                <PictureDog />
            </div>
            <div className={s.memePartyPicture}>
                <PictureMemeParty />
            </div>
        </div>
    );
};
