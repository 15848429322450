import Cookies, { CookieSetOptions } from 'universal-cookie';

/* Gets the cookie's value by name */
export const getCookie = (name: string) => {
    const matches = document.cookie.match(
        new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
    );

    return matches ? decodeURIComponent(matches[1]) : undefined;
};

/* Sets the cookie's value by name */
export const setCookie = (name: string, value: string, options?: CookieSetOptions) => {
    const cookie = new Cookies();
    cookie.set(name, value, options);
};

/* Copies value to clipboard */
export const copyToClipboard = async (value: string) => {
    await navigator?.clipboard?.writeText(value);
};
