import { configureStore } from '@reduxjs/toolkit';

import { api, appSliceReducer, creatorApi, versionApi, streamojiApi } from './app';

export const store = configureStore({
    reducer: {
        app: appSliceReducer,
        [api.reducerPath]: api.reducer,
        [creatorApi.reducerPath]: creatorApi.reducer,
        [versionApi.reducerPath]: versionApi.reducer,
        [streamojiApi.reducerPath]: streamojiApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat([
        api.middleware,
        creatorApi.middleware,
        versionApi.middleware,
        streamojiApi.middleware,
    ]),
});

export type AppState = ReturnType<typeof store.getState>;

export * from './app';
