import { Column, Row } from '@hyperclap/ui';
import React from 'react';

import { useApp } from '@hooks';
import { InformationPage } from '@hyperclap/views';

import s from './Terms.scss';

export const Terms = () => {
    const app = useApp();

    return (
        <InformationPage {...app}>
            <Column className={s.contentArea}>
                <Row className={s.title}>{'MEMEALERTS TERMS OF USE'}</Row>
                <Row>
                    <Column>
                        <p>(Effective as of 16.10.2023)</p>

                        <p>
                            Welcome to MemeAlerts! These Terms of Service
                            agreement governs your access and use of
                            https://www.memealerts.com (the “Site”), in which
                            users can display stickers on their Live Stream (the
                            “Service”).
                        </p>

                        <p>
                            Please review the following terms carefully. By
                            accessing, viewing, or using the Site or Service,
                            you signify your agreement to be legally bound to
                            these Terms. If you do not agree to be bound by
                            these Terms of Use in their entirety, you may not
                            access or use the SITE OR SERVICE.
                        </p>

                        <p>
                            THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN
                            INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN
                            COURT OR JURY TRIALS OR CLASS ACTIONS AND ALSO LIMIT
                            THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A
                            DISPUTE. CAREFULLY REVIEW THE DISPUTE RESOLUTION
                            SECTION OF THESE TERMS BEFORE YOU ACCESS OR USE THE
                            SITE OR SERVICE.
                        </p>

                        <p>1. ELIGIBILITY</p>

                        <p>
                            By accessing or using the Site or Service, you
                            affirm that you are of legal age to enter into these
                            Terms, or, if you are not of legal age, that you are
                            at least 13 years of age and have obtained parental
                            or guardian consent to enter into these Terms and
                            your parent or guardian consents to these Terms on
                            your behalf. If you violate or do not agree to these
                            Terms, then your access to and use of the Site or
                            Service is unauthorized.
                        </p>

                        <p>2. MODIFICATIONS OF THESE TERMS</p>

                        <p>
                            We can amend these Terms at any time and, as such,
                            we reserve the right, in our sole discretion, to do
                            so as needed. We will update these Terms in the
                            event of any such amendments. It is your sole
                            responsibility to check the Site from time to time
                            to view any such changes in these Terms. Your
                            continued use of the Site or Service after the
                            posting of any changes to the Terms signifies your
                            agreement to our revisions. We will endeavor to
                            notify you of material changes to the Terms by
                            posting a notice on the Site homepage. For this
                            additional reason, you should keep your contact and
                            profile information current.
                        </p>

                        <p>3. USER LICENSE</p>

                        <p>
                            Provided you comply with these Terms, MemeAlerts
                            grants you a limited license, which may be revoked
                            at any time and for any reason or no reason, to
                            access and use the Site or Service solely for your
                            personal use. You may not remove any trademark,
                            copyright, or other proprietary notices contained on
                            such pages. Any rights not expressly granted herein
                            are reserved by MemeAlerts.
                        </p>

                        <p>4. PERSONAL INFORMATION</p>

                        <p>
                            All Personal Information we collect will be handled
                            in accordance with our Privacy Policy. You agree to
                            keep such Personal Information current and that the
                            information you provide shall be truthful.
                        </p>

                        <p>
                            5. CANCELLATION, TERMINATION, TRANSFER, AND
                            EXPIRATION OF ACCOUNT
                        </p>

                        <p>a. Termination of Use by You</p>

                        <p>
                            You may terminate your use of the Service at any
                            time by requesting via email. Any User Content you
                            have Submitted using the Site or Service may remain
                            in our archives. We have no obligation to return any
                            User Content you have Submitted.
                        </p>

                        <p>
                            b. Suspension or Termination of Your Account by
                            MemeAlerts
                        </p>

                        <p>
                            MemeAlerts may suspend or terminate your access to
                            or use of the Site or Service for any reason, with
                            or without prior notice to you. In such a case, your
                            license to access and use the Site, or the Service
                            is immediately terminated. MemeAlerts shall not be
                            liable to you or any third party for any termination
                            or suspension of your ability to access or use the
                            Site or Service. MemeAlerts has no obligation to
                            archive or otherwise store any User Content upon
                            suspension or termination of your access.
                        </p>

                        <p>6. ACCEPTABLE USE POLICY</p>

                        <p>
                            You may only use the Site and Service for lawful
                            purposes and in accordance with these Terms,
                            including this Acceptable Use Policy. You agree that
                            failure to adhere to any of these conditions
                            constitutes a material breach of these Terms.
                        </p>

                        <p>
                            You shall notify us immediately if you become aware
                            of anyone violating the Acceptable Use Policy and
                            shall reasonably assist us with any investigations
                            that we may conduct in light of the information you
                            provide.
                        </p>

                        <p>a. Compliance with Laws</p>

                        <p>
                            You agree to use the Site and Service in compliance
                            with applicable federal, state, or local laws,
                            rules, or regulations (“Applicable Laws”).
                        </p>

                        <p>b. Truthful and Honest</p>

                        <p>
                            You agree that when using the Site and Service you
                            will:
                        </p>

                        <ul>
                            <li>
                                Submit only true, correct, and accurate
                                information.
                            </li>

                            <li>
                                Not misrepresent your affiliations with
                                MemeAlerts or any third party.
                            </li>

                            <li>
                                Not commit fraud or falsify information in
                                connection with your use of the Site or Service.
                            </li>

                            <li>
                                Not act maliciously against the business
                                interests or reputation of MemeAlerts.
                            </li>
                        </ul>

                        <p>c. Protection of Intellectual Property Rights</p>

                        <p>You agree not to:</p>

                        <ul>
                            <li>
                                Use the Site or Service in a manner which
                                infringes or misappropriates the Intellectual
                                Property Rights of MemeAlerts or any third
                                party.
                            </li>

                            <li>
                                Modify, adapt, translate, copy, distribute,
                                re-publish, transmit, broadcast, display,
                                perform, reproduce, publish, reuse, sell,
                                resell, license, create derivative works of, or
                                transfer the Site or the Service in whole or in
                                part.
                            </li>

                            <li>
                                Create a competitive product or service
                                offering.
                            </li>

                            <li>
                                Link to or use the Service in a manner which
                                does or has the potential to, in our sole
                                opinion, damage or dilute the reputation of
                                MemeAlerts or its third-party providers and the
                                goodwill associated with MemeAlert’s names,
                                trademarks, or the Service.
                            </li>

                            <li>
                                Create the false appearance that any program,
                                person, or entity is associated with you,
                                MemeAlerts, the Service, or any third party.
                            </li>
                        </ul>

                        <p>d. Misuse of the Service</p>

                        <p>You agree not to use the Site or Service to:</p>

                        <ul>
                            <li>
                                Harass, annoy, intimidate, or threaten any
                                person.
                            </li>

                            <li>
                                Attempt to gain unauthorized access to other
                                computer systems from or through the Site or
                                Service.
                            </li>

                            <li>
                                Interfere with another’s use or enjoyment of the
                                Site or Service.
                            </li>

                            <li>
                                Cend chain letters or junk mail, spam, or use of
                                distribution lists.
                            </li>

                            <li>
                                Deep link to the Site or access the Site with
                                any robot, spider, web crawler, extraction
                                software, or any other automated process or
                                device to scrape, copy, or monitor any portion
                                of the Site or Service or any Content available
                                from the Site or Service.
                            </li>

                            <li>
                                Share any personal information for which you do
                                not have authorization or consent to share with
                                us.
                            </li>

                            <li>
                                Share confidential information of any third
                                party.
                            </li>

                            <li>
                                Access the Service for any reason other than
                                your personal, non-commercial use solely as
                                permitted by the normal functionality of the
                                Service.
                            </li>

                            <li>
                                Collect or harvest any Personal Information of
                                any user of the Site or the Service.
                            </li>

                            <li>
                                Use the Site or the Service for the solicitation
                                of business in the course of trade or in
                                connection with a commercial enterprise.
                            </li>
                        </ul>

                        <p>
                            e. Interference with Operation of the Site and
                            Service.
                        </p>

                        <p>You agree not to:</p>

                        <ul>
                            <li>
                                Engage in activities that could render the Site
                                or Service inoperable or make use of the Site or
                                Service more difficult.
                            </li>

                            <li>
                                Upload, post, or transmit viruses or other
                                malware to the Site or Service.
                            </li>

                            <li>
                                Disrupt, interfere with, overburden, or
                                otherwise harm or violate the security of the
                                Site or Service, system resources, servers or
                                networks connected to or accessible through the
                                Site.
                            </li>
                        </ul>

                        <p>
                            If you violate this Acceptable Use Policy,
                            MemeAlerts may not have an adequate remedy at law
                            and monetary compensation may not be a sufficient
                            remedy. In that event, we may seek immediate
                            injunctive relief, without the need to post a bond.
                            MemeAlerts reserves all of its statutory and common
                            law rights against any person or entity who violates
                            this Acceptable Use Policy and may suspend or
                            terminate your ability to use the Site or Service
                            for a violation of this Acceptable Use Policy.
                        </p>

                        <p>7. USER CONTENT RESTRICTIONS</p>

                        <p>a. Nature of User Content</p>

                        <p>
                            When you use the Service, you may be able to
                            provide, create, post, transmit, display, publish,
                            distribute, broadcast, upload or otherwise submit
                            (collectively “Submit”) comments, data, images,
                            text, photos, files, audio, voices, public messages
                            and posts, and other content and materials, and to
                            respond to content provided by others, collectively
                            (“User Content”) to or through the Service. You are
                            solely responsible for the User Content that is
                            posted, uploaded, linked to or otherwise made
                            available via the Service under your registered
                            account, subject to a reported and confirmed breach
                            of the security of your account or credentials.
                        </p>

                        <p>
                            Any User Content will be treated as non-confidential
                            and non-proprietary. When you Submit User Content to
                            the Service, you grant to MemeAlerts a worldwide,
                            non-exclusive, royalty-free, transferable license to
                            use, encode, share, reproduce, distribute, prepare
                            derivative works of, display, and perform that User
                            Content as necessary to provide, promote, and
                            operate the Service. You retain ownership rights of
                            your User Content and you grant to each user of the
                            Service, a worldwide, non-exclusive, royalty-free
                            license to access your User Content through the
                            Service, and to use, distribute, and display such
                            User Content to the extent permitted by the Service
                            and these Terms.
                        </p>

                        <p>b. User Representations and Warranties</p>

                        <p>
                            Each time you Submit User Content to or through the
                            Service, including but not limited to, you represent
                            and warrant to MemeAlerts that you: (i) retain all
                            ownership rights in your User Content or you have
                            the legal right to use the User Content, (ii) that
                            the User Content is true and accurate, (iii) that
                            such User Content will not cause harm to any third
                            party, and (iv) your User Content will not violate
                            these Terms or Applicable Laws.
                        </p>

                        <p>c. Disclaimer of Responsibility for User Content</p>

                        <p>
                            MemeAlerts does not control User Content Submitted
                            using the Service and disclaims any responsibility
                            for any User Content. We are not responsible or
                            liable in any way for any corruption, misdelivery,
                            creation, use, storage, destruction, or other loss
                            of any of User content. You are responsible for all
                            User Content that you provide and for the legality,
                            originality, and appropriateness of the User
                            Content.
                        </p>

                        <p>d. Review and Removal of User Content</p>

                        <p>
                            We reserve the right to remove any User Content from
                            the Service at our sole discretion. You agree that
                            we have the right to determine whether your User
                            Content is appropriate and complies with these
                            Terms, remove any and/or all of your User Content,
                            and terminate your access to the Site or Service
                            with or without prior notice.
                        </p>

                        <p>
                            The Service does not, and cannot, pre-screen or
                            monitor all User Content. However, at our
                            discretion, we, or technology we employ, may monitor
                            and/or record your interactions with the Service and
                            with other users.
                        </p>

                        <p>8. LINKS TO THIRD-PARTY WEBSITES AND RESOURCES</p>

                        <p>
                            Solely for your convenience, the Site and Service
                            may include links to websites and other online
                            resources operated or provided by third parties. You
                            acknowledge and agree that we are not responsible
                            for the availability of any such third-party
                            websites or resources and that we do not endorse and
                            are not responsible or liable for any such
                            third-party websites or resources or any
                            information, materials, products, or services
                            contained on any third-party websites or resources
                            or accessible through such third-party websites or
                            resources.
                        </p>

                        <p>
                            We make no, and expressly disclaim any, warranties,
                            express or implied, with regard to the information,
                            materials, products, or services that are contained
                            on or accessible through any third-party websites or
                            resources. Your access to and use of any third-party
                            websites or resources, including, without
                            limitation, any information, materials, products, or
                            services on or accessible through any third-party
                            websites or resources is solely at your own risk.
                        </p>

                        <p>9. THIRD-PARTY MATERIALS</p>

                        <p>
                            The Site or Service may display, include, or make
                            available data, information, applications, and other
                            products, services, and/or materials provided by
                            third parties, including, without limitation, other
                            users (“Third Party Materials”). Your rights to
                            access or use any Third-Party Materials may be
                            subject to additional licensing terms required by a
                            third party concerning such third party’s
                            Third-Party Materials.
                        </p>

                        <p>
                            You acknowledge and agree that we and other third
                            parties are not responsible or liable for, nor do we
                            endorse, any Third-Party Materials that appear on or
                            are available through the Site or Services.
                        </p>

                        <p>
                            MEMEALERTS IS NOT RESPONSIBLE FOR ANY THIRD-PARTY
                            MATERIALS, INCLUDING, WITHOUT LIMITATION, THEIR
                            ACCURACY, COMPLETENESS, TIMELINESS, VALIDITY,
                            NON-INFRINGEMENT, LEGALITY, DECENCY, OR QUALITY.
                            MEMEALERTS DOES NOT ASSUME AND WILL NOT HAVE ANY
                            LIABILITY OR RESPONSIBILITY TO YOU OR ANY OTHER
                            PERSON OR ENTITY ARISING OUT OF ANY THIRD-PARTY
                            MATERIALS, INCLUDING, WITHOUT LIMITATION, YOUR USE
                            OF OR RELIANCE ON ANY THIRD-PARTY MATERIALS. ANY
                            RELIANCE YOU PLACE ON ANY THIRD-PARTY MATERIALS IS
                            STRICTLY AT YOUR SOLE DISCRETION AND RISK.
                        </p>

                        <p>10. OWNERSHIP</p>

                        <p>a. Intellectual Property</p>

                        <p>
                            All right, title and interest in and to the Site and
                            Service, including, but not limited to, all of the
                            software and code that comprise and operate the Site
                            and Service, and all of the text, product
                            descriptions, compilations, hypertext markup
                            language (HTML), scripts, logos, slogans, names of
                            products and services, photographs, illustrations,
                            images, graphics, audio, video, URLs and other
                            materials provided through the Site and Service such
                            as the features and functions (together, “Content”)
                            are owned by us or third parties who have licensed
                            their content to us. MemeAlerts is protected under
                            trademark, service mark, trade dress, copyright,
                            patent, trade secret and other intellectual property
                            laws.
                        </p>

                        <p>
                            Unless approved in writing in advance by MemeAlerts,
                            you agree not to use our name, trademarks, or logos
                            in connection with any product or service that is
                            not under MemeAlerts ownership or control.
                        </p>

                        <p>
                            These Terms do not give you any right to reproduce,
                            distribute, transmit, publish or commercially
                            exploit any information provided by MemeAlerts
                            without permission of the owner of the copied
                            information. Any unauthorized use of MemeAlerts
                            name, trademark, or logos is strictly prohibited and
                            may be prosecuted to the fullest extent of the law.
                        </p>

                        <p>b. License</p>

                        <p>
                            You grant MemeAlerts a worldwide, non-exclusive,
                            royalty-free, transferable license to use, encode,
                            share, reproduce, distribute, prepare derivative
                            works of, display clips of your live stream (video
                            and audio), live stream screenshots, your username,
                            your profile picture and user specific Service data.
                        </p>

                        <p>11. FEEDBACK AND SUBMISSIONS</p>

                        <p>
                            You acknowledge and agree that any questions,
                            comments, suggestions, ideas, feedback, or other
                            information regarding the Site or Service
                            (&quot;Submissions&quot;) provided by you to us are
                            non-confidential and shall become our sole property.
                            We shall own exclusive rights, including all
                            intellectual property rights, and shall be entitled
                            to the unrestricted use and dissemination of these
                            Submissions for any lawful purpose, commercial or
                            otherwise, without acknowledgment or compensation to
                            you.
                        </p>

                        <p>12. COPYRIGHT COMPLAINTS AND COPYRIGHT AGENT</p>

                        <p>
                            We respect the intellectual property rights of
                            others and require that our users do the same. We
                            will respond to notices of alleged copyright
                            infringement that comply with applicable law.
                        </p>

                        <p>a. Reporting Claims of Copyright Infringement</p>

                        <p>
                            If you are a copyright owner or an agent thereof and
                            believe, in good faith, that any materials provided
                            on or through the Service infringe upon your
                            copyrights, you may submit a written notification
                            requesting removal of those materials (or access to
                            them) from the Service to our copyright agent
                            designated below. The Digital Millennium Copyright
                            Act (see 17 U.S.C 512) (“DMCA”) following
                            information in writing (“DMCA Notice”):
                        </p>

                        <ul>
                            <li>The date you submit the DMCA Notice;</li>

                            <li>
                                A physical or electronic signature of a person
                                authorized to act on behalf of the owner of an
                                exclusive right that is allegedly infringed;
                            </li>

                            <li>
                                A description of the copyrighted work claimed to
                                have been infringed, or, if multiple copyrighted
                                works at a single online site are covered by a
                                single notification, a representative list of
                                such works at that site;
                            </li>

                            <li>
                                A description of the material that is claimed to
                                be infringing or to be the subject of infringing
                                activity and information sufficient to enable us
                                to locate such work;
                            </li>

                            <li>
                                Information reasonably sufficient to permit the
                                service provider to contact you, such as an
                                address, telephone number, and/or email address;
                            </li>

                            <li>
                                A statement that you have a good faith belief
                                that use of the material in the manner
                                complained of is not authorized by the copyright
                                owner, its agent, or the law;
                            </li>

                            <li>
                                A statement that the information in the
                                notification is accurate, and under penalty of
                                perjury, that you are authorized to act on
                                behalf of the owner of an exclusive right that
                                is allegedly infringed.
                            </li>
                        </ul>

                        <p>
                            Our designated copyright agent to receive DMCA
                            Notices is: dmca@memealerts.com
                        </p>

                        <p>
                            If you fail to comply with all of the requirements
                            of Section 512(c)(3) of the DMCA, your DMCA Notice
                            may not be effective.
                        </p>

                        <p>
                            Please be aware that if you knowingly materially
                            misrepresent that material or activity on the
                            Service is infringing your copyright, you may be
                            held liable for damages (including costs and
                            attorneys&apos; fees) under Section 512(f) of the DMCA.
                        </p>

                        <p>b. Counter-Notices</p>

                        <p>
                            If you believe that your User Content has been
                            removed from the Service by mistake or
                            misidentifications, you may send a counter-notice
                            containing the following information to our
                            copyright agent using the contact information set
                            forth above:
                        </p>

                        <p>Your physical or electronic signature;</p>

                        <p>
                            A description of the content that has been removed
                            and the location at which the content appeared
                            before it was removed;
                        </p>

                        <p>
                            A statement under penalty of perjury by you that you
                            have a good faith belief that the content that was
                            removed as a result of mistake or a
                            misidentification of the content; and
                        </p>

                        <p>
                            Your name, address, telephone number, and email
                            address by which we may contact you; and a statement
                            that you consent to the jurisdiction of the Federal
                            District Court for the judicial district in which
                            your address is located (or if you reside outside
                            the United States for any judicial district in which
                            the Site or Service may be found) and a statement
                            that you will accept service of process from the
                            person who provided notification of the alleged
                            infringement.
                        </p>

                        <p>
                            If a counter-notice is received by our copyright
                            agent, we may send a copy of the counter-notice to
                            the original complaining party informing such person
                            that it may reinstate the removed content in ten
                            (10) business days. Unless the copyright owner files
                            an action seeking a court order against the content
                            provider, member or user, the removed content may
                            (in our sole discretion) be reinstated on the Site
                            in ten (10) to fourteen (14) business days or more
                            after receipt of the counter-notice.
                        </p>

                        <p>c. Termination of Repeat Infringer Accounts</p>

                        <p>
                            Pursuant to our policy, we may terminate access for
                            users who are found repeatedly to provide or post
                            protected third-party content without necessary
                            rights and permissions.
                        </p>

                        <p>13. DISCLAIMERS AND LIMITATIONS OF LIABILITY</p>

                        <p>
                            THE SITE AND SERVICE, ARE PROVIDED “AS IS” AND “AS
                            AVAILABLE” BASIS. MEMEALERTS MAKES NO EXPRESS OR
                            IMPLIED WARRANTIES WITH REGARD TO THE SITE OR
                            SERVICE AND, TO THE EXTENT PERMITTED BY LAW,
                            EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS,
                            IMPLIED, OR STATUTORY, REGARDING THE SERVICE
                            INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF
                            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                            TITLE, WARRANTIES THAT MAY ARISE OUT OF COURSE OF
                            DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE
                            PRACTICE, QUIET ENJOYMENT, QUIET POSSESSION, AND
                            NON-INFRINGEMENT. MEMEALERTS MAKES NO WARRANTIES OR
                            REPRESENTATIONS REGARDING THE ACCURACY,
                            COMPLETENESS, SECURITY, RELIABILITY, QUALITY, OR
                            AVAILABILITY OF THE SITE OR SERVICE.
                        </p>

                        <p>
                            MemeAlerts does not warrant that any part of the
                            Site or Service will be uninterrupted or error-free,
                            that defects will be corrected, or that this site,
                            Service, or the server(S) that makes them available
                            are free of viruses, malware, destructive code, or
                            other harmful components. MemeAleets will not be
                            liable for any loss or damage caused by a
                            distributed denial of service attack, viruses, or
                            other harmful material due to your use of the site
                            or service. MemeAlerts expressly disclaims any and
                            all liability for the content or any omissions from
                            the site or Service, including any inaccuracies,
                            errors, or misstatements in such content. we are not
                            responsible for any damage to your computer system
                            or loss of data resulting from your use of the site
                            or Service, posting of content, or your download of
                            content from the site.
                        </p>

                        <p>
                            Applicable law may not allow the exclusion of
                            implied warranties, so the above exclusion may not
                            apply to you to the extent not allowed by such law.
                        </p>

                        <p>14. LIMITATION OF MEMEALERTS LIABILITY</p>

                        <p>
                            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
                            OUR ENTIRE LIABILITY, AND YOUR EXCLUSIVE REMEDY,
                            WITH RESPECT TO YOUR ACCESS TO AND USE OF THE SITE
                            OR SERVICE SHALL BE THE AMOUNT OF $100.
                        </p>

                        <p>
                            IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY THIRD
                            PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                            EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES
                            ARISING FROM YOUR ACCESS TO OR USE OF THE SITE OR
                            SERVICE OR FOR ANY OTHER CLAIM RELATED IN ANY WAY TO
                            YOUR ACCESS TO OR USE OF THE SITE OR SERVICE,
                            INCLUDING CLAIMS FOR LOSS OF DATA.
                        </p>

                        <p>
                            THESE LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES
                            ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING
                            NEGLIGENCE) OR OTHERWISE AND REGARDLESS OF WHETHER
                            SUCH DAMAGES WERE FORESEEABLE OR MEMEALERTS WAS
                            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                        </p>

                        <p>
                            SOME STATES OR JURISDICTIONS DO NOT ALLOW CERTAIN
                            LIMITATIONS OF LIABILITY, SO SOME OF THE ABOVE
                            LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU. IN
                            SUCH STATES OR JURISDICTIONS, THE RELEASED PARTIES’
                            LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT
                            PERMITTED BY APPLICABLE LAW.
                        </p>

                        <p>15. CONTACT</p>

                        <p>
                            If you have any questions concerning these Terms,
                            please contact MemeAlerts at admin@memealerts.com.
                        </p>
                    </Column>
                </Row>
            </Column>
        </InformationPage>
    );
};
