import { useEffect, useState } from 'react';

import { useApi } from '@hooks';
import { IEventData } from '@typings';

const PAGE_SIZE = 100;

interface ISupporterParams {
    supporterId: string;
}

export const useSupporter = (params: ISupporterParams) => {
    const {
        supporterId,
    } = params;

    const {
        events: {
            useLazyLoadEventsForSupporterQuery,
        },
        supporters: {
            useGetSupporterQuery,
        },
        users: {
            useGiveBonusMutation,
        },
    } = useApi();

    const { data: supporter, isFetching } = useGetSupporterQuery(supporterId, { skip: !supporterId });

    const [loadEventsPage, { data: loadedEventsPage }] = useLazyLoadEventsForSupporterQuery();
    const [giveBonus] = useGiveBonusMutation();

    const [events, setEvents] = useState<Array<IEventData>>();
    const [page, setPage] = useState(0);
    const [pagesCount, setPagesCount] = useState(0);

    const [bonusSize, setBonusSize] = useState(0);

    const changePage = (val: number) => setPage(val);
    const sendBonus = (userId: string, streamerId: string) => giveBonus({ userId, streamerId, value: bonusSize });

    useEffect(() => {
        if (supporter) {
            setPage(1);
        }
    }, [supporter]);

    useEffect(() => {
        if (page > 0 && supporter) {
            loadEventsPage({
                limit: PAGE_SIZE,
                skip: (page - 1) * PAGE_SIZE,
                supporterId: supporter.supporterId,
            }, false);
        }
    }, [page, supporter]);

    useEffect(() => {
        if (loadedEventsPage) {
            setEvents(loadedEventsPage.data);
            setPagesCount(Math.ceil(loadedEventsPage.total / PAGE_SIZE));
        }
    }, [loadedEventsPage]);

    return {
        bonusSize,
        events,
        page,
        pagesCount,
        supporter,
        supporterNotFound: !isFetching && !supporter,
        changePage,
        sendBonus,
        setBonusSize,
    };
};
