import { useEffect } from 'react';

import { NotificationType } from '@contexts';
import { useApi, useNotifications } from '@hooks';

export const usePersonalSettings = () => {
    const {
        users: {
            useUpdateUserNameMutation,
        },
    } = useApi();

    const [update, { isSuccess: updateSuccess, isError: updateError }] = useUpdateUserNameMutation();

    const { notify } = useNotifications();

    const updateName = (name: string) => {
        update(name).unwrap();
    };

    useEffect(() => {
        if (updateSuccess) {
            notify('Изменения успешно сохранены', NotificationType.SUCCESS);
        }

        if (updateError) {
            notify('Не удалось сохранить изменения', NotificationType.ERROR);
        }
    }, [updateSuccess, updateError]);

    return {
        updateName,
    };
};
