import { Button, Column, EComponentColor, ETheme, Row, ThemeContext } from '@hyperclap/ui';
import cn from 'classnames';
import React, { useContext } from 'react';

import { TApp } from '@hooks';
import { Logo, LogoKind } from '@hyperclap/views';
import { Login } from '@views';

import s from './Header.scss';


interface IHeaderProps extends TApp {}

export const Header = (props: IHeaderProps) => {
    const {
        modals: {
            showModal,
        },
        deviceInfo: {
            isMobileLayoutRecommended,
        },
        navigation: {
            goToHome,
        },
        translation: {
            t,
        },
    } = props;

    const { theme } = useContext(ThemeContext);

    const onAuthClick = async () => {
        showModal({
            content: <Login {...props}/>,
            options: {
                bodyClassName: s.loginModalBody,
            },
        });
    };

    return (
        <Row grow className={cn(s.header, { [s.headerMobileLayout]: isMobileLayoutRecommended })}>
            <Column>
                <Logo
                    width={92}
                    kind={theme === ETheme.LIGHT ? LogoKind.LIGHT : LogoKind.DARK}
                    onClick={goToHome}
                />
            </Column>

            <Column grow/>

            {!isMobileLayoutRecommended &&
                <Column>
                    <Row columnGap={10} padding={0}>
                        <Button
                            color={EComponentColor.ACCENT}
                            caption={t('common.login')}
                            thinText
                            linkStyle
                            onClick={onAuthClick}
                        />
                        <Button
                            color={EComponentColor.ACCENT}
                            caption={t('common.registration')}
                            onClick={onAuthClick}
                        />
                    </Row>
                </Column>
            }
        </Row>
    );
};
