import { Button, Checkbox, Column, EComponentSize, ERowJustification, Icon, Label, Row } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React, { createRef, CSSProperties, useEffect, useState } from 'react';

import { IconTrashLine, IconUploadLine } from '@assets/images/svg';
import { TApp } from '@hooks';

import s from './BackgroundLoader.scss';

interface BackgroundLoaderProps extends TApp {
    onBackgroundModeChange?: (mode: boolean) => void;
}

export const BackgroundLoader = (props: BackgroundLoaderProps) => {
    const {
        currentUser: {
            currentUser,
        },
        settings: {
            streamer: {
                page: {
                    channelBgImageData,
                    isChannelBgCleared,
                    onChangeChannelBg,
                    onCleanBgImage,
                },
            },
        },
        translation: { t },
        onBackgroundModeChange = noop,
    } = props;

    const [isPreviewDisplayed, setPreviewDisplayed] = useState(false);

    const fileSelectorRef = createRef<HTMLInputElement>();

    const previewImageStyles: CSSProperties = {
        backgroundImage: `url(${channelBgImageData || currentUser?.channel.backgroundUrl || ''})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
    };

    const onBgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files && files.length > 0) {
            onChangeChannelBg(files[0]);
        }
    };

    const cleanBackground = () => {
        if (fileSelectorRef.current) {
            fileSelectorRef.current.value = '';
        }

        onCleanBgImage();
    };

    const onCheck = (val: boolean) => {
        onBackgroundModeChange(val);
    };

    useEffect(() => {
        setPreviewDisplayed((!!currentUser?.channel.backgroundUrl || !!channelBgImageData) && !isChannelBgCleared);
    }, [currentUser?.channel.backgroundUrl, channelBgImageData, isChannelBgCleared]);

    return (
        <Column>
            <Label padding={{ top: 0, bottom: 10 }} caption={t('streamer-settings.page.channel-background')} size={EComponentSize.MEDIUM} />
            <Column rowGap={10}>
                <Row width={255} height={100} className={s.background} justification={ERowJustification.CENTER}>
                    {isPreviewDisplayed
                        ? <Column grow height={100} className={s.backgroundPreview} style={previewImageStyles}/>
                        : <Icon width={32} icon={<IconUploadLine/>} className={s.uploadIcon}/>
                    }
                    <input type={'file'} className={s.imageSelectInput} onChange={onBgChange}/>
                </Row>
                <Row>
                    <Button
                        caption={t('common.reset')}
                        icon={<IconTrashLine/>}
                        linkStyle
                        height={35}
                        padding={0}
                        width={80}
                        onClick={cleanBackground}
                    />
                    <Column grow />
                    { !!currentUser &&
                        <Checkbox
                            checked={currentUser?.channel.backgroundMode === 1 ?? false}
                            caption={'Заполнить фон'}
                            padding={{ right: 5 }}
                            onChanged={onCheck}
                        />
                    }
                </Row>
            </Column>
        </Column>
    );
};
