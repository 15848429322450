import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
    IconCategoryBottom,
    IconCategoryFacialHair,
    IconCategoryGlasses,
    IconCategoryHair,
    IconCategoryHat,
    IconCategoryLeftHand,
    IconCategoryRightHand,
    IconCategoryTop,
} from './assets';

interface ICategoryInfoItem {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    title: string;
}

export const useCategoriesInfo = () => {
    const { t: translation } = useTranslation();

    const t = (id: string) => translation(`avatar-editor.categories.${id}`);

    const categoriesInfo: Record<string, ICategoryInfoItem> = {
        '666f7d7760037a779bedd706': {
            Icon: IconCategoryBottom,
            title: t('bottom'),
        },
        '666f7d6f60037a779bedd703': {
            Icon: IconCategoryFacialHair,
            title: t('facialHair'),
        },
        '666f7d9360037a779bedd715': {
            Icon: IconCategoryGlasses,
            title: t('glasses'),
        },
        '666f7d8a60037a779bedd70f': {
            Icon: IconCategoryHair,
            title: t('hair'),
        },
        '666f7d8e60037a779bedd712': {
            Icon: IconCategoryHat,
            title: t('hat'),
        },
        '666f7d7d60037a779bedd709': {
            Icon: IconCategoryLeftHand,
            title: t('leftHand'),
        },
        '666f7d8460037a779bedd70c': {
            Icon: IconCategoryRightHand,
            title: t('rightHand'),
        },
        '666f7d5e60037a779bedd700': {
            Icon: IconCategoryTop,
            title: t('top'),
        },
    };

    return {
        categoriesInfo,
    };
};
