import { useCurrentUser } from '@hooks/app';

import { usePersonalSettings } from './PersonalSettings';
import { useStreamerSettings } from './StreamerSettings';
import { useSystemSettings } from './SystemSettings';

export const useSettings = () => {
    const { currentUser } = useCurrentUser();

    const personal = usePersonalSettings();
    const system = useSystemSettings({ active: !!currentUser });
    const streamer = useStreamerSettings();

    return {
        personal,
        system,
        streamer,
    };
};
