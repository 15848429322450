import React from 'react';
import { RouteObject } from 'react-router-dom';

import { Events } from '@views';

export const EventsRoutes: Array<RouteObject> = [
    {
        path: '',
        element: <Events/>,
    },
    {
        path: ':timestamp',
        element: <Events/>,
    },
];
