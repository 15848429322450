import React, { useEffect, useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useApp } from '@hooks';

import { Desktop, Mobile } from './layouts';


export const Landing = () => {
    const app = useApp();
    const {
        deviceInfo: {
            isMobileLayoutRecommended,
        },
        telegram: {
            registerLoginRequest,
        },
    } = app;
    const [searchParams, setSearchParams] = useSearchParams();

    const isTelegramLogin = searchParams.has('id') && searchParams.has('auth_date') && searchParams.has('hash');

    useLayoutEffect(() => {
        document.title = 'Hyperclap - Bring Memes To Your Stream';
    }, []);

    useEffect(() => {
        if (isTelegramLogin) {
            void registerLoginRequest(searchParams.toString());
            setSearchParams(new URLSearchParams());
        }
    }, []);

    return (
        isMobileLayoutRecommended
            ? <Mobile {...app}/>
            : <Desktop {...app}/>
    );
};
