import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';

import { CACHED_STATE_SHOW_INVITE_PROMPT_NAME, isUndefined, parseBool } from '@common';
import { useInvites } from '@hooks';

interface IInviteRequiredProps {
    invitePrompt: ReactElement;
}

export const InviteRequired = (props: PropsWithChildren<IInviteRequiredProps>) => {
    const { children, invitePrompt } = props;
    const { invitesEnabled, inviteActivated, isInviteStatusLoading } = useInvites();

    const [showInvitePrompt, setShowInvitePrompt] = useState<boolean>(parseBool(
        localStorage.getItem(CACHED_STATE_SHOW_INVITE_PROMPT_NAME) ?? 'false'),
    );

    useEffect(() => {
        if (!isInviteStatusLoading) {
            setShowInvitePrompt(invitesEnabled && !inviteActivated);
            localStorage.setItem(CACHED_STATE_SHOW_INVITE_PROMPT_NAME, (invitesEnabled && !inviteActivated).toString());
        }
    }, [invitesEnabled, inviteActivated, isInviteStatusLoading]);

    return isUndefined(showInvitePrompt)
        ? null
        : showInvitePrompt
            ? invitePrompt
            : children;
};
