import { IAvatarAsset, IAvatarAssetCategory } from '@typings';

const RANDOM_HIDDEN_RATE: Record<string, number> = {
    'Facial Hair': 7,
    'Hat': 7,
    'Left Hand': 7,
    'Right Hand': 7,
    'Glasses': 7,
};

const RANDOM_HIDDEN_RATE_CATEGORIES = Object.keys(RANDOM_HIDDEN_RATE);

export const getRandomAssetList: (
    categories: IAvatarAssetCategory[],
    assets: IAvatarAsset[],
) => IAvatarAsset[] = (
    categories,
    assets,
) => {
    return categories
        .filter((category) => !category.isNotVariable)
        .filter((category) => {
            const hasCategory = Math.round(Math.random() * 9) >= RANDOM_HIDDEN_RATE[category.title];

            return !RANDOM_HIDDEN_RATE_CATEGORIES.includes(category.title) || hasCategory;
        })
        .map((category) => {
            const filtered = assets.filter((item) => item.categoryId === category.id);

            return filtered[Math.floor(Math.random() * filtered.length)];
        });
};
