import cn from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import s from './CategoryTabs.scss';
import { ICategoryTabsProps } from './types';
import { useCategoriesInfo } from '../../hooks';

export const CategoryTabs = (props: ICategoryTabsProps) => {
    const {
        categories,
        selectedCategory,
        isFaceListDisplayed,
        onSelect,
        onFaceClick,
    } = props;
    const { categoriesInfo } = useCategoriesInfo();
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`avatar-editor.CategoryTabs.${id}`);

    return (
        <div className={s.root}>
            <div
                className={cn(s.item, { [s.itemSelected]: isFaceListDisplayed })}
                onClick={onFaceClick}
            >
                <div className={s.itemTitle}>{t('face')}</div>
                <div className={s.itemTitleFake}>{t('face')}</div>
            </div>

            {categories.map((item) => {
                const category = categoriesInfo[item.id];

                return (
                    <div
                        key={item.id}
                        className={cn(s.item, { [s.itemSelected]: item.id === selectedCategory })}
                        onClick={() => onSelect(item.id)}
                    >
                        <div className={s.itemTitle}>{category.title}</div>
                        <div className={s.itemTitleFake}>{category.title}</div>
                    </div>
                );
            })}
        </div>
    );
};
