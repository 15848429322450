import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const SuccessPaymentRedirect = () => {
    const [searchParams] = useSearchParams();
    const result = searchParams.get('status');

    if (result && window.opener) {
        window.opener.postMessage(
            JSON.stringify({ status: result, action: 'paymentState' }),
        );
    }

    useEffect(() => {
        setTimeout(() => {
            window.close();
        }, 500);
    }, []);

    return null;
};
