import { useEffect, useState } from 'react';

import { useApi } from '@hooks';
import { IHookBaseParams, ISticker, IUser } from '@typings';

const PAGE_SIZE = 20;

interface ICatalogueParams extends IHookBaseParams {
    streamer?: IUser;
}

export const useCatalogue = (params: ICatalogueParams) => {
    const {
        active,
        streamer,
    } = params;

    const {
        stickers: {
            useLazyLoadPageStreamerAreaCatalogueQuery,

            useUpdateStickerCustomSettingsMutation,
            useAddStickerToFavoritesMutation,
            useRemoveStickerFromFavoritesMutation,
            useAddStickerToChannelMutation,
            useRemoveStickerFromChannelMutation,
        },
    } = useApi();

    const [updateCustomSettings] = useUpdateStickerCustomSettingsMutation();
    const [addToFavorites] = useAddStickerToFavoritesMutation();
    const [removeFromFavorites] = useRemoveStickerFromFavoritesMutation();
    const [addToChannel] = useAddStickerToChannelMutation();
    const [removeFromChannel] = useRemoveStickerFromChannelMutation();

    const [loadPage, { isFetching, isUninitialized }] = useLazyLoadPageStreamerAreaCatalogueQuery();
    const [stickers, setStickers] = useState<Array<ISticker>>([]);
    const [isEnd, setIsEnd] = useState(false);

    const updateStickerCustomSettings = (sticker: ISticker) => {
        setStickers(stickers.map((s) => sticker.id === s.id ? { ...sticker } : s));

        if (sticker.customSettings) {
            updateCustomSettings({
                stickerId: sticker.id,
                stickerCustomSettings: sticker.customSettings,
            });
        }
    };

    const updateStickerFavoriteState = async (sticker: ISticker) => {
        const method = sticker.isFavorite ? addToFavorites : removeFromFavorites;
        const result = await method(sticker.id).unwrap();
        setStickers(stickers.map((s) => result.id === s.id ? { ...result } : s));

        return result;
    };

    const updateStickerInChannelState = async (sticker: ISticker) => {
        const method = sticker.isAddedToChannel ? addToChannel : removeFromChannel;
        const result = await method(sticker.id).unwrap();
        setStickers(stickers.map((s) => result.id === s.id ? { ...result } : s));

        return result;
    };

    const loadNextPage = async () => {
        if (!active || !streamer || isEnd || isFetching) return;
        const params = {
            limit: PAGE_SIZE,
            skip: stickers.length,
            categories: [],
            streamerId: streamer.id,
        };

        const loadedPage = await loadPage(params, false).unwrap();

        if (loadedPage?.length) {
            setStickers(stickers.concat(loadedPage));
            setIsEnd(loadedPage?.length < PAGE_SIZE);
        }
    };

    useEffect(() => {
        if (active && !stickers.length) {
            void loadNextPage();
        }
    }, [stickers, active]);

    return {
        isEnd,
        isFetching,
        isUninitialized,
        stickers,
        loadNextPage,
        updateStickerCustomSettings,
        updateStickerFavoriteState,
        updateStickerInChannelState,
    };
};
