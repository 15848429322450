import { IAvatarAssetColorsMapVariableItem } from './IAvatarAssetColorsMapVariableItem';
import { IAvatarAssetFaceEmotion } from './IAvatarAssetFaceEmotion';

export interface IAvatarAssetFace {
    id: string;
    title: string;
    emotions: IAvatarAssetFaceEmotion[];
    eyeColor: IAvatarAssetColorsMapVariableItem;
    sortIndex: number;
}
